import {
  GET_A_MARKETER_ERROR,
  GET_A_MARKETER_LOADING,
  GET_A_MARKETER_SUCCESS,
  SUBMIT_SHOP_DETAILS_ERROR,
  SUBMIT_SHOP_DETAILS_LOADING,
  SUBMIT_SHOP_DETAILS_SUCCESS,
} from "./types";

const initialState = {
  getAMarketerLoading: null,
  getAMarketerSuccess: null,
  getAMarketerError: null,
  submitShopDetailsLoading: null,
  submitShopDetailsSuccess: null,
  submitShopDetailsError: null,
};

export default function marketingReducer(state = initialState, action) {
  switch (action.type) {
    case GET_A_MARKETER_LOADING:
      return { ...state, getAMarketerLoading: action.payload };
    case GET_A_MARKETER_SUCCESS:
      return { ...state, getAMarketerSuccess: action.payload };
    case GET_A_MARKETER_ERROR:
      return { ...state, getAMarketerError: action.payload };
    case SUBMIT_SHOP_DETAILS_LOADING:
      return { ...state, submitShopDetailsLoading: action.payload };
    case SUBMIT_SHOP_DETAILS_SUCCESS:
      return { ...state, submitShopDetailsSuccess: action.payload };
    case SUBMIT_SHOP_DETAILS_ERROR:
      return { ...state, submitShopDetailsError: action.payload };
    default:
      return state;
  }
}
