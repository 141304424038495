import axios from "axios";
import { toast } from "react-toastify";
import {
  A_PRODUCT_IN_A_SHOP_ERROR,
  A_PRODUCT_IN_A_SHOP_LOADING,
  A_PRODUCT_IN_A_SHOP_SUCCESS,
  CALCULATE_DELIVERY_FEE_ERROR,
  CALCULATE_DELIVERY_FEE_LOADING,
  CALCULATE_DELIVERY_FEE_SUCCESS,
  CREATE_ORDER_ERROR,
  CREATE_ORDER_LOADING,
  CREATE_ORDER_SUCCESS,
  GET_ADS_ERROR,
  GET_ADS_LOADING,
  GET_ADS_SUCCESS,
  GET_CATEGORIES_ERROR,
  GET_CATEGORIES_LOADING,
  GET_CATEGORIES_SUCCESS,
  GET_NEW_ARRIVALS_ERROR,
  GET_NEW_ARRIVALS_LOADING,
  GET_NEW_ARRIVALS_SUCCESS,
  INCREASE_AD_COUNT_ERROR,
  INCREASE_AD_COUNT_LOADING,
  INCREASE_AD_COUNT_SUCCESS,
  PRODUCTS_IN_A_CATEGORY_ERROR,
  PRODUCTS_IN_A_CATEGORY_LOADING,
  PRODUCTS_IN_A_CATEGORY_SUCCESS,
  PRODUCTS_IN_A_SHOP_ERROR,
  PRODUCTS_IN_A_SHOP_LOADING,
  PRODUCTS_IN_A_SHOP_SUCCESS,
  VERIFY_COUPON_CODE_ERROR,
  VERIFY_COUPON_CODE_LOADING,
  VERIFY_COUPON_CODE_SUCCESS,
  VERIFY_EMAIL_ERROR,
  VERIFY_EMAIL_LOADING,
  VERIFY_EMAIL_SUCCESS,
} from "../reducers/types";
import { apiBaseUrl } from "../../constants/url";

export const getAllProductsInAShop =
  (name, page, limit) => async (dispatch) => {
    dispatch({ type: PRODUCTS_IN_A_SHOP_LOADING, payload: true });
    dispatch({ type: PRODUCTS_IN_A_SHOP_ERROR, payload: null });
    try {
      const response = await axios.get(
        `${apiBaseUrl}/shop/all/${name}?page=${page}&limit=${limit}`
      );
      dispatch({ type: PRODUCTS_IN_A_SHOP_LOADING, payload: null });

      if (response.data.status !== 200) {
        return toast.error(response.data.message);
      }
      dispatch({ type: PRODUCTS_IN_A_SHOP_SUCCESS, payload: response.data });
      //   return response.data;
    } catch (err) {
      dispatch({ type: PRODUCTS_IN_A_SHOP_LOADING, payload: null });

      //   if (err.response.status === 422) {
      //     return toast.error(`Validation error`);
      //   }
      if (
        err.response.status === 422 &&
        err.response.data?.errors[0]?.name === "Shop does not exist"
      ) {
        dispatch({
          type: PRODUCTS_IN_A_SHOP_ERROR,
          payload: "Shop does not exist",
        });
      } else {
        return toast.error(err.response?.data?.message);
      }
    }
  };

export const getAProductInAShop =
  (shopSlug, productSlug) => async (dispatch) => {
    dispatch({ type: A_PRODUCT_IN_A_SHOP_LOADING, payload: true });
    dispatch({ type: A_PRODUCT_IN_A_SHOP_ERROR, payload: null });
    try {
      const response = await axios.get(
        `${apiBaseUrl}/shop/product/${shopSlug}/${productSlug}`
      );
      dispatch({ type: A_PRODUCT_IN_A_SHOP_LOADING, payload: null });

      if (response.data.status !== 200) {
        dispatch({
          type: A_PRODUCT_IN_A_SHOP_ERROR,
          payload: response.data.message,
        });
      }
      dispatch({ type: A_PRODUCT_IN_A_SHOP_SUCCESS, payload: response.data });
      return response.data;
    } catch (err) {
      dispatch({ type: A_PRODUCT_IN_A_SHOP_LOADING, payload: null });

      //   if (err.response.status === 422) {
      //     return toast.error(`Validation error`);
      //   }
      if (
        (err.response.status === 422 &&
          err.response.data?.errors[0]?.shopSlug === "Shop does not exist") ||
        (err.response.status === 422 &&
          err.response.data?.errors[0]?.productSlug ===
            "Product does not exist") ||
        (err.response.status === 422 &&
          err.response.data?.errors[0]?.productSlug ===
            "Product has been unpublished by the vendor")
      ) {
        dispatch({
          type: A_PRODUCT_IN_A_SHOP_ERROR,
          payload:
            err.response.data?.errors[0]?.productSlug ??
            "Product does not exist",
        });
      } else {
        dispatch({
          type: A_PRODUCT_IN_A_SHOP_ERROR,
          payload: err.response?.data?.message,
        });
        // return toast.error(err.response?.data?.message);
      }
    }
  };

export const getAllProductsInACategory =
  (slug, page, limit) => async (dispatch) => {
    dispatch({ type: PRODUCTS_IN_A_CATEGORY_LOADING, payload: true });
    dispatch({ type: PRODUCTS_IN_A_CATEGORY_ERROR, payload: null });
    try {
      const response = await axios.get(
        `${apiBaseUrl}/shop/categories/${slug}?page=${page}&limit=${limit}`
      );
      dispatch({ type: PRODUCTS_IN_A_CATEGORY_LOADING, payload: null });

      if (response.data.status !== 200) {
        return toast.error(response.data.message);
      }
      dispatch({
        type: PRODUCTS_IN_A_CATEGORY_SUCCESS,
        payload: response.data,
      });
      //   return response.data;
    } catch (err) {
      dispatch({ type: PRODUCTS_IN_A_CATEGORY_LOADING, payload: null });

      //   if (err.response.status === 422) {
      //     return toast.error(`Validation error`);
      //   }
      if (
        err.response.status === 422 &&
        err.response.data?.errors[0]?.name === "Category does not exist"
      ) {
        dispatch({
          type: PRODUCTS_IN_A_CATEGORY_ERROR,
          payload: "Category does not exist",
        });
      } else {
        return toast.error(err.response?.data?.message);
      }
    }
  };

export const verifyCouponCode = (data) => async (dispatch) => {
  dispatch({ type: VERIFY_COUPON_CODE_LOADING, payload: true });
  dispatch({ type: VERIFY_COUPON_CODE_ERROR, payload: null });
  try {
    const response = await axios.post(`${apiBaseUrl}/shop/coupon/verify`, data);
    dispatch({ type: VERIFY_COUPON_CODE_LOADING, payload: null });

    if (response.data.status === 400 && response.data.status !== 422) {
      dispatch({
        type: VERIFY_COUPON_CODE_ERROR,
        payload: response?.data?.message,
      });
    }
    dispatch({
      type: VERIFY_COUPON_CODE_SUCCESS,
      payload: response.data,
    });
    return response.data;
  } catch (err) {
    dispatch({ type: VERIFY_COUPON_CODE_LOADING, payload: null });

    //   if (err.response.status === 422) {
    //     return toast.error(`Validation error`);
    //   }
    if (err.response.status === 422) {
      dispatch({
        type: VERIFY_COUPON_CODE_ERROR,
        payload: err.response?.data?.errors[0]?.couponCode,
      });
    } else {
      dispatch({
        type: VERIFY_COUPON_CODE_ERROR,
        payload: err.response?.data?.message,
      });
    }
  }
};

export const calculateDeliveryPrice = (data) => async (dispatch) => {
  dispatch({ type: CALCULATE_DELIVERY_FEE_LOADING, payload: true });
  dispatch({ type: CALCULATE_DELIVERY_FEE_ERROR, payload: null });
  try {
    const response = await axios.post(`${apiBaseUrl}/delivery/price`, data);
    dispatch({ type: CALCULATE_DELIVERY_FEE_LOADING, payload: null });

    if (response.data.status === 400 && response.data.status !== 422) {
      dispatch({
        type: CALCULATE_DELIVERY_FEE_ERROR,
        payload: response?.data?.message,
      });
    }
    dispatch({
      type: CALCULATE_DELIVERY_FEE_SUCCESS,
      payload: response.data,
    });
    return response.data;
  } catch (err) {
    dispatch({ type: CALCULATE_DELIVERY_FEE_LOADING, payload: null });

    //   if (err.response.status === 422) {
    //     return toast.error(`Validation error`);
    //   }
    if (err.response.status === 422) {
      dispatch({
        type: CALCULATE_DELIVERY_FEE_ERROR,
        payload: err.response?.data?.errors[0]?.deliveryType,
      });
    } else {
      dispatch({
        type: CALCULATE_DELIVERY_FEE_ERROR,
        payload: err.response?.data?.message,
      });
    }
  }
};

export const getAllCategories = (page, limit) => async (dispatch) => {
  dispatch({ type: GET_CATEGORIES_LOADING, payload: true });
  dispatch({ type: GET_CATEGORIES_ERROR, payload: null });
  try {
    const response = await axios.get(
      `${apiBaseUrl}/shop/list/categories?page=${page}&limit=${limit}`
    );
    dispatch({ type: GET_CATEGORIES_LOADING, payload: null });

    if (response.data.status !== 200) {
      return toast.error(response.data.message);
    }
    dispatch({ type: GET_CATEGORIES_SUCCESS, payload: response.data });
    //   return response.data;
  } catch (err) {
    dispatch({ type: GET_CATEGORIES_LOADING, payload: null });

    //   if (err.response.status === 422) {
    //     return toast.error(`Validation error`);
    //   }
    return toast.error(err.response?.data?.message);
  }
};

export const getNewArrivals = () => async (dispatch) => {
  dispatch({ type: GET_NEW_ARRIVALS_LOADING, payload: true });
  dispatch({ type: GET_NEW_ARRIVALS_ERROR, payload: null });
  try {
    const response = await axios.get(`${apiBaseUrl}/shop/new-arrivals`);
    dispatch({ type: GET_NEW_ARRIVALS_LOADING, payload: null });

    if (response.data.status !== 200) {
      return toast.error(response.data.message);
    }
    dispatch({ type: GET_NEW_ARRIVALS_SUCCESS, payload: response.data });
    //   return response.data;
  } catch (err) {
    dispatch({ type: GET_NEW_ARRIVALS_LOADING, payload: null });

    //   if (err.response.status === 422) {
    //     return toast.error(`Validation error`);
    //   }
    return toast.error(err.response?.data?.message);
  }
};

export const createOrder = (data) => async (dispatch) => {
  dispatch({ type: CREATE_ORDER_LOADING, payload: true });
  dispatch({ type: CREATE_ORDER_ERROR, payload: null });
  try {
    const response = await axios.post(`${apiBaseUrl}/order/create`, data);
    dispatch({ type: CREATE_ORDER_LOADING, payload: null });

    if (response.data.status === 400 && response.data.status !== 422) {
      dispatch({
        type: CREATE_ORDER_ERROR,
        payload: response?.data?.message,
      });

      return toast.error(response?.data?.message, { toastId: "order-error" });
    }
    dispatch({
      type: CREATE_ORDER_SUCCESS,
      payload: response.data,
    });
    return response.data;
  } catch (err) {
    dispatch({ type: CREATE_ORDER_LOADING, payload: null });

    if (err.response.status === 422) {
      // dispatch({
      //   type: CALCULATE_DELIVERY_FEE_ERROR,
      //   payload: err.response?.data?.errors[0]?.deliveryType,
      // });

      return toast.error(`Validation error`);
    } else {
      dispatch({
        type: CALCULATE_DELIVERY_FEE_ERROR,
        payload: toast.error(`${err.response?.data?.message}`),
      });
    }
  }
};

export const getAllPublishedAds = () => async (dispatch) => {
  dispatch({ type: GET_ADS_LOADING, payload: true });
  dispatch({ type: GET_ADS_ERROR, payload: null });
  try {
    const response = await axios.get(`${apiBaseUrl}/admin/ads/all-ads/public`);
    dispatch({ type: GET_ADS_LOADING, payload: null });

    if (response.data.status !== 200) {
      return toast.error(response.data.message);
    }
    dispatch({ type: GET_ADS_SUCCESS, payload: response.data });
    //   return response.data;
  } catch (err) {
    dispatch({ type: GET_ADS_LOADING, payload: null });

    //   if (err.response.status === 422) {
    //     return toast.error(`Validation error`);
    //   }
  }
};

export const increaseAdCount = (adId) => async (dispatch) => {
  dispatch({ type: INCREASE_AD_COUNT_LOADING, payload: true });
  dispatch({ type: INCREASE_AD_COUNT_ERROR, payload: null });
  try {
    const response = await axios.get(`${apiBaseUrl}/admin/ads/count/${adId}`);
    dispatch({ type: INCREASE_AD_COUNT_LOADING, payload: null });

    if (response.data.status !== 200) {
      return toast.error(response.data.message);
    }
    dispatch({ type: INCREASE_AD_COUNT_SUCCESS, payload: response.data });
    return response.data;
  } catch (err) {
    dispatch({ type: INCREASE_AD_COUNT_LOADING, payload: null });

    //   if (err.response.status === 422) {
    //     return toast.error(`Validation error`);
    //   }
  }
};

export const verifyEmail = (token) => async (dispatch) => {
  dispatch({ type: VERIFY_EMAIL_LOADING, payload: true });
  dispatch({ type: VERIFY_EMAIL_ERROR, payload: null });
  try {
    const response = await axios.get(
      `${apiBaseUrl}/auth/confirm/email/${token}`
    );
    dispatch({ type: VERIFY_EMAIL_LOADING, payload: null });

    if (response.data.status !== 200) {
      return toast.error(response.data.message);
    }
    dispatch({ type: VERIFY_EMAIL_SUCCESS, payload: response.data });
    return response.data;
  } catch (err) {
    dispatch({ type: VERIFY_EMAIL_LOADING, payload: null });

    //   if (err.response.status === 422) {
    //     return toast.error(`Validation error`);
    //   }
  }
};
