import { Formik } from "formik";
import React, { useEffect } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import LogoImg from "../assets/logo.svg";
import SolidButton from "../components/Buttons/SolidButton";
import CustomInputCmp from "../components/InputsCmp/CustomInputCmp";
import { connect } from "react-redux";
import SpinnerCmp from "../components/SpinnerCmp/SpinnerCmp";
import { toast } from "react-toastify";
import {
  getAMarketer,
  submitShopDetails,
} from "../redux/actions/marketerAction";
import EmptyCmp from "../components/EmptyCmp/EmptyCmp";
import submitShopDetailsSchema from "../validators/submitShopDetailsValidator";

const Container = styled.div`
  max-width: 375px;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  padding: 1rem;
  margin: 3rem auto;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: inherit;
`;

const Logo = styled(LazyLoadImage)`
  cursor: pointer;
  padding: 0 0 10px;
`;

const Welcome = styled.h2`
  font-weight: 700;
  font-size: 20px;
  color: #1d1e2c;
  padding: 10px 0;
  margin: 0;
  text-align: center;

  @media only screen and (max-width: 375px) {
    font-size: 18px;
  }
`;

const Desc = styled.p`
  font-weight: 400;
  font-size: 16px;
  color: #526366;
  margin: 0;
  text-align: center;

  @media only screen and (max-width: 375px) {
    font-size: 14px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
`;

const InputWrapper = styled.div`
  width: 100%;
`;

const InputLabel = styled.h2`
  font-weight: 500;
  font-size: 14px;
  color: #6c6c74;
`;

const Marketing = ({
  setGetAMarketer,
  getAMarketerLoading,
  getAMarketerSuccess,
  getAMarketerError,
  setSubmitShopDetails,
  submitShopDetailsLoading,
  submitShopDetailsSuccess,
  submitShopDetailsError,
}) => {
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!params) {
      navigate("/");
    }
    async function getMarketerDetails() {
      await setGetAMarketer(params?.marketerId);
    }
    getMarketerDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.marketerId]);

  return (
    <>
      <Container>
        <Wrapper>
          <Logo src={LogoImg} alt="Vendar" />
          {getAMarketerLoading ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <SpinnerCmp
                enabled={true}
                color={"#fff"}
                size={40}
                secondaryColor={"#01525F"}
              />
            </div>
          ) : getAMarketerError ? (
            <EmptyCmp firstText={"Invalid Marketer ID"} />
          ) : (
            <>
              <Welcome>Submit shop details</Welcome>
              <Desc>
                Dear {getAMarketerSuccess?.data?.firstName}, use the form below
                to submit vendors you have onboarded
              </Desc>
              <Formik
                validationSchema={submitShopDetailsSchema}
                enableReinitialize={false}
                isInitialValid={true}
                initialValues={{
                  shopUrl: "",
                  shopName: "",
                }}
                onSubmit={async (values, { resetForm }) => {
                  const response = await setSubmitShopDetails(
                    {
                      shopName: values.shopName,
                      shopUrl: values.shopUrl,
                    },
                    getAMarketerSuccess?.data?._id
                  );

                  if (
                    response &&
                    response.status === 200 &&
                    response.message === "Shop details saved successfully"
                  ) {
                    toast.success(`Shop details saved successfully`);
                    resetForm();
                  }
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  setFieldValue,
                  values,
                  touched,
                  isValid,
                  errors,
                }) => (
                  <>
                    <form
                      style={{
                        width: "100%",
                        paddingTop: "1rem",
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                      noValidate
                      onSubmit={handleSubmit}
                    >
                      <InputWrapper>
                        <InputLabel>Shop Name</InputLabel>
                        <CustomInputCmp
                          value={values.shopName}
                          onChange={handleChange}
                          name="shopName"
                          placeholder="Enter shop name"
                          message={`${
                            errors["shopName"] ? errors["shopName"] : ""
                          }`}
                          showMessage
                        />
                      </InputWrapper>
                      <InputWrapper>
                        <InputLabel>Vendor Shop URL</InputLabel>
                        <CustomInputCmp
                          value={values.shopUrl}
                          onChange={handleChange}
                          name="shopUrl"
                          placeholder="https://vendar.co/shop/ab-store"
                          message={`${
                            errors["shopUrl"] ? errors["shopUrl"] : ""
                          }`}
                          showMessage
                        />
                      </InputWrapper>
                      <ButtonWrapper>
                        <SolidButton
                          text={
                            submitShopDetailsLoading ? (
                              <>
                                <SpinnerCmp
                                  enabled={true}
                                  color={"000"}
                                  size={20}
                                  secondaryColor={"#fff"}
                                />
                              </>
                            ) : (
                              "Submit"
                            )
                          }
                          type="submit"
                          weighty="700"
                          textFontSize="16px"
                          specifyPadding="1rem 1rem"
                          color="#fff"
                          borderRadius="11px"
                          backColor="#01525F"
                          backgroundHoverColor="#01525F"
                          textHoverColor="#fff"
                          widthWebkit="100%"
                          widthMoz="100%"
                          widthNormal="100%"
                          margin="0"
                          disabled={submitShopDetailsLoading}
                        />
                      </ButtonWrapper>
                    </form>
                  </>
                )}
              </Formik>
            </>
          )}
        </Wrapper>
      </Container>
    </>
  );
};

const mapState = (state) => ({
  getAMarketerLoading: state.marketing.getAMarketerLoading,
  getAMarketerError: state.marketing.getAMarketerError,
  getAMarketerSuccess: state.marketing.getAMarketerSuccess,
  submitShopDetailsLoading: state.marketing.submitShopDetailsLoading,
  submitShopDetailsError: state.marketing.submitShopDetailsError,
  submitShopDetailsSuccess: state.marketing.submitShopDetailsSuccess,
});

const mapDispatch = (dispatch) => ({
  setGetAMarketer: (data) => dispatch(getAMarketer(data)),
  setSubmitShopDetails: (data, marketerId) =>
    dispatch(submitShopDetails(data, marketerId)),
});

export default connect(mapState, mapDispatch)(Marketing);
