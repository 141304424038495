import React, { useEffect } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import styled from "styled-components";
import LogoImg from "../assets/logo.svg";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { verifyEmail } from "../redux/actions/shopAction";
import SpinnerCmp from "../components/SpinnerCmp/SpinnerCmp";

const Container = styled.div`
  max-width: 375px;
  width: 100%;
  min-height: 70vh;
  height: 100%;
  padding: 1rem;
  margin: 3rem auto;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: inherit;
`;

const Logo = styled(LazyLoadImage)`
  cursor: pointer;
  padding: 0 0 10px;
`;

const Welcome = styled.h2`
  font-weight: 700;
  font-size: 20px;
  color: #1d1e2c;
  padding: 10px 0;
  margin: 0;
  text-align: center;

  @media only screen and (max-width: 375px) {
    font-size: 18px;
  }
`;

// const Desc = styled.p`
//   font-weight: 400;
//   font-size: 16px;
//   color: #526366;
//   margin: 0;
//   text-align: center;

//   @media only screen and (max-width: 375px) {
//     font-size: 14px;
//   }
// `;

const EmailVerification = ({
  getVerifyEmailError,
  getVerifyEmailLoading,
  getVerifyEmailSuccess,
  setVerifyEmail,
}) => {
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!params) {
      navigate("/");
    }
    async function getVerifyEmailDetails() {
      await setVerifyEmail(params?.emailToken);
    }
    getVerifyEmailDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.emailToken]);
  return (
    <>
      <Container>
        <Wrapper>
          <Logo src={LogoImg} alt="Vendar" />
          <>
            <Welcome>
              {getVerifyEmailLoading ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <SpinnerCmp
                    enabled={true}
                    color={"#fff"}
                    size={40}
                    secondaryColor={"#01525F"}
                  />
                </div>
              ) : (
                <>
                  {" "}
                  {getVerifyEmailSuccess
                    ? "Email Verification Successful"
                    : "Email Verification Failed"}
                </>
              )}
            </Welcome>
          </>
        </Wrapper>
      </Container>
    </>
  );
};

const mapState = (state) => ({
  getVerifyEmailLoading: state.shop.verifyEmailLoading,
  getVerifyEmailError: state.shop.verifyEmailError,
  getVerifyEmailSuccess: state.shop.verifyEmailSuccess,
});

const mapDispatch = (dispatch) => ({
  setVerifyEmail: (token) => dispatch(verifyEmail(token)),
});

export default connect(mapState, mapDispatch)(EmailVerification);
