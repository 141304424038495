import React, { useState } from "react";
import { Container, Form } from "react-bootstrap";
import NumberFormat from "react-number-format";
import styled from "styled-components";
import SolidButton from "../components/Buttons/SolidButton";
import ShopNavbarCmp from "../components/ShopNavbarCmp/ShopNavbarCmp";
import NumericInputCmp from "../components/NumericInputCmp/NumericInputCmp";
import EmptyCmp from "../components/EmptyCmp/EmptyCmp";
import { useEffect } from "react";
import {
  areAllVendorIdsSame,
  calculateTotalPriceAndQuantity,
} from "../utils/cart";
import RemoveModal from "../components/Modals/RemoveModal";
import SuccessModal from "../components/Modals/SuccessModal";
import DeliveryDetailsDrawer from "../components/DeliveryDetailsDrawer/DeliveryDetailsDrawer";
import ShopFooterCmp from "../components/FooterCmp/ShopFooterCmp";
import SpinnerCmp from "../components/SpinnerCmp/SpinnerCmp";
import { Formik } from "formik";
import couponValidatorSchema from "../validators/couponValidator";
import {
  calculateDeliveryPrice,
  verifyCouponCode,
} from "../redux/actions/shopAction";
import { connect } from "react-redux";
import TickErrorImg from "../assets/tick-error.svg";
import PayModal from "../components/Modals/PayModal";
import { useNavigate } from "react-router-dom";

const PageWrapper = styled(Container)`
  background-color: #f8f9fb !important;
  padding: 0;
`;

const Wrapper = styled.div`
  /* background-color: #fff; */
  padding: 2rem 0rem;
  width: 100%;
  max-width: calc(1100px + 5rem);
  margin: 1rem auto 5rem;

  @media only screen and (max-width: 1247px) {
    padding: 2rem 1.5rem;
  }

  @media only screen and (max-width: 760px) {
    margin: 1rem auto 4rem;
    max-width: calc(1000px + 1rem);
  }

  @media only screen and (max-width: 547px) {
    margin: 1rem auto 3rem;
    padding: 0rem 1rem;
    background-color: transparent;
  }
`;

const PageTitle = styled.h2`
  font-weight: 500;
  font-size: 20px;
  color: #636e72;
  padding-bottom: 1rem;
`;

const CartWrapper = styled.div`
  display: flex;
  gap: 20px;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const LeftWrapperContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  flex-basis: 65%;
  width: 65%;

  @media only screen and (max-width: 768px) {
    flex-basis: 100%;
    width: 100%;
  }
`;

const LeftWrapper = styled.div`
  background: #ffffff;
  border-radius: 10px;
  height: fit-content;
  padding: 1.5rem 1rem;
  display: flex;
  gap: 15px;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

const InnerWrapperLeft = styled.div`
  flex-basis: 65%;
  width: 65%;
  display: flex;
  gap: 15px;
  align-items: center;

  @media only screen and (max-width: 600px) {
    flex-basis: 100%;
    width: 100%;
  }
`;

const SmallProductImg = styled.img`
  width: 90px;
  aspect-ratio: 1;
`;

const ProductDescWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;

const ProductTitle = styled.h2`
  font-weight: 700;
  font-size: 16px;
  color: #2d3436;
  margin: 0;

  @media only screen and (max-width: 1024px) {
    font-size: 15px;
  }
`;

const VendorName = styled.p`
  margin: 0;
  font-weight: 700;
  font-size: 12px;
  color: #7c7c84;
`;

const Deadline = styled.p`
  margin: 0;
  font-weight: 400;
  font-size: 12px;
  color: #7c7c84;
`;

const InnerWrapperRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: right;
  flex-basis: 35%;
  width: 35%;

  @media only screen and (max-width: 600px) {
    display: none;
  }
`;

const MobileInnerWrapperRight = styled.div`
  display: none;

  @media only screen and (max-width: 600px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }

  @media only screen and (max-width: 374px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const TotalProductPrice = styled.h2`
  margin: 0;
  font-weight: 600;
  font-size: 20px;
  color: #1d1e2c;
`;

const QuantityWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;

  @media only screen and (max-width: 500px) {
    /* justify-content: space-between;
    width: 100%; */
    flex-direction: column-reverse;
    align-items: flex-end;
  }

  @media only screen and (max-width: 374px) {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  @media only screen and (max-width: 345px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const RemoveText = styled.h2`
  margin: 0;
  font-weight: 600;
  font-size: 16px;
  color: #01525f;
  cursor: pointer;
`;

const QuantityInputWrapper = styled.div``;

const RightWrapper = styled.div`
  background: #ffffff;
  border-radius: 10px;
  flex-basis: 35%;
  width: 35%;
  padding: 1.5rem 1rem;

  @media only screen and (max-width: 768px) {
    flex-basis: 100%;
    width: 100%;
  }
`;

const OrderSummaryWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SummaryText = styled.h2`
  font-weight: 500;
  font-size: 16px;
  color: #1d1e2c;
  margin: 0;
`;

const Line = styled.hr`
  width: 100%;
  height: 1px;
  color: #dfe6e9;
  border-top: 1px solid #dfe6e9;
`;

const DeliveryWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: ${(props) => (props.alignItems ? props.alignItems : "center")};
`;

const DeliveryChargesText = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: #636e72;
  margin: 0;
`;

const DeliveryChargesLeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  text-align: right;
`;

const DeliveryChargesLeftText = styled.h2`
  font-weight: 700;
  font-size: 14px;
  color: ${(props) => (props.color ? props.color : "#7c7c84")};
  margin: 0;
  cursor: pointer;
`;

const TotalWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TotalText = styled.h2`
  font-weight: 700;
  font-size: 16px;
  color: #1d1e2c;
  margin: 0;
`;

const SmallText = styled.p`
  font-weight: 400;
  font-size: 12px;
  text-align: right;
  color: #7c7c84;
  margin: 0;
  padding: 0.7rem 0;
`;

const ButtonWrapper = styled.div`
  width: 100%;
`;

const CouponWrapper = styled.div``;

const Input = styled(Form.Control)`
  padding: 1rem;
  padding-right: 8rem;
  border-radius: 5px;
  border: ${(props) => (props.message ? "1px solid red" : "1px solid #ADB5BD")};
  background: rgba(243, 244, 246, 0.7);
  border-radius: 8px;
  color: #374151;
  font-weight: 500;
  font-size: 18px;
  &:focus {
    box-shadow: none;
    border: 1px solid #01525f;
    background: rgba(243, 244, 246, 0.7);
  }
  &::placeholder {
    color: rgba(107, 114, 128, 0.3);
    font-weight: 400;
    font-size: 14px;
  }
  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: rgba(107, 114, 128, 0.3);
    font-weight: 400;
    font-size: 14px;
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: rgba(107, 114, 128, 0.3);
    font-weight: 400;
    font-size: 14px;
  }

  @media only screen and (max-width: 400px) {
    padding-right: 6rem;
  }
`;

const FormWrapper = styled(Form)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 1rem 0rem;
`;

const Cart = ({
  loading,
  setVerifyCouponCode,
  verifyCouponCodeError,
  verifyCouponCodeLoading,
  verifyCouponCodeSuccess,
  calculateDeliveryFeeError,
  calculateDeliveryFeeLoading,
  calculateDeliveryFeeSuccess,
  setCalculateDeliveryFee,
}) => {
  const [triggerWhenProductRemoved, setTriggerWhenProductRemoved] =
    useState(false);
  const [triggerWhenQuantityChanged, setTriggerWhenQuantityChanged] =
    useState(false);
  const [ordersArr, setOrdersArr] = useState(
    JSON.parse(localStorage.getItem("orders")) || []
  );
  const [objToRemove, setObjToRemove] = useState({});
  const [couponDetails, setCouponDetails] = useState(
    JSON.parse(localStorage.getItem("couponDetails")) || {}
  );
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [showPayModal, setShowPayModal] = useState(false);
  const [openDeliveryDrawer, setOpenDeliveryDrawer] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showPaySuccessModal, setShowPaySuccessModal] = useState(false);
  const [totalPriceAndQuantity, setTotalPriceAndQuantity] = useState({});
  const [deliveryFee, setDeliveryFee] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    setOrdersArr(JSON.parse(localStorage.getItem("orders")));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerWhenProductRemoved, triggerWhenQuantityChanged]);

  useEffect(() => {
    if (ordersArr) {
      setTotalPriceAndQuantity(calculateTotalPriceAndQuantity(ordersArr));
    }
  }, [ordersArr]);

  useEffect(() => {
    if (ordersArr && ordersArr.length) {
      async function calculateDeliveryFee() {
        const response = await setCalculateDeliveryFee({
          deliveryType: JSON.parse(localStorage.getItem("deliveryDetails"))
            ?.deliveryType,
          productCategoryArrId: ordersArr?.map((v) => {
            return { id: v.productCategoryId, quantity: v.quantity };
          }),
        });

        setDeliveryFee(response?.data);
      }
      calculateDeliveryFee();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ordersArr, openDeliveryDrawer]);

  return (
    <>
      <PageWrapper fluid>
        <ShopNavbarCmp logoType="Vendar" />

        <Wrapper>
          {!JSON.parse(localStorage.getItem("orders"))?.length ? (
            <EmptyCmp
              firstText="Your cart is empty!"
              secondText="Browse our shop and discover our best deals!"
              buttonText="Start shopping"
              buttonAction={() => {
                navigate("/shop");
              }}
            />
          ) : (
            <>
              <PageTitle>
                Shopping Cart{" "}
                {!areAllVendorIdsSame(ordersArr) && (
                  <span
                    style={{
                      fontSize: "12px",
                      fontWeight: "400",
                      fontStyle: "italic",
                      color: "red",
                    }}
                  >
                    (You cannot order from multiple vendors at a time)
                  </span>
                )}
              </PageTitle>
              <CartWrapper>
                <LeftWrapperContainer>
                  {ordersArr?.map((v, i) => {
                    return (
                      <LeftWrapper>
                        <InnerWrapperLeft>
                          <SmallProductImg
                            src={v.images[0]?.url}
                            alt={v.slug}
                          />
                          <ProductDescWrapper>
                            <ProductTitle>{v.productName}</ProductTitle>
                            <VendorName>
                              by{" "}
                              <span style={{ color: "#1D1E2C" }}>
                                {v.storeName}
                              </span>
                            </VendorName>
                            <Deadline>
                              Ships by no later than 1/12/2023
                            </Deadline>

                            <MobileInnerWrapperRight>
                              <TotalProductPrice>
                                <NumberFormat
                                  thousandSeparator
                                  prefix="₦"
                                  value={v.totalPrice}
                                  decimalScale={2}
                                  defaultValue="0.00"
                                  allowNegative={false}
                                  placeholder="0.00"
                                  displayType="text"
                                  disabled
                                />
                              </TotalProductPrice>
                              <QuantityWrapper>
                                <RemoveText
                                  onClick={() => {
                                    setObjToRemove(v);
                                    setShowRemoveModal(true);
                                  }}
                                >
                                  Remove
                                </RemoveText>
                                <QuantityInputWrapper>
                                  <NumericInputCmp
                                    onChange={(val) => {
                                      const editedArr = [
                                        ...ordersArr.slice(0, i),
                                        {
                                          ...v,
                                          quantity: val,
                                          totalPrice: val * parseFloat(v.price),
                                        },
                                        ...ordersArr.slice(i + 1),
                                      ];
                                      localStorage.setItem(
                                        "orders",
                                        JSON.stringify(editedArr)
                                      );
                                      setTriggerWhenQuantityChanged(
                                        !triggerWhenQuantityChanged
                                      );
                                    }}
                                    value={v.quantity}
                                    max={v.stockQty || undefined}
                                  />
                                </QuantityInputWrapper>
                              </QuantityWrapper>
                            </MobileInnerWrapperRight>
                          </ProductDescWrapper>
                        </InnerWrapperLeft>
                        <InnerWrapperRight>
                          <TotalProductPrice>
                            <NumberFormat
                              thousandSeparator
                              prefix="₦"
                              value={v.totalPrice}
                              decimalScale={0}
                              defaultValue="0.00"
                              allowNegative={false}
                              placeholder="0.00"
                              displayType="text"
                              disabled
                            />
                          </TotalProductPrice>
                          <QuantityWrapper>
                            <RemoveText
                              onClick={() => {
                                setObjToRemove(v);
                                setShowRemoveModal(true);
                              }}
                            >
                              Remove
                            </RemoveText>
                            <QuantityInputWrapper>
                              <NumericInputCmp
                                onChange={(val) => {
                                  const editedArr = [
                                    ...ordersArr.slice(0, i),
                                    {
                                      ...v,
                                      quantity: val,
                                      totalPrice: val * parseFloat(v.price),
                                    },
                                    ...ordersArr.slice(i + 1),
                                  ];
                                  localStorage.setItem(
                                    "orders",
                                    JSON.stringify(editedArr)
                                  );
                                  setTriggerWhenQuantityChanged(
                                    !triggerWhenQuantityChanged
                                  );
                                }}
                                value={v.quantity}
                                max={v.stockQty || undefined}
                              />
                            </QuantityInputWrapper>
                          </QuantityWrapper>
                        </InnerWrapperRight>
                      </LeftWrapper>
                    );
                  })}
                </LeftWrapperContainer>
                <RightWrapper>
                  <OrderSummaryWrapper>
                    <SummaryText>Order Summary</SummaryText>
                    <SummaryText>{ordersArr?.length || 0} Item(s)</SummaryText>
                  </OrderSummaryWrapper>
                  <Line />
                  <DeliveryWrapper alignItems="flex-start">
                    <DeliveryChargesText>Delivery charges:</DeliveryChargesText>
                    <DeliveryChargesLeftContainer>
                      <DeliveryChargesLeftText>
                        {couponDetails?.couponType === "Free Shipping" ? (
                          <NumberFormat
                            thousandSeparator
                            prefix="₦"
                            value={"0"}
                            decimalScale={2}
                            defaultValue="0.00"
                            allowNegative={false}
                            placeholder="0.00"
                            displayType="text"
                            disabled
                          />
                        ) : calculateDeliveryFeeLoading ? (
                          "Loading..."
                        ) : (
                          <NumberFormat
                            thousandSeparator
                            prefix="₦"
                            value={deliveryFee}
                            decimalScale={2}
                            defaultValue="0.00"
                            allowNegative={false}
                            placeholder="0.00"
                            displayType="text"
                            disabled
                          />
                        )}
                      </DeliveryChargesLeftText>
                      <DeliveryChargesLeftText>
                        {
                          JSON.parse(localStorage.getItem("deliveryDetails"))
                            ?.deliveryType
                        }{" "}
                        delivery
                      </DeliveryChargesLeftText>
                      <DeliveryChargesLeftText
                        color="#01525F"
                        onClick={() => {
                          setOpenDeliveryDrawer(true);
                        }}
                      >
                        Change delivery method
                      </DeliveryChargesLeftText>
                    </DeliveryChargesLeftContainer>
                  </DeliveryWrapper>
                  <Line />
                  <DeliveryWrapper>
                    <DeliveryChargesText>Subtotal:</DeliveryChargesText>
                    <DeliveryChargesLeftText>
                      <NumberFormat
                        thousandSeparator
                        prefix="₦"
                        value={totalPriceAndQuantity?.totalPrice}
                        decimalScale={2}
                        defaultValue="0.00"
                        allowNegative={false}
                        placeholder="0.00"
                        displayType="text"
                        disabled
                      />
                    </DeliveryChargesLeftText>
                  </DeliveryWrapper>
                  <Line />
                  {couponDetails && couponDetails?.couponCode && (
                    <>
                      <DeliveryWrapper>
                        <DeliveryChargesText>Coupon:</DeliveryChargesText>
                        <DeliveryChargesLeftText>
                          {couponDetails?.couponType === "Percentage" ? (
                            `${couponDetails?.amount}% Off`
                          ) : couponDetails?.couponType === "Fixed" ||
                            couponDetails?.couponType === "Fixed Shipping" ? (
                            <>
                              <NumberFormat
                                thousandSeparator
                                prefix="₦"
                                value={couponDetails?.amount}
                                decimalScale={2}
                                defaultValue="0.00"
                                allowNegative={false}
                                placeholder="0.00"
                                displayType="text"
                                disabled
                              />{" "}
                              {"Off"}{" "}
                              {couponDetails?.couponType === "Fixed Shipping"
                                ? "on shipping"
                                : ""}
                            </>
                          ) : (
                            couponDetails?.couponType
                          )}
                        </DeliveryChargesLeftText>
                      </DeliveryWrapper>
                      <Line />
                    </>
                  )}
                  <TotalWrapper>
                    <TotalText>Total</TotalText>
                    <TotalText>
                      <NumberFormat
                        thousandSeparator
                        prefix="₦"
                        value={
                          couponDetails?.couponType === "Percentage"
                            ? totalPriceAndQuantity?.totalPrice +
                              parseFloat(deliveryFee) -
                              (parseFloat(couponDetails?.amount) / 100) *
                                (totalPriceAndQuantity?.totalPrice +
                                  parseFloat(deliveryFee))
                            : couponDetails?.couponType === "Fixed" ||
                              couponDetails?.couponType === "Fixed Shipping"
                            ? totalPriceAndQuantity?.totalPrice +
                              parseFloat(deliveryFee) -
                              parseFloat(couponDetails?.amount)
                            : couponDetails?.couponType === "Free Shipping"
                            ? totalPriceAndQuantity?.totalPrice
                            : totalPriceAndQuantity?.totalPrice +
                              parseFloat(deliveryFee)
                        }
                        decimalScale={2}
                        defaultValue="0.00"
                        allowNegative={false}
                        placeholder="0.00"
                        displayType="text"
                        disabled
                      />
                    </TotalText>
                  </TotalWrapper>
                  <SmallText>Including delivery charge</SmallText>
                  <CouponWrapper>
                    <DeliveryChargesText>Enter coupon</DeliveryChargesText>
                    <Formik
                      validationSchema={couponValidatorSchema}
                      initialValues={{
                        coupon: "",
                      }}
                      onSubmit={async (values, { resetForm }) => {
                        const response = await setVerifyCouponCode({
                          couponCode: values.coupon,
                          email: JSON.parse(
                            localStorage.getItem("deliveryDetails")
                          ).email,
                        });

                        setShowSuccessModal(true);

                        if (
                          response &&
                          response.message === "Coupon code is valid"
                        ) {
                          resetForm();
                          setCouponDetails(response.data);
                          localStorage.setItem(
                            "couponDetails",
                            JSON.stringify(response.data)
                          );
                        }
                      }}
                    >
                      {({
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        setFieldValue,
                        values,
                        touched,
                        isValid,
                        errors,
                      }) => (
                        <>
                          <FormWrapper noValidate onSubmit={handleSubmit}>
                            <Input
                              value={values.coupon}
                              onChange={handleChange}
                              name="coupon"
                              placeholder="e.g 1234567"
                              message={`${
                                errors["coupon"] ? errors["coupon"] : ""
                              }`}
                            />
                            <SolidButton
                              customStyle={{
                                position: "absolute",
                                right: "0.8rem",
                              }}
                              text={
                                verifyCouponCodeLoading ? (
                                  <>
                                    <SpinnerCmp
                                      enabled={true}
                                      color={"000"}
                                      size={20}
                                      secondaryColor={"#fff"}
                                    />
                                  </>
                                ) : (
                                  "Apply"
                                )
                              }
                              type="submit"
                              weighty="700"
                              textFontSize="16px"
                              specifyPadding="10px 24px"
                              color="#fff"
                              borderRadius="16px"
                              backColor="#01525F"
                              backgroundHoverColor="#01525F"
                              textHoverColor="#fff"
                              widthWebkit="100%"
                              widthMoz="100%"
                              widthNormal="100%"
                              margin="0"
                              maxWidth="100px"
                              disabled={verifyCouponCodeLoading ? true : false}
                            />
                          </FormWrapper>
                        </>
                      )}
                    </Formik>
                  </CouponWrapper>
                  <ButtonWrapper>
                    <SolidButton
                      text={
                        <>
                          Check Out{" "}
                          <NumberFormat
                            thousandSeparator
                            prefix="₦"
                            value={
                              couponDetails?.couponType === "Percentage"
                                ? totalPriceAndQuantity?.totalPrice +
                                  parseFloat(deliveryFee) -
                                  (parseFloat(couponDetails?.amount) / 100) *
                                    (totalPriceAndQuantity?.totalPrice +
                                      parseFloat(deliveryFee))
                                : couponDetails?.couponType === "Fixed" ||
                                  couponDetails?.couponType === "Fixed Shipping"
                                ? totalPriceAndQuantity?.totalPrice +
                                  parseFloat(deliveryFee) -
                                  parseFloat(couponDetails?.amount)
                                : couponDetails?.couponType === "Free Shipping"
                                ? totalPriceAndQuantity?.totalPrice
                                : totalPriceAndQuantity?.totalPrice +
                                  parseFloat(deliveryFee)
                            }
                            decimalScale={2}
                            defaultValue="0.00"
                            allowNegative={false}
                            placeholder="0.00"
                            displayType="text"
                            disabled
                          />
                        </>
                      }
                      type="submit"
                      weighty="700"
                      textFontSize="16px"
                      specifyPadding="1rem 0rem"
                      color="#fff"
                      borderRadius="12px"
                      backColor="#01525F"
                      backgroundHoverColor="#01525F"
                      textHoverColor="#fff"
                      widthWebkit="100%"
                      widthMoz="100%"
                      widthNormal="100%"
                      margin="0"
                      disabled={
                        calculateDeliveryFeeLoading
                          ? true
                          : !areAllVendorIdsSame(ordersArr)
                          ? true
                          : false
                      }
                      onClick={() => {
                        setShowPayModal(true);
                      }}
                    />
                  </ButtonWrapper>
                </RightWrapper>
              </CartWrapper>
            </>
          )}
        </Wrapper>
        <ShopFooterCmp />
        <RemoveModal
          maxWidth="400px"
          showCloseIcon={false}
          isOpen={showRemoveModal}
          onRequestClose={() => {
            setShowRemoveModal(false);
          }}
          removeFn={() => {
            setShowRemoveModal(false);
            const idxObj = ordersArr.findIndex((object) => {
              return object.id === objToRemove.id;
            });

            ordersArr.splice(idxObj, 1);
            localStorage.setItem("orders", JSON.stringify(ordersArr));
            setTriggerWhenProductRemoved(!triggerWhenProductRemoved);
          }}
        />
        <DeliveryDetailsDrawer
          isOpen={openDeliveryDrawer}
          onClose={() => {
            setOpenDeliveryDrawer(false);
          }}
        />
        <SuccessModal
          heading={
            verifyCouponCodeError
              ? verifyCouponCodeError
              : "Coupon applied Successfully"
          }
          title={
            verifyCouponCodeError
              ? "Please check your coupon details and try again"
              : "Coupon applied Successfully"
          }
          successImgUrl={verifyCouponCodeError && TickErrorImg}
          btnBackColor={verifyCouponCodeError ? "#D3180C" : "#01525F"}
          titleMaxWidth="350px"
          maxWidth="400px"
          wrapperPadding={"0rem 1rem 2rem"}
          showCloseIcon={true}
          isOpen={showSuccessModal}
          buttonCloseText={"Continue"}
          onRequestClose={() => {
            setShowSuccessModal(false);
          }}
        />
        <PayModal
          maxWidth="500px"
          showCloseIcon={false}
          isOpen={showPayModal}
          onRequestClose={() => {
            setShowPayModal(false);
          }}
          ordersArr={ordersArr}
          subtotal={totalPriceAndQuantity?.totalPrice}
          deliveryFee={
            couponDetails?.couponType === "Free Shipping" ? (
              <NumberFormat
                thousandSeparator
                prefix="₦"
                value={"0"}
                decimalScale={0}
                defaultValue="0.00"
                allowNegative={false}
                placeholder="0.00"
                displayType="text"
                disabled
              />
            ) : calculateDeliveryFeeLoading ? (
              "Loading..."
            ) : (
              <NumberFormat
                thousandSeparator
                prefix="₦"
                value={deliveryFee}
                decimalScale={0}
                defaultValue="0.00"
                allowNegative={false}
                placeholder="0.00"
                displayType="text"
                disabled
              />
            )
          }
          total={
            <NumberFormat
              thousandSeparator
              prefix="₦"
              value={
                couponDetails?.couponType === "Percentage"
                  ? totalPriceAndQuantity?.totalPrice +
                    parseFloat(deliveryFee) -
                    (parseFloat(couponDetails?.amount) / 100) *
                      (totalPriceAndQuantity?.totalPrice +
                        parseFloat(deliveryFee))
                  : couponDetails?.couponType === "Fixed" ||
                    couponDetails?.couponType === "Fixed Shipping"
                  ? totalPriceAndQuantity?.totalPrice +
                    parseFloat(deliveryFee) -
                    parseFloat(couponDetails?.amount)
                  : couponDetails?.couponType === "Free Shipping"
                  ? totalPriceAndQuantity?.totalPrice
                  : totalPriceAndQuantity?.totalPrice + parseFloat(deliveryFee)
              }
              decimalScale={2}
              defaultValue="0.00"
              allowNegative={false}
              placeholder="0.00"
              displayType="text"
              disabled
            />
          }
          couponDetails={couponDetails}
          totalPriceAndQuantity={totalPriceAndQuantity}
          setShowPaySuccessModal={setShowPaySuccessModal}
        />
        <SuccessModal
          heading={"Payment Successful"}
          title={"Vendors will acknowledge your orders soon"}
          titleMaxWidth="350px"
          maxWidth="400px"
          wrapperPadding={"0rem 1rem 2rem"}
          showCloseIcon={true}
          isOpen={showPaySuccessModal}
          buttonCloseText={"Close"}
          onRequestClose={() => {
            setShowPaySuccessModal(false);
            navigate("/shop");
          }}
          btnBackColor={"#01525F"}
        />
      </PageWrapper>
    </>
  );
};

const mapState = (state) => ({
  verifyCouponCodeLoading: state.shop.verifyCouponCodeLoading,
  verifyCouponCodeError: state.shop.verifyCouponCodeError,
  verifyCouponCodeSuccess: state.shop.verifyCouponCodeSuccess,
  calculateDeliveryFeeLoading: state.shop.calculateDeliveryFeeLoading,
  calculateDeliveryFeeError: state.shop.calculateDeliveryFeeError,
  calculateDeliveryFeeSuccess: state.shop.calculateDeliveryFeeSuccess,
});

const mapDispatch = (dispatch) => ({
  setVerifyCouponCode: (data) => dispatch(verifyCouponCode(data)),
  setCalculateDeliveryFee: (data) => dispatch(calculateDeliveryPrice(data)),
});

export default connect(mapState, mapDispatch)(Cart);
