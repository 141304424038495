import React from "react";
import EmptyCmp from "../components/EmptyCmp/EmptyCmp";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Container } from "react-bootstrap";
import ShopNavbarCmp from "../components/ShopNavbarCmp/ShopNavbarCmp";

const PageWrapper = styled(Container)`
  background-color: #f8f9fb !important;
  padding: 0;
  min-height: 100vh;
`;

const Wrapper = styled.div`
  /* background-color: #fff; */
  padding: 2rem 0rem;
  width: 100%;
  max-width: calc(1100px + 5rem);
  margin: 1rem auto 5rem;

  @media only screen and (max-width: 1247px) {
    padding: 2rem 1.5rem;
  }

  @media only screen and (max-width: 760px) {
    margin: 1rem auto 4rem;
    max-width: calc(1000px + 1rem);
  }

  @media only screen and (max-width: 547px) {
    margin: 1rem auto 3rem;
    padding: 0rem 1rem;
    background-color: transparent;
  }
`;

const ErrorPage = () => {
  const navigate = useNavigate();
  return (
    <PageWrapper fluid>
      <ShopNavbarCmp logoType="Vendar" />
      <Wrapper>
        <EmptyCmp
          firstText="Page not found!"
          secondText="Browse our shop and discover our best deals!"
          buttonText="Visit Shop"
          buttonAction={() => {
            navigate("/shop");
          }}
        />
      </Wrapper>
    </PageWrapper>
  );
};

export default ErrorPage;
