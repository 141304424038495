import React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import { Fade } from "react-awesome-reveal";
import UnderlineTextCmp from "../UnderlineTextCmp/UnderlineTextCmp";
import "./HowItWorksCmp.css";
import "devices.css";
import Slide1 from "../../assets/slide-1.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, EffectFade } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-fade";

const ContainerDiv = styled(Container)`
  padding: 6rem 2rem 4rem;
  background-color: #f4f5f8;
  margin-top: -3.1rem;
  clip-path: polygon(0 0, 100% 5%, 100% 100%, 0% 100%);

  @media only screen and (max-width: 500px) {
    padding: 6rem 1rem 3rem;
  }
`;

const Wrapper = styled.div`
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
`;

const BigTitle = styled.h2`
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
  font-weight: 500;
  font-size: 35px;
  color: #1d1e2c;
  line-height: 45px;

  @media only screen and (max-width: 500px) {
    font-size: 30px;
    line-height: 40px;
  }
`;

const Desc = styled.p`
  max-width: 360px;
  width: 100%;
  margin: 0 auto;
  font-weight: 400;
  font-size: 14px;
  color: #1d1e2c;
  opacity: 0.7;
  padding-top: 0.5rem;

  @media only screen and (max-width: 500px) {
    max-width: 320px;
  }
`;

const DeviceWrapper = styled.div`
  padding-top: 3rem;
`;

const HowItWorksCmp = () => {
  return (
    <>
      <ContainerDiv fluid>
        <Wrapper>
          <Fade cascade durattion="200" direction="up" triggerOnce>
            <BigTitle>
              See how Vendar works on{" "}
              <span style={{ fontWeight: "800" }}>
                any <UnderlineTextCmp>platform</UnderlineTextCmp>
              </span>
            </BigTitle>
            <Desc>
              Fully managed from a single platform and integrated with your
              preferred channels.
            </Desc>
          </Fade>
          <DeviceWrapper>
            <div className="device device-iphone-x">
              <div className="device-frame">
                <div style={{ overflow: "hidden", borderRadius: "30px" }}>
                  <Swiper
                    className="instagram-carousel"
                    modules={[Autoplay, Pagination, EffectFade]}
                    slidesPerView={1}
                    autoplay={{
                      delay: 5000,
                    }}
                    fadeEffect={{ crossFade: true }}
                    effect={"fade"}
                    speed={1500}
                    pagination={{
                      clickable: true,
                      renderBullet: (index, className) => {
                        return `<div class=${className}>
            </div>`;
                      },
                    }}
                    style={{ width: "350px" }}
                    spaceBetween={50}
                    loop={true}
                  >
                    <SwiperSlide>
                      <img src={Slide1} alt={`Callsesh`} />
                    </SwiperSlide>{" "}
                    <SwiperSlide>
                      <img src={Slide1} alt={`Callsesh`} />
                    </SwiperSlide>
                  </Swiper>
                </div>
              </div>
              <div className="device-stripe" />
              <div className="device-header" />
              <div className="device-sensors" />
              <div className="device-btns" />
              <div className="device-power" />
            </div>
          </DeviceWrapper>
        </Wrapper>
      </ContainerDiv>
    </>
  );
};

export default HowItWorksCmp;
