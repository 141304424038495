import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { connect } from "react-redux";
import { LazyLoadImage } from "react-lazy-load-image-component";
import styled from "styled-components";
import GooglePlayImage from "../../assets/Play.svg";
import AppleStoreImage from "../../assets/Apple.svg";
import { Fade } from "react-awesome-reveal";
import VendarMobile from "../../assets/vendar-mobile.svg";
import HeroBg from "../../assets/hero-bg.svg";
import FaviconImg from "../../assets/favicon.svg";
import Typewriter from "typewriter-effect";
import "./HeroCmp.css";
import SolidButton from "../Buttons/SolidButton";
// import CustomInputCmp from "../InputsCmp/CustomInputCmp";
// import { Formik } from "formik";
// import newsletterValidatorSchema from "../../validators/newsletterValidator";
// import { PaperPlaneOutline } from "@styled-icons/evaicons-outline";
import { subscribe } from "../../redux/actions/newsletterAction";
// import SpinnerCmp from "../SpinnerCmp/SpinnerCmp";
import SuccessModal from "../Modals/SuccessModal";
import ReactStars from "react-rating-stars-component";

const ContainerDiv = styled(Container)`
  margin: ${(props) => props.margin && props.margin};
  padding: 5rem 3rem 4rem;
  background-color: ${(props) => (props.bgColor ? props.bgColor : "#fff")};
  background: url(${HeroBg}), #ffffff no-repeat;
  clip-path: ${(props) =>
    props.isSlant && "polygon(0 0, 100% 0, 100% 100%, 0 95%)"};

  @media only screen and (max-width: 768px) {
    padding-top: 7rem;
  }

  @media only screen and (max-width: 500px) {
    padding: 6rem 1rem 3rem;
  }
`;

const Wrapper = styled.div`
  max-width: calc(1100px + 3rem);
  width: 100%;
  margin: 0 auto;
  text-align: center;
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 450px) {
    flex-wrap: wrap;
    gap: 15px;
  }
`;

const LeftSection = styled.div`
  max-width: 500px;
  width: 100%;
  text-align: left;
  flex: 0 0 50%;

  @media only screen and (max-width: 450px) {
    flex: unset;
    max-width: 100%;
  }
`;

const BigTitle = styled.h2`
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  font-weight: 500;
  font-size: 65px;
  color: #01525f;
  line-height: 70px;

  @media only screen and (max-width: 790px) {
    font-size: 50px;
  }

  @media only screen and (max-width: 751px) {
    font-size: 45px;
  }

  @media only screen and (max-width: 700px) {
    font-size: 40px;
    line-height: 50px;
  }

  @media only screen and (max-width: 600px) {
    font-size: 45px;
    line-height: 60px;
  }

  @media only screen and (max-width: 550px) {
    font-size: 35px;
    line-height: 50px;
  }

  @media only screen and (max-width: 450px) {
    font-size: 45px;
    line-height: 50px;
  }

  @media only screen and (max-width: 370px) {
    font-size: 40px;
    line-height: 50px;
  }
`;

const Desc = styled.p`
  max-width: 340px;
  width: 100%;
  font-weight: 400;
  font-size: 15px;
  color: #1d1e2c;
  opacity: 0.7;
  padding-top: 20px;

  @media only screen and (max-width: 500px) {
    max-width: 300px;
    font-size: 15px;
    padding-top: 24px;
  }
`;

const StoreWrapper = styled.div`
  display: flex;
  gap: 10px;
  padding-top: 20px;
  justify-content: flex-start;

  @media only screen and (max-width: 450px) {
    justify-content: center;
  }
`;

const StoreImg = styled(LazyLoadImage)`
  cursor: pointer;

  @media only screen and (max-width: 700px) {
    width: 100px;
  }

  @media only screen and (max-width: 450px) {
    width: 120px;
  }
`;

const RightImg = styled(LazyLoadImage)`
  width: unset;
  max-width: 523px;
  @media only screen and (max-width: 1000px) {
    width: 100%;
  }

  @media only screen and (max-width: 600px) {
    width: 80%;
  }
`;

// const EmailWrapper = styled.div`
//   display: flex;
//   gap: 10px;

//   @media only screen and (max-width: 450px) {
//     flex-direction: column;
//   }
// `;

// const InputWrapper = styled.div`
//   flex: 70%;

//   @media only screen and (max-width: 930px) {
//     flex: 60%;
//   }

//   @media only screen and (max-width: 821px) {
//     flex: 50%;
//   }

//   @media only screen and (max-width: 730px) {
//     flex: 80%;
//   }

//   @media only screen and (max-width: 650px) {
//     flex: 100%;
//     padding-top: 18px;
//   }
// `;

// const ButtonWrapper = styled.div`
//   flex: 30%;

//   @media only screen and (max-width: 730px) {
//     display: none;
//   }

//   @media only screen and (max-width: 450px) {
//     display: unset;
//   }
// `;

// const ButtonWrapperTablet = styled.div`
//   flex: 30%;
//   display: none;

//   @media only screen and (max-width: 730px) {
//     display: unset;
//   }

//   @media only screen and (max-width: 450px) {
//     display: none;
//   }
// `;

const MobileDownloadWrapper = styled.div`
  width: 100%;
  height: 70px;
  background: #cadee1;
  position: fixed;
  bottom: 0;
  display: none;
  z-index: 1000;
  padding: 1rem;

  @media only screen and (max-width: 500px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const MobileLeftDownload = styled.div`
  display: flex;
  gap: 8px;
`;

const TextWrapper = styled.div`
  font-weight: 700;
  font-size: 16px;
  color: #01525f;
`;

const VendarText = styled.p`
  margin: 0;
`;

const MobileIcon = styled.img``;

const MobileRightDownload = styled.div``;

const HeroCmp = ({ loading, setSubscribe, ...props }) => {
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  return (
    <>
      <ContainerDiv fluid isSlant={props.isSlant} margin={props.margin}>
        <Wrapper>
          <ContentWrapper>
            <LeftSection>
              <BigTitle>
                Do{" "}
                <span style={{ fontWeight: "900" }}>
                  business{" "}
                  <span style={{ color: "#5A98A2" }}>
                    <Typewriter
                      options={{
                        strings: ["Better", "Faster", "Smarter"],
                        autoStart: true,
                        loop: true,
                        delay: "natural",
                        wrapperClassName: "Vendar__effect",
                      }}
                    />
                  </span>
                </span>
              </BigTitle>
              <Desc>
                Control everything about your business from your smartphone.
                Your busines is our business.
              </Desc>

              {/* <Formik
                validationSchema={newsletterValidatorSchema}
                initialValues={{
                  email: "",
                }}
                onSubmit={async (values, { resetForm }) => {
                  const response = await setSubscribe({
                    ...values,
                  });
                  if (
                    response &&
                    response.status === 200 &&
                    response.message === "Email submitted successfully"
                  ) {
                    resetForm();
                    setShowSuccessModal(true);
                    return;
                  }
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  setFieldValue,
                  values,
                  touched,
                  isValid,
                  errors,
                }) => (
                  <>
                    {" "}
                    <form noValidate onSubmit={handleSubmit}>
                      {" "}
                      <EmailWrapper>
                        <InputWrapper>
                          <CustomInputCmp
                            value={values.email}
                            onChange={handleChange}
                            name="email"
                            placeholder="Enter email address"
                            message={`${
                              errors["email"] ? errors["email"] : ""
                            }`}
                          />
                        </InputWrapper>
                        <ButtonWrapper>
                          <SolidButton
                            text={
                              loading ? (
                                <>
                                  <SpinnerCmp
                                    enabled={true}
                                    color={"#000"}
                                    size={20}
                                    secondaryColor={"#fff"}
                                  />
                                </>
                              ) : (
                                "Join waitlist"
                              )
                            }
                            type="submit"
                            weighty="700"
                            textFontSize="16px"
                            specifyPadding="1rem 1rem"
                            color="#fff"
                            borderRadius="11px"
                            backColor="#01525F"
                            backgroundHoverColor="#01525F"
                            textHoverColor="#fff"
                            widthWebkit="100%"
                            widthMoz="100%"
                            widthNormal="100%"
                            margin="0"
                            disabled={loading ? true : false}
                          />
                          <div
                            style={{
                              color: "red",
                              fontWeight: "300",
                              fontSize: "13px",
                              padding: "0",
                              paddingTop: "0.5rem",
                              margin: "0",
                            }}
                          ></div>
                        </ButtonWrapper>
                        <ButtonWrapperTablet>
                          <SolidButton
                            text={
                              loading ? (
                                <>
                                  <SpinnerCmp
                                    enabled={true}
                                    color={"#000"}
                                    size={20}
                                    secondaryColor={"#fff"}
                                  />
                                </>
                              ) : (
                                <>
                                  <PaperPlaneOutline color="#fff" size={20} />
                                </>
                              )
                            }
                            type="submit"
                            weighty="700"
                            textFontSize="16px"
                            specifyPadding="1rem 1rem"
                            color="#fff"
                            borderRadius="11px"
                            backColor="#01525F"
                            backgroundHoverColor="#01525F"
                            textHoverColor="#fff"
                            widthWebkit="60px"
                            widthMoz="60px"
                            widthNormal="60px"
                            margin="0"
                            disabled={loading ? true : false}
                          />
                          <div
                            style={{
                              color: "red",
                              fontWeight: "300",
                              fontSize: "13px",
                              padding: "0",
                              paddingTop: "0.5rem",
                              margin: "0",
                            }}
                          ></div>
                        </ButtonWrapperTablet>
                      </EmailWrapper>
                    </form>
                  </>
                )}
              </Formik> */}

              <StoreWrapper>
                <StoreImg
                  src={GooglePlayImage}
                  alt="Google Play Store"
                  effect="blur"
                  onClick={() => {
                    window.open(
                      "https://play.google.com/store/apps/details?id=com.vendar",
                      "_blank"
                    );
                  }}
                />
                <StoreImg
                  src={AppleStoreImage}
                  alt="Apple Store"
                  effect="blur"
                  onClick={() => {
                    window.open(
                      "https://apps.apple.com/us/app/vendar/id6449153439",
                      "_blank"
                    );
                  }}
                />
              </StoreWrapper>
            </LeftSection>
            <Fade direction="left" triggerOnce>
              <RightImg
                src={VendarMobile}
                alt={"Vendar Mobile"}
                effect="blur"
              />
            </Fade>
          </ContentWrapper>
        </Wrapper>
      </ContainerDiv>
      <MobileDownloadWrapper>
        <MobileLeftDownload>
          <MobileIcon src={FaviconImg} alt="Vendar" />
          <TextWrapper>
            <VendarText>Vendar</VendarText>
            <ReactStars
              count={5}
              value={5}
              size={12}
              activeColor="#FFC83E"
              edit={false}
            />
          </TextWrapper>
        </MobileLeftDownload>
        <MobileRightDownload>
          <SolidButton
            text={"Download App"}
            type="submit"
            weighty="700"
            textFontSize="12px"
            specifyPadding="0.8rem 0.5rem"
            color="#fff"
            borderRadius="6px"
            backColor="#01525F"
            backgroundHoverColor="#01525F"
            textHoverColor="#fff"
            widthWebkit="110px"
            widthMoz="110px"
            widthNormal="110px"
          />
        </MobileRightDownload>
      </MobileDownloadWrapper>
      <SuccessModal
        title="You will be the first to know when we launch"
        titleMaxWidth="300px"
        maxWidth="400px"
        wrapperPadding={"0rem 1rem 2rem"}
        showCloseIcon={true}
        isOpen={showSuccessModal}
        heading="Congratulations"
        onRequestClose={() => {
          setShowSuccessModal(true);
        }}
      />
    </>
  );
};

const mapState = (state) => ({
  loading: state.newsletter.subscribeLoading,
});

const mapDispatch = (dispatch) => ({
  setSubscribe: (data) => dispatch(subscribe(data)),
});

export default connect(mapState, mapDispatch)(HeroCmp);
