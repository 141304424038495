import React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import CardWithIcon from "../CardsCmp/CardWithIcon";
import PiggySvg from "../../assets/piggy.svg";
import PlaneSvg from "../../assets/plane.svg";
import EyeSvg from "../../assets/eyeglass.svg";
import BulbSvg from "../../assets/bulb.svg";
import { Fade } from "react-awesome-reveal";
import UnderlineTextCmp from "../UnderlineTextCmp/UnderlineTextCmp";

const ContainerDiv = styled(Container)`
  padding: 4rem 2rem;
  background-color: #fff;

  @media only screen and (max-width: 500px) {
    padding: 3rem 1rem;
  }
`;

const Wrapper = styled.div`
  max-width: calc(1100px + 3rem);
  width: 100%;
  margin: 0 auto;
  text-align: center;
`;

const WhyTitle = styled.h2`
  color: #01525f;
  font-size: 18px;
  font-weight: 600;

  @media only screen and (max-width: 500px) {
    font-size: 16px;
  }
`;

const BigTitle = styled.h2`
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
  font-weight: 500;
  font-size: 35px;
  color: #1d1e2c;
  line-height: 45px;

  @media only screen and (max-width: 500px) {
    font-size: 30px;
    line-height: 40px;
  }
`;

const Desc = styled.p`
  max-width: 340px;
  width: 100%;
  margin: 0 auto;
  font-weight: 400;
  font-size: 14px;
  color: #1d1e2c;
  opacity: 0.7;
  padding-top: 0.5rem;

  @media only screen and (max-width: 500px) {
    max-width: 300px;
  }
`;

const CardContainer = styled.div`
  max-width: 1150px;
  display: flex;
  gap: 20px;
  margin: 0 auto;
  justify-content: space-evenly;
  padding: 3.5rem 0;
  flex-wrap: wrap;
`;

const WhyVendarCmp = () => {
  return (
    <>
      <ContainerDiv fluid>
        <Wrapper>
          <WhyTitle>Why Vendar?</WhyTitle>
          <Fade cascade durattion="200" direction="up" triggerOnce>
            <BigTitle>
              Grow your business{" "}
              <span style={{ fontWeight: "800" }}>
                without <UnderlineTextCmp>stress</UnderlineTextCmp>
              </span>
            </BigTitle>
            <Desc>
              Experience an integrated e-commerce platform that makes sales,
              marketing and growth easier.
            </Desc>
          </Fade>
          <Fade cascade damping={0.1} triggerOnce direction="up">
            <CardContainer>
              {[
                {
                  icon: `${PiggySvg}`,
                  title: "Super easy",
                  desc: "An easy-to-use mobile app that lets you focus on your sales, instead of complicated systems.",
                },
                {
                  icon: `${PlaneSvg}`,
                  title: "Fast turnaround time",
                  desc: "You don’t have to dream about your money for weeks, get your money at the speed of light.",
                },
                {
                  icon: `${BulbSvg}`,
                  title: "One post, multiple channels",
                  desc: "Multiple posts where? Post your products across major social media platforms at once.",
                },
                {
                  icon: `${EyeSvg}`,
                  title: "In-app courier service",
                  desc: "Vendar provides a highly effective delivery network that guarantees prompt delivery of goods.",
                },
              ].map((v, i) => {
                return (
                  <CardWithIcon
                    key={i}
                    icon={v.icon}
                    title={v.title}
                    desc={v.desc}
                  />
                );
              })}
            </CardContainer>
          </Fade>
        </Wrapper>
      </ContainerDiv>
    </>
  );
};

export default WhyVendarCmp;
