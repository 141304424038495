import newsletterReducer from "./newsletterReducer";
import shopReducer from "./shopReducer";
import orderReducer from "./orderReducer";
import marketingReducer from "./marketingReducer";

const rootReducer = {
  newsletter: newsletterReducer,
  shop: shopReducer,
  order: orderReducer,
  marketing: marketingReducer,
};

export default rootReducer;
