import React from "react";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import ModalCmp from "../ModalCmp/ModalCmp";
import SolidButton from "../Buttons/SolidButton";

const Wrapper = styled.div`
  padding: 1rem;
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const OrderDetailsText = styled.h2`
  font-weight: 500;
  font-size: 16px;
  color: #1d1e2c;
`;

const Line = styled.hr`
  width: 100%;
  height: 1px;
  color: #dfe6e9;
  border-top: 1px solid #dfe6e9;
`;

const InnerWrapperLeft = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  cursor: pointer;

  @media only screen and (max-width: 600px) {
    flex-basis: 100%;
    width: 100%;
  }
`;

const SmallProductImg = styled.img`
  width: 60px;
  aspect-ratio: 1;
`;

const ProductDescWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ProductTitle = styled.h2`
  font-weight: 700;
  font-size: 16px;
  color: #2d3436;
  margin: 0;

  @media only screen and (max-width: 1024px) {
    font-size: 15px;
  }
`;

const AmountWrapper = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
`;

const Quantity = styled.p`
  margin: 0;
  font-weight: 600;
  font-size: 14px;
  color: #1d1e2c;
`;

const BtnWrapper = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  justify-content: center;
  padding-top: 2rem;
`;

const OrderDetailsModal = ({ ...props }) => {
  return (
    <>
      <ModalCmp
        isOpen={props.isOpen}
        onRequestClose={props.onRequestClose}
        contentStyles={{ maxWidth: props.maxWidth || "350px" }}
        shouldFocusAfterRender={false}
        contentLabel="Order Details"
        id="order-details"
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={false}
        showCloseIcon={props.showCloseIcon}
      >
        <Wrapper>
          <TopWrapper>
            <OrderDetailsText>Order details</OrderDetailsText>
          </TopWrapper>
          <Line />
          <Swiper
            modules={[Autoplay]}
            slidesPerView={1}
            autoplay={{
              delay: 5000,
            }}
            speed={1500}
            style={{ width: "100%" }}
            loop={false}
          >
            {props?.ordersArr &&
              props?.ordersArr?.length &&
              props?.ordersArr?.map((v, i) => {
                return (
                  <SwiperSlide key={i}>
                    <InnerWrapperLeft>
                      <SmallProductImg
                        src={v?.productId?.images[0]?.url}
                        alt={""}
                      />
                      <ProductDescWrapper>
                        <ProductTitle>{v.productId.productName}</ProductTitle>
                        <AmountWrapper>
                          <Quantity>Quantity: {v.quantity}</Quantity>
                        </AmountWrapper>
                      </ProductDescWrapper>
                    </InnerWrapperLeft>
                  </SwiperSlide>
                );
              })}
          </Swiper>
          <BtnWrapper>
            <SolidButton
              text={"Close"}
              type="submit"
              weighty="500"
              onClick={() => {
                props.onRequestClose();
              }}
              textFontSize="14px"
              specifyPadding="0.8rem 1.5rem"
              color="#fff"
              widthWebkit="100%"
              widthMoz="100%"
              widthNormal="100%"
              borderRadius="7px"
              backColor="#01525F"
              backgroundHoverColor="#01525F"
              textHoverColor="#fff"
              margin="0rem auto 0"
            />
          </BtnWrapper>
        </Wrapper>
      </ModalCmp>
    </>
  );
};

export default OrderDetailsModal;
