import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import CategoryCardCmp from "../components/CategoryCardCmp/CategoryCardCmp";
import ShopNavbarCmp from "../components/ShopNavbarCmp/ShopNavbarCmp";
import PaginationCmp from "../components/PaginationCmp/PaginationCmp";
import ShopFooterCmp from "../components/FooterCmp/ShopFooterCmp";
import { LazyLoadImage } from "react-lazy-load-image-component";
import {
  getAllCategories,
  getNewArrivals,
  getAllPublishedAds,
  increaseAdCount,
} from "../redux/actions/shopAction";
import { connect } from "react-redux";
import SpinnerCmp from "../components/SpinnerCmp/SpinnerCmp";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade } from "swiper";
import DeliveryDetailsDrawer from "../components/DeliveryDetailsDrawer/DeliveryDetailsDrawer";
import "swiper/css";
import "swiper/css/effect-fade";
import categoryIcons from "../utils/categoryIcons";
import { ShoppingBag } from "@styled-icons/fa-solid";

const PageWrapper = styled(Container)`
  background-color: #f8f9fb !important;
  padding: 0;
`;

const ProductDisplayWrapper = styled.div`
  /* background-color: #fff; */
  padding: 2rem 0rem;
  width: 100%;
  max-width: calc(1100px + 5rem);
  margin: 1rem auto 5rem;

  @media only screen and (max-width: 1247px) {
    padding: 2rem 1.5rem;
  }

  @media only screen and (max-width: 760px) {
    margin: 1rem auto 4rem;
    max-width: calc(1000px + 1rem);
  }

  @media only screen and (max-width: 547px) {
    margin: 1rem auto 3rem;
    padding: 0rem 1rem;
    background-color: transparent;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 2rem;
  /* css added cos of the ads section */
  padding-top: 1rem;
`;

const Title = styled.h2`
  font-weight: 800;
  font-size: 32px;
  color: #1d1e2c;
  padding-bottom: 10px;
  position: relative;
  display: inline-block;

  &::after {
    content: "";
    height: 1px;
    width: 20%;
    background: #01525f;
    position: absolute;
    left: calc(50% - 10%);
    bottom: 0;
  }

  @media only screen and (max-width: 800px) {
    font-size: 24px;
  }
`;

const AdWrapper = styled.div`
  width: 100%;
  text-align: center;
  padding-bottom: 1rem;
  cursor: pointer;
`;

const AdImage = styled(LazyLoadImage)`
  height: 100%;
  max-height: 90px;
  width: 100%;
`;

const CategoryItemsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 25px;

  @media only screen and (max-width: 531px) {
    justify-content: space-between;
  }
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 16px;
`;

const Commission = ({
  productCategoriesLoading,
  productCategoriesSuccess,
  setGetAllCategories,
  setIncreaseAdCount,
  adsSuccess,
}) => {
  const [pageNo, setPageNo] = useState(1);
  const navigate = useNavigate();
  const [colorsArr] = useState([
    "#FFE8EB",
    "#CCE1EE",
    "#FFE7F7",
    "#FFEDEA",
    "#EAFFF8",
    "#DFE0F9",
    "#B6D3D8",
    "#F9EABD",
  ]);
  const [openDeliveryDrawer, setOpenDeliveryDrawer] = useState(false);
  const [currentItemToAddToCart, setCurrentItemToAddToCart] = useState({});
  const [triggerWhenProductAdded, setTriggerWhenProductAdded] = useState(false);

  useEffect(() => {
    const getAllCategories = async () => {
      await setGetAllCategories(pageNo, 20);
    };
    getAllCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNo]);

  return (
    <>
      <PageWrapper fluid>
        <ShopNavbarCmp logoType="Vendar" />
        <ProductDisplayWrapper>
          {adsSuccess && adsSuccess?.data?.length > 0 && (
            <Swiper
              watchOverflow={true}
              modules={[Autoplay, EffectFade]}
              slidesPerView={1}
              autoplay={{
                delay: 5000,
              }}
              fadeEffect={{ crossFade: true }}
              effect={"fade"}
              speed={1500}
              loop={true}
            >
              {adsSuccess?.data?.map((v) => {
                return (
                  <SwiperSlide>
                    <AdWrapper
                      onClick={async () => {
                        const response = await setIncreaseAdCount(v?._id);

                        if (response?.message === "Successful") {
                          window.open(v.link, "_blank");
                        }
                      }}
                    >
                      <AdImage
                        wrapperClassName="ads-image"
                        alt={v.link}
                        effect="blur"
                        src={v.adPicUrl}
                      />
                    </AdWrapper>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          )}
          <TitleWrapper>
            <Title>Browse Categories & Commission</Title>
          </TitleWrapper>

          {productCategoriesLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                padding: "1rem 0",
              }}
            >
              <SpinnerCmp
                enabled={true}
                color={"#fff"}
                size={40}
                secondaryColor={"#01525F"}
              />
            </div>
          ) : (
            <CategoryItemsContainer>
              <>
                {productCategoriesSuccess?.data?.productCategories?.map(
                  (v, i) => {
                    return (
                      <CategoryCardCmp
                        key={i}
                        name={v.name}
                        icon={
                          categoryIcons[v.slug] || (
                            <ShoppingBag
                              size={50}
                              color="rgba(29, 30, 44, 0.2)"
                            />
                          )
                        }
                        iconBgColor={colorsArr[i % colorsArr.length]}
                        onClick={() => {
                          navigate(`/categories/${v.slug}`);
                        }}
                        commission={v?.percentageCommission}
                      />
                    );
                  }
                )}
              </>
            </CategoryItemsContainer>
          )}
          {productCategoriesSuccess && (
            <PaginationWrapper>
              <PaginationCmp
                totalSize={productCategoriesSuccess?.data?.count}
                limit={20}
                pageClick={(pageNumber, pageSize) => {
                  setPageNo(pageNumber);
                }}
              />
            </PaginationWrapper>
          )}
        </ProductDisplayWrapper>
        <ShopFooterCmp />
      </PageWrapper>
      <DeliveryDetailsDrawer
        isOpen={openDeliveryDrawer}
        onClose={() => {
          setTriggerWhenProductAdded(!triggerWhenProductAdded);
          setOpenDeliveryDrawer(false);
        }}
        currentItemToAddToCart={currentItemToAddToCart}
        setCurrentItemToAddToCart={setCurrentItemToAddToCart}
      />
    </>
  );
};

const mapState = (state) => ({
  productCategoriesLoading: state.shop.getCategoriesLoading,
  productCategoriesError: state.shop.getCategoriesError,
  productCategoriesSuccess: state.shop.getCategoriesSuccess,
  newArrivalsLoading: state.shop.getNewArrivalsLoading,
  newArrivalsError: state.shop.getNewArrivalsError,
  newArrivalsSuccess: state.shop.getNewArrivalsSuccess,
  adsLoading: state.shop.getAdsLoading,
  adsSuccess: state.shop.getAdsSuccess,
  adsError: state.shop.getAdsError,
});

const mapDispatch = (dispatch) => ({
  setGetAllCategories: (page, limit) => dispatch(getAllCategories(page, limit)),
  setGetNewArrivals: () => dispatch(getNewArrivals()),
  setGetAllPublishedAds: () => dispatch(getAllPublishedAds()),
  setIncreaseAdCount: (adId) => dispatch(increaseAdCount(adId)),
});

export default connect(mapState, mapDispatch)(Commission);
