import React from "react";
import styled from "styled-components";
import ModalCmp from "../ModalCmp/ModalCmp";
import SolidButton from "../Buttons/SolidButton";

const Wrapper = styled.div`
  padding: ${(props) =>
    props.wrapperPadding ? props.wrapperPadding : "2rem 1rem"};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
`;

const Title = styled.h2`
  font-weight: 800;
  font-size: 18px;
  color: #2d3436;
  margin: 0;
`;

const DescText = styled.p`
  font-weight: 500;
  font-size: 16px;
  color: #2d3436;
  max-width: 280px;
  margin: 0;
  text-align: center;
`;

const BtnWrapper = styled.div`
  display: flex;
  gap: 10px;
  max-width: 300px;
  width: 100%;
  justify-content: center;
`;

const RemoveModal = (props) => {
  return (
    <ModalCmp
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose}
      contentStyles={{ maxWidth: props.maxWidth || "350px" }}
      shouldFocusAfterRender={false}
      contentLabel="Success"
      id="success"
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={false}
      showCloseIcon={props.showCloseIcon}
    >
      <Wrapper wrapperPadding={props.wrapperPadding}>
        <Container>
          <TextWrapper>
            <Title>Remove from cart</Title>
            <DescText>
              Do you really want to remove this item from cart?
            </DescText>
          </TextWrapper>
          <BtnWrapper>
            <SolidButton
              text="Cancel"
              type="submit"
              weighty="500"
              onClick={() => {
                props.onRequestClose();
              }}
              textFontSize="14px"
              specifyPadding="0.8rem 1.5rem"
              color="#1D1E2C"
              widthWebkit="100%"
              widthMoz="100%"
              widthNormal="100%"
              borderRadius="7px"
              backColor="rgba(141, 184, 191, 0.2)"
              backgroundHoverColor="rgba(141, 184, 191, 0.2)"
              textHoverColor="#1D1E2C"
              margin="0rem auto 0"
            />
            <SolidButton
              text="Remove"
              type="submit"
              weighty="500"
              onClick={() => {
                props.removeFn();
              }}
              textFontSize="14px"
              specifyPadding="0.8rem 1.5rem"
              color="#fff"
              widthWebkit="100%"
              widthMoz="100%"
              widthNormal="100%"
              borderRadius="7px"
              backColor="#01525F"
              backgroundHoverColor="#01525F"
              textHoverColor="#fff"
              margin="0rem auto 0"
            />
          </BtnWrapper>
        </Container>
      </Wrapper>
    </ModalCmp>
  );
};

export default RemoveModal;
