import {
  A_PRODUCT_IN_A_SHOP_ERROR,
  A_PRODUCT_IN_A_SHOP_LOADING,
  A_PRODUCT_IN_A_SHOP_SUCCESS,
  CALCULATE_DELIVERY_FEE_ERROR,
  CALCULATE_DELIVERY_FEE_LOADING,
  CALCULATE_DELIVERY_FEE_SUCCESS,
  CREATE_ORDER_ERROR,
  CREATE_ORDER_LOADING,
  CREATE_ORDER_SUCCESS,
  GET_ADS_ERROR,
  GET_ADS_LOADING,
  GET_ADS_SUCCESS,
  GET_CATEGORIES_ERROR,
  GET_CATEGORIES_LOADING,
  GET_CATEGORIES_SUCCESS,
  GET_NEW_ARRIVALS_ERROR,
  GET_NEW_ARRIVALS_LOADING,
  GET_NEW_ARRIVALS_SUCCESS,
  INCREASE_AD_COUNT_ERROR,
  INCREASE_AD_COUNT_LOADING,
  INCREASE_AD_COUNT_SUCCESS,
  PRODUCTS_IN_A_CATEGORY_ERROR,
  PRODUCTS_IN_A_CATEGORY_LOADING,
  PRODUCTS_IN_A_CATEGORY_SUCCESS,
  PRODUCTS_IN_A_SHOP_ERROR,
  PRODUCTS_IN_A_SHOP_LOADING,
  PRODUCTS_IN_A_SHOP_SUCCESS,
  VERIFY_COUPON_CODE_ERROR,
  VERIFY_COUPON_CODE_LOADING,
  VERIFY_COUPON_CODE_SUCCESS,
  VERIFY_EMAIL_ERROR,
  VERIFY_EMAIL_LOADING,
  VERIFY_EMAIL_SUCCESS,
} from "./types";

const initialState = {
  productsInAShopLoading: null,
  productsInAShopSuccess: null,
  productsInAShopError: null,
  aProductInAShopLoading: null,
  aProductInAShopSuccess: null,
  aProductInAShopError: null,
  productsInACategoryLoading: null,
  productsInACategorySuccess: null,
  productsInACategoryError: null,
  verifyCouponCodeLoading: null,
  verifyCouponCodeSuccess: null,
  verifyCouponCodeError: null,
  calculateDeliveryFeeLoading: null,
  calculateDeliveryFeeSuccess: null,
  calculateDeliveryFeeError: null,
  getNewArrivalsLoading: null,
  getNewArrivalsSuccess: null,
  getNewArrivalsError: null,
  createOrderLoading: null,
  createOrderSuccess: null,
  createOrderError: null,
  getAdsLoading: null,
  getAdsSuccess: null,
  getAdsError: null,
  verifyEmailLoading: null,
  verifyEmailSuccess: null,
  verifyEmailError: null,
};

export default function shopReducer(state = initialState, action) {
  switch (action.type) {
    case PRODUCTS_IN_A_SHOP_LOADING:
      return { ...state, productsInAShopLoading: action.payload };
    case PRODUCTS_IN_A_SHOP_SUCCESS:
      return { ...state, productsInAShopSuccess: action.payload };
    case PRODUCTS_IN_A_SHOP_ERROR:
      return { ...state, productsInAShopError: action.payload };
    case A_PRODUCT_IN_A_SHOP_LOADING:
      return { ...state, aProductInAShopLoading: action.payload };
    case A_PRODUCT_IN_A_SHOP_SUCCESS:
      return { ...state, aProductInAShopSuccess: action.payload };
    case A_PRODUCT_IN_A_SHOP_ERROR:
      return { ...state, aProductInAShopError: action.payload };
    case PRODUCTS_IN_A_CATEGORY_LOADING:
      return { ...state, productsInACategoryLoading: action.payload };
    case PRODUCTS_IN_A_CATEGORY_SUCCESS:
      return { ...state, productsInACategorySuccess: action.payload };
    case PRODUCTS_IN_A_CATEGORY_ERROR:
      return { ...state, productsInACategoryError: action.payload };
    case VERIFY_COUPON_CODE_LOADING:
      return { ...state, verifyCouponCodeLoading: action.payload };
    case VERIFY_COUPON_CODE_SUCCESS:
      return { ...state, verifyCouponCodeSuccess: action.payload };
    case VERIFY_COUPON_CODE_ERROR:
      return { ...state, verifyCouponCodeError: action.payload };
    case CALCULATE_DELIVERY_FEE_LOADING:
      return { ...state, calculateDeliveryFeeLoading: action.payload };
    case CALCULATE_DELIVERY_FEE_SUCCESS:
      return { ...state, calculateDeliveryFeeSuccess: action.payload };
    case CALCULATE_DELIVERY_FEE_ERROR:
      return { ...state, calculateDeliveryFeeError: action.payload };
    case GET_CATEGORIES_LOADING:
      return { ...state, getCategoriesLoading: action.payload };
    case GET_CATEGORIES_SUCCESS:
      return { ...state, getCategoriesSuccess: action.payload };
    case GET_CATEGORIES_ERROR:
      return { ...state, getCategoriesError: action.payload };
    case GET_NEW_ARRIVALS_LOADING:
      return { ...state, getNewArrivalsLoading: action.payload };
    case GET_NEW_ARRIVALS_SUCCESS:
      return { ...state, getNewArrivalsSuccess: action.payload };
    case GET_NEW_ARRIVALS_ERROR:
      return { ...state, getNewArrivalsError: action.payload };
    case CREATE_ORDER_LOADING:
      return { ...state, createOrderLoading: action.payload };
    case CREATE_ORDER_SUCCESS:
      return { ...state, createOrderSuccess: action.payload };
    case CREATE_ORDER_ERROR:
      return { ...state, createOrderError: action.payload };
    case GET_ADS_LOADING:
      return { ...state, getAdsLoading: action.payload };
    case GET_ADS_SUCCESS:
      return { ...state, getAdsSuccess: action.payload };
    case GET_ADS_ERROR:
      return { ...state, getAdsError: action.payload };
    case INCREASE_AD_COUNT_LOADING:
      return { ...state, increaseAdCountLoading: action.payload };
    case INCREASE_AD_COUNT_SUCCESS:
      return { ...state, increaseAdCountSuccess: action.payload };
    case INCREASE_AD_COUNT_ERROR:
      return { ...state, increaseAdCountError: action.payload };
    case VERIFY_EMAIL_LOADING:
      return { ...state, verifyEmailLoading: action.payload };
    case VERIFY_EMAIL_SUCCESS:
      return { ...state, verifyEmailSuccess: action.payload };
    case VERIFY_EMAIL_ERROR:
      return { ...state, verifyEmailError: action.payload };
    default:
      return state;
  }
}
