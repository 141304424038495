import { CloseOutline } from "@styled-icons/evaicons-outline";
import Dropdown from "rc-dropdown";
import React from "react";
import { useState } from "react";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import styled from "styled-components";
import LogoImg from "../../assets/logo.svg";
import NigeriaSvg from "../../assets/nigeria.svg";
import DropdownCmp from "../DropdownCmp/DropdownCmp";

const MenuWrapper = styled.div`
  padding: 2rem 1rem;
`;

const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Logo = styled.img``;

const ButtonFlagHolder = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

const FlagImage = styled.img``;

const CloseIcon = styled(CloseOutline)``;

const GroupLink1 = styled.div`
  display: flex;
  gap: 32px;
  flex-direction: column;
  padding-top: 2rem;
`;

const MenuLink = styled.p`
  margin: 0;
  font-weight: 700;
  font-size: 16px;
  color: #1d1e2c;
  cursor: pointer;
`;

const SideDrawerMobile = ({ isOpen, onClose }) => {
  const [, setSellVisible] = useState();
  const [, setMarketVisible] = useState();
  const [, setManageVisible] = useState();
  const [, setLearnVisible] = useState();
  return (
    <>
      <Drawer
        open={isOpen}
        onClose={onClose}
        direction="left"
        size={"320px"}
        zIndex={1001}
      >
        <MenuWrapper>
          <TopSection>
            <Logo src={LogoImg} alt="Vendar" />
            <ButtonFlagHolder>
              <FlagImage src={NigeriaSvg} alt="Nigeria" />
              <CloseIcon color="#1D1E2C" size={"20px"} onClick={onClose} />
            </ButtonFlagHolder>
          </TopSection>
          <GroupLink1>
            <Dropdown
              trigger={["click"]}
              overlay={
                <DropdownCmp
                  title="Sell anywhere"
                  desc="Sell around the world on a website, social media,  and anywhere else that strikes your fancy."
                  leftArr={[
                    { text: "Website", url: "/website" },
                    { text: "Google", url: "/google" },
                    { text: "Twitter", url: "/twitter" },
                  ]}
                  rightArr={[
                    { text: "Instagram", url: "/instagram" },
                    { text: "TikTok", url: "/tiktok" },
                    { text: "Facebook", url: "/facebook" },
                  ]}
                />
              }
              animation="slide-up"
              overlayStyle={{
                fontFamily: '"Mulish", sans-serif',
                zIndex: "2000",
                width: "fit-content",
                top: "75px",
                border: "1px solid #1D1E2C",
                borderRadius: "10px",
              }}
              onVisibleChange={(visible) => {
                setSellVisible(visible);
              }}
            >
              <MenuLink>Sell</MenuLink>
            </Dropdown>
            <Dropdown
              trigger={["click"]}
              overlay={
                <DropdownCmp
                  title="Market your business"
                  desc="Marketing your business has never been easier. Google advertising, Facebook advertising, and more."
                  leftArr={[
                    { text: "Google Ads", url: "/website" },
                    { text: "Facebook Ads", url: "/google" },
                    { text: "Marketing apps", url: "/twitter" },
                  ]}
                />
              }
              animation="slide-up"
              overlayStyle={{
                fontFamily: '"Mulish", sans-serif',
                zIndex: "2000",
                width: "fit-content",
                top: "75px",
                border: "1px solid #1D1E2C",
                borderRadius: "10px",
              }}
              onVisibleChange={(visible) => {
                setMarketVisible(visible);
              }}
            >
              <MenuLink>Market</MenuLink>
            </Dropdown>
            <Dropdown
              trigger={["click"]}
              overlay={
                <DropdownCmp
                  title="Manage your store"
                  desc="Simplicity at its finest."
                  leftArr={[
                    { text: "Google Ads", url: "/website" },
                    { text: "Facebook Ads", url: "/google" },
                    { text: "Marketing apps", url: "/twitter" },
                  ]}
                />
              }
              animation="slide-up"
              overlayStyle={{
                fontFamily: '"Mulish", sans-serif',
                zIndex: "2000",
                width: "fit-content",
                top: "75px",
                border: "1px solid #1D1E2C",
                borderRadius: "10px",
              }}
              onVisibleChange={(visible) => {
                setManageVisible(visible);
              }}
            >
              <MenuLink>Manage</MenuLink>
            </Dropdown>
            <Dropdown
              trigger={["click"]}
              overlay={
                <DropdownCmp
                  title="Learn"
                  desc="Marketing your business has never been easier. Google advertising, Facebook advertising, and more."
                  leftArr={[
                    { text: "Customer support", url: "/website" },
                    { text: "Help Center", url: "/google" },
                    { text: "Blog", url: "/twitter" },
                  ]}
                />
              }
              animation="slide-up"
              overlayStyle={{
                fontFamily: '"Mulish", sans-serif',
                zIndex: "2000",
                width: "fit-content",
                top: "75px",
                border: "1px solid #1D1E2C",
                borderRadius: "10px",
              }}
              onVisibleChange={(visible) => {
                setLearnVisible(visible);
              }}
            >
              <MenuLink>Learn</MenuLink>
            </Dropdown>
            <MenuLink>Pricing</MenuLink>
            <MenuLink
              onClick={() => {
                window.open("https://blog.vendar.co", "_blank");
              }}
            >
              Blog
            </MenuLink>
          </GroupLink1>
        </MenuWrapper>
      </Drawer>
    </>
  );
};

export default SideDrawerMobile;
