import React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import FooterCmp from "../components/FooterCmp/FooterCmp";
import NavbarCmp from "../components/NavbarCmp/NavbarCmp";
import PageHeadingCmp from "../components/PageHeadingCmp/PageHeadingCmp";

const RefundPolicy = () => {
  const ContainerDiv = styled(Container)`
    background-color: #f8f9fb;
    padding: 5rem 1rem 5rem;

    @media only screen and (max-width: 500px) {
      padding: 3rem 1rem 3rem;
    }
  `;

  const WhiteWrapper = styled.div`
    background: #ffffff;
    border-radius: 10px;
    padding: 1.5rem 1rem;
    max-width: calc(1100px + 3rem);
    width: 100%;
    margin: 0 auto;
    text-align: left;
  `;

  const Title = styled.h2`
    font-weight: 800;
    font-size: 24px;
    color: #01525f;
    margin: 0;
    margin-bottom: 10px;

    @media only screen and (max-width: 700px) {
      font-size: 20px;
    }
  `;

  const Text = styled.p`
    font-weight: 400;
    font-size: 15px;
    line-height: 26px;
    color: #1d1e2c;

    @media only screen and (max-width: 600px) {
      font-size: 14px;
    }
  `;

  const Section = styled.div`
    margin-bottom: 1.2rem;
  `;

  return (
    <>
      <NavbarCmp />
      <PageHeadingCmp
        margin="-80px 0 0 0"
        title="Returns & Refund Policy"
        date="Last updated December 2023"
      />
      <ContainerDiv fluid>
        <WhiteWrapper>
          <Section>
            <Title>Vendar Returns & Refund Policy</Title>
          </Section>
          <Section>
            <Title>1. Introduction</Title>
            <Text>
              At Vendar, our commitment is to ensure your satisfaction with
              every purchase. This Returns and Refunds Policy is designed to
              provide a comprehensive understanding of the return process,
              including specific guidelines related to payment methods, product
              inspection, and dispute resolution.
            </Text>
          </Section>{" "}
          <Section>
            <Title>
              2. Return Period and Conditions for Acceptance of Returns
            </Title>
            <Text>
              We want to guarantee your contentment with the items purchased on
              Vendar. Please be informed that we do not support cash-on-delivery
              (COD) transactions. Buyers can initiate returns within 4 hours
              post-delivery, adhering to the conditions specified below:
            </Text>
            <Text>
              <i>Reason for Return | Applicable Product Category</i>
              <ul>
                <li>
                  Size is correct but doesn't fit as expected | Clothing and
                  shoes only
                </li>
                <li>
                  Item stopped working well after usage | All product categories
                  (excluding clothing, sport & fitness, and consumables)
                </li>
                <li>
                  Item received broken or defective | All product categories
                </li>
                <li>Packaging was damaged | All product categories</li>
                <li>
                  Item received with missing parts or accessories | All product
                  categories
                </li>
                <li>
                  Item received used or expired | All product categories
                  (excluding software)
                </li>
                <li>
                  Item seems to be fake/unauthentic | All product categories
                </li>
                <li>Wrong item/color/size/model | All product categories</li>
              </ul>
            </Text>
          </Section>{" "}
          <Section>
            <Title>3. No Cash-on-Delivery (COD) Transactions</Title>
            <Text>
              We do not facilitate cash-on-delivery transactions. All orders
              must be prepaid through the available payment methods on our
              platform.
            </Text>
          </Section>{" "}
          <Section>
            <Title>4. Product Inspection and Dispute Resolution</Title>
            <Text>
              To ensure a smooth process, we urge buyers to inspect their
              products immediately upon receipt from the courier. Any
              discrepancies, damages, or defects should be reported within 4
              hours of receiving the order. This timely reporting is crucial for
              initiating a dispute resolution process.
            </Text>
            <Text>Upon identifying an issue, follow these steps:</Text>
            <ul>
              <li>
                <b>Document the Issue:</b> Take clear pictures of the product
                highlighting the problem and keep the original packaging.
              </li>
              <li>
                <b>Contact Customer Service:</b> Reach out to our Customer
                Service team via [
                <a href="mailto:support@vendar.co">support@vendar.co</a>] and
                provide detailed information along with the documented evidence.
              </li>
              <li>
                <b>Initiate Dispute:</b> We encourage buyers to initiate a
                dispute promptly. Failure to report within the stipulated 4-hour
                window may impact the eligibility for dispute resolution.
              </li>
            </ul>
          </Section>{" "}
          <Section>
            <Title>5. Items That Cannot Be Returned</Title>
            <Text>
              Certain product categories cannot be returned due to health and
              hygiene reasons or if they may deteriorate or expire rapidly.
              Returns are only accepted if you received the wrong item, a
              damaged or defective item, or a fake or inauthentic item.
              Customized items, configured, or inscribed to your specification
              cannot be returned unless damaged or defective upon arrival.
            </Text>
          </Section>{" "}
          <Section>
            <Title>6. Packaging Returns</Title>
            <Text>
              When returning an item, ensure it is in the exact condition
              received, including original packaging, tags, and labels. You are
              responsible for the item until it reaches us, so package it
              securely. Do not include any additional items unrelated to the
              return, and delete any personal data associated with the item.
            </Text>
          </Section>{" "}
          <Section>
            <Title>7. Refunds</Title>
            <Text>
              Upon accepting your return, we aim to refund the purchase price
              within the specified timeframe. For incorrect, defective, or
              damaged items, the full cost of delivery will also be refunded. If
              the return is due to reasons not attributable to Vendar or the
              seller, no refund on delivery fees will be provided.
            </Text>
          </Section>{" "}
          <Section>
            <Title>8. Rejected Return and Refund Requests and Forfeiture</Title>
            <Text>
              All returned items are inspected to verify the provided return
              reasons. If your return request is not approved, no refund will be
              issued. Two redelivery attempts will be made within 3 business
              days. If both attempts fail, and you do not collect the item
              within 30 days from the initial notification, the item will be
              forfeited.
            </Text>
          </Section>{" "}
          <Section>
            <Title>9. No Exchange</Title>
            <Text>We do not offer exchanges.</Text>
            <Text>
              If you have any questions or concerns regarding this Returns and
              Refunds Policy, please contact our Customer Service at [
              <a href="mailto:support@vendar.co">support@vendar.co</a>]. Thank
              you for choosing Vendar.
            </Text>
          </Section>
        </WhiteWrapper>
      </ContainerDiv>
      <FooterCmp />
    </>
  );
};

export default RefundPolicy;
