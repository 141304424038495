import { Formik } from "formik";
import React, { useEffect } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import styled from "styled-components";
import LogoImg from "../assets/logo.svg";
import SolidButton from "../components/Buttons/SolidButton";
import CustomInputCmp from "../components/InputsCmp/CustomInputCmp";
import { connect, useDispatch } from "react-redux";
import {
  verifyDeliveryAccessCode,
  verifyTrackingId,
} from "../redux/actions/orderAction";
import { useNavigate, useParams } from "react-router-dom";
import EmptyCmp from "../components/EmptyCmp/EmptyCmp";
import SpinnerCmp from "../components/SpinnerCmp/SpinnerCmp";
import verifyCodeValidatorSchema from "../validators/verifyCodeValidator";
import { toast } from "react-toastify";
import OrderDetailsModal from "../components/Modals/OrderDetailsModal";
import { VERIFY_DELIVERY_CODE_SUCCESS } from "../redux/reducers/types";

const Container = styled.div`
  max-width: 375px;
  width: 100%;
  min-height: 70vh;
  height: 100%;
  padding: 1rem;
  margin: 3rem auto;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: inherit;
`;

const Logo = styled(LazyLoadImage)`
  cursor: pointer;
  padding: 0 0 10px;
`;

const Welcome = styled.h2`
  font-weight: 700;
  font-size: 20px;
  color: #1d1e2c;
  padding: 10px 0;
  margin: 0;
  text-align: center;

  @media only screen and (max-width: 375px) {
    font-size: 18px;
  }
`;

const Desc = styled.p`
  font-weight: 400;
  font-size: 16px;
  color: #526366;
  margin: 0;
  text-align: center;

  @media only screen and (max-width: 375px) {
    font-size: 14px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
`;

const InputWrapper = styled.div`
  width: 100%;
`;

const InputLabel = styled.h2`
  font-weight: 500;
  font-size: 14px;
  color: #6c6c74;
`;

const Verification = ({
  getVerifyTrackingIdError,
  getVerifyTrackingIdLoading,
  getVerifyTrackingIdSuccess,
  setVerifyTrackingId,
  setVerifyDeliveryAccessCode,
  getVerifyDeliveryCodeSuccess,
  getVerifyDeliveryCodeLoading,
}) => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!params) {
      navigate("/");
    }
    async function getTrackingIdDetails() {
      await setVerifyTrackingId(params?.trackingId);
    }
    getTrackingIdDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.trackingId]);
  return (
    <>
      <Container>
        <Wrapper>
          <Logo src={LogoImg} alt="Vendar" />
          {getVerifyTrackingIdLoading ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <SpinnerCmp
                enabled={true}
                color={"#fff"}
                size={40}
                secondaryColor={"#01525F"}
              />
            </div>
          ) : getVerifyTrackingIdError ? (
            <EmptyCmp firstText={"Invalid tracking ID"} />
          ) : (
            <>
              <Welcome>Verify Code</Welcome>
              <Desc>Please enter a verification accessCode of an order</Desc>
              <Formik
                validationSchema={verifyCodeValidatorSchema}
                enableReinitialize={false}
                isInitialValid={true}
                initialValues={{ accessCode: "" }}
                onSubmit={async (values, { resetForm }) => {
                  const response = await setVerifyDeliveryAccessCode({
                    accessCode: values?.accessCode,
                    trackingId: params?.trackingId,
                  });

                  if (
                    response &&
                    response.status === 200 &&
                    response.message === "Order retrieved successfully"
                  ) {
                    toast.success(`Order retrieved successfully`);
                    resetForm();
                    navigate("/");
                  }
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  setFieldValue,
                  values,
                  touched,
                  isValid,
                  errors,
                }) => (
                  <>
                    <form
                      style={{
                        width: "100%",
                        paddingTop: "1rem",
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                      noValidate
                      onSubmit={handleSubmit}
                    >
                      <InputWrapper>
                        <InputLabel>Code</InputLabel>
                        <CustomInputCmp
                          value={values.accessCode}
                          onChange={handleChange}
                          name="accessCode"
                          placeholder="Enter Delivery Access Code"
                          message={`${
                            errors["accessCode"] ? errors["accessCode"] : ""
                          }`}
                        />
                      </InputWrapper>
                      <ButtonWrapper>
                        <SolidButton
                          text={
                            getVerifyDeliveryCodeLoading ? (
                              <>
                                <SpinnerCmp
                                  enabled={true}
                                  color={"000"}
                                  size={20}
                                  secondaryColor={"#fff"}
                                />
                              </>
                            ) : (
                              "Submit"
                            )
                          }
                          type="submit"
                          weighty="700"
                          textFontSize="16px"
                          specifyPadding="1rem 1rem"
                          color="#fff"
                          borderRadius="11px"
                          backColor="#01525F"
                          backgroundHoverColor="#01525F"
                          textHoverColor="#fff"
                          widthWebkit="100%"
                          widthMoz="100%"
                          widthNormal="100%"
                          margin="0"
                          disabled={getVerifyDeliveryCodeLoading ? true : false}
                        />
                      </ButtonWrapper>
                    </form>
                  </>
                )}
              </Formik>
            </>
          )}
        </Wrapper>
        <OrderDetailsModal
          maxWidth="500px"
          showCloseIcon={false}
          isOpen={getVerifyDeliveryCodeSuccess ? true : false}
          onRequestClose={() => {
            dispatch({
              type: VERIFY_DELIVERY_CODE_SUCCESS,
              payload: null,
            });
          }}
          ordersArr={getVerifyDeliveryCodeSuccess?.data?.products}
        />
      </Container>
    </>
  );
};

const mapState = (state) => ({
  getVerifyTrackingIdLoading: state.order.verifyTrackingIdLoading,
  getVerifyTrackingIdError: state.order.verifyTrackingIdError,
  getVerifyTrackingIdSuccess: state.order.verifyTrackingIdSuccess,
  getVerifyDeliveryCodeSuccess: state.order.verifyDeliveryCodeSuccess,
  getVerifyDeliveryCodeLoading: state.order.verifyDeliveryCodeLoading,
});

const mapDispatch = (dispatch) => ({
  setVerifyTrackingId: (data) => dispatch(verifyTrackingId(data)),
  setVerifyDeliveryAccessCode: (data) =>
    dispatch(verifyDeliveryAccessCode(data)),
});

export default connect(mapState, mapDispatch)(Verification);
