import { Cart } from "@styled-icons/evil";
import React, { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import SolidButton from "../Buttons/SolidButton";
import LogoImg from "../../assets/logo.svg";
import Avatar from "react-avatar";
import { slugToTitle } from "../../utils/slugToTitle";

const NavbarContainer = styled.div`
  background: ${(props) =>
    props.navBarState || props.type
      ? "rgba(245, 246, 248, 0.8)"
      : "transparent"};
  backdrop-filter: blur(3px);
  /* height: 80px; */
  /* margin-top: -80px; */
  display: flex;
  align-items: center;
  padding: 1rem 3rem;

  /* position: relative; */
  position: sticky;
  top: 0;
  z-index: 10;
  transition: 0.8s all ease;

  @media only screen and (max-width: 760px) {
    padding: 1rem;
    flex-direction: column;
    gap: 20px;
  }
`;

const NavbarWrapper = styled.div`
  width: 100%;
  max-width: calc(1100px + 5rem);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;

  @media only screen and (max-width: 500px) {
    max-width: calc(1000px + 1rem);
  }
`;

const ShopName = styled.h2`
  color: #01525f;
  font-weight: 700;
  font-size: 20px;
  margin: 0;
  cursor: pointer;
`;

const ButtonWrapperDesktop = styled.div`
  /* @media only screen and (max-width: 760px) {
    display: none;
  } */
`;

// const ButtonWrapperMobile = styled.div`
//   display: none;

//   @media only screen and (max-width: 760px) {
//     display: unset;
//   }
// `;

// const InputWrapper = styled.div`
//   position: relative;
// `;

// const FormWrapper = styled.div``;

// const ButtonWrapper = styled.div`
//   position: absolute;
//   top: 6px;
//   right: 10px;
//   cursor: pointer;
// `;

// const SearchFormWrapperDesktop = styled.div`
//   max-width: 350px;
//   width: 100%;

//   @media only screen and (max-width: 760px) {
//     display: none;
//   }
// `;

// const SearchFormWrapperMobile = styled.div`
//   display: none;

//   @media only screen and (max-width: 760px) {
//     display: unset;
//     width: 100%;
//   }
// `;

const Logo = styled(LazyLoadImage)`
  cursor: pointer;
`;

const ShopNavbarCmp = ({ logoType, shopSlug }) => {
  const [navbarStateScroll, setNavbarStateScroll] = useState(false);
  const navigate = useNavigate();

  const changeBackgroundColor = () => {
    if (window.scrollY >= 10) {
      setNavbarStateScroll(true);
    } else {
      setNavbarStateScroll(false);
    }
  };

  window.addEventListener("scroll", changeBackgroundColor);

  // const SearchFormCmp = () => {
  //   return (
  //     <>
  //       <Formik
  //         validationSchema={searchValidatorSchema}
  //         initialValues={{
  //           query: "",
  //         }}
  //         onSubmit={async (values, { resetForm }) => {
  //           console.log("clicked");
  //         }}
  //       >
  //         {({
  //           handleSubmit,
  //           handleChange,
  //           handleBlur,
  //           setFieldValue,
  //           values,
  //           touched,
  //           isValid,
  //           errors,
  //         }) => (
  //           <>
  //             <form
  //               noValidate
  //               //   onSubmit={handleSubmit}
  //             >
  //               <FormWrapper>
  //                 <InputWrapper>
  //                   <CustomInputCmp
  //                     value={values.query}
  //                     onChange={handleChange}
  //                     name="query"
  //                     placeholder="Search"
  //                     message={`${errors["query"] ? errors["query"] : ""}`}
  //                     padding="0.5rem 1.8rem 0.5rem 1rem"
  //                   />
  //                   <ButtonWrapper onClick={handleSubmit}>
  //                     <Search size={20} color="#A6AFC2" />
  //                   </ButtonWrapper>
  //                 </InputWrapper>
  //               </FormWrapper>
  //             </form>
  //           </>
  //         )}
  //       </Formik>
  //     </>
  //   );
  // };

  return (
    <>
      <NavbarContainer navBarState={navbarStateScroll}>
        <NavbarWrapper>
          {logoType === "Vendar" ? (
            <Logo
              onClick={() => {
                navigate("/");
              }}
              src={LogoImg}
              alt="Vendar"
            />
          ) : (
            <ShopName
              onClick={() => {
                navigate(`/shop/${shopSlug}`);
              }}
            >
              <Avatar
                textSizeRatio={3}
                maxInitials={2}
                title={slugToTitle(shopSlug)}
                name={slugToTitle(shopSlug)}
                round={true}
                size={50}
                color="#01525F"
              />
            </ShopName>
          )}
          {/* <SearchFormWrapperDesktop>
            <SearchFormCmp />
          </SearchFormWrapperDesktop> */}
          <ButtonWrapperDesktop>
            <SolidButton
              onClick={() => {
                navigate("/cart");
              }}
              text={
                <>
                  <div
                    style={{
                      display: "flex",
                      gap: "5px",
                      alignItems: "center",
                    }}
                  >
                    <Cart color="#fff" size={25} />
                    <span>My cart</span>
                    <span
                      style={{
                        width: "27px",
                        height: "27px",
                        borderRadius: "3px",
                        backgroundColor: "#fff",
                        color: "#1D1E2C",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {JSON.parse(localStorage.getItem("orders"))?.length || 0}
                    </span>
                  </div>
                </>
              }
              type="submit"
              weighty="800"
              textFontSize="15px"
              specifyPadding="0.5rem 1rem"
              color="#fff"
              borderRadius="10px"
              backColor="#01525F"
              backgroundHoverColor="#01525F"
              textHoverColor="#fff"
              widthWebkit="150px"
              widthMoz="150px"
              widthNormal="150px"
            />
          </ButtonWrapperDesktop>
          {/* <ButtonWrapperMobile>
            <ShoppingCartOutline color="#1D1E2C" size={30} />
          </ButtonWrapperMobile> */}
        </NavbarWrapper>
        {/* <SearchFormWrapperMobile>
          <SearchFormCmp />
        </SearchFormWrapperMobile> */}
      </NavbarContainer>
    </>
  );
};

export default ShopNavbarCmp;
