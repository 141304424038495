exports.calculateTotalPriceAndQuantity = (products) => {
  let totalPrice = 0;
  let totalQuantity = 0;
  for (let i = 0; i < products.length; i++) {
    totalPrice += products[i].totalPrice;
    totalQuantity += products[i].quantity;
  }
  return {
    totalPrice,
    totalQuantity,
  };
};

exports.capitalizeFirstLetter = (sentence) => {
  // Capitalize the first letter of the sentence
  const capitalizedSentence =
    sentence?.charAt(0)?.toUpperCase() + sentence?.slice(1);

  // Return the modified sentence
  return capitalizedSentence;
};

exports.areAllVendorIdsSame = (products) => {
  // Get the vendorId of the first product in the array.
  const firstVendorId = products[0].userId;

  // Use the `every` method to check if all vendorIds are the same as the first one.
  return products.every((product) => product.userId === firstVendorId);
};
