import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import ShopFooterCmp from "../components/FooterCmp/ShopFooterCmp";
import ItemCardCmp from "../components/ItemCardCmp/ItemCardCmp";
import ShopNavbarCmp from "../components/ShopNavbarCmp/ShopNavbarCmp";
import NumberFormat from "react-number-format";
import PaginationCmp from "../components/PaginationCmp/PaginationCmp";
import ScrollToTop from "react-scroll-to-top";
import { ArrowIosUpwardOutline } from "@styled-icons/evaicons-outline";
import { useParams, useNavigate } from "react-router-dom";
import {
  getAllProductsInAShop,
  getAllPublishedAds,
  increaseAdCount,
} from "../redux/actions/shopAction";
import { connect } from "react-redux";
import EmptyCmp from "../components/EmptyCmp/EmptyCmp";
import SpinnerCmp from "../components/SpinnerCmp/SpinnerCmp";
import DeliveryDetailsDrawer from "../components/DeliveryDetailsDrawer/DeliveryDetailsDrawer";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade } from "swiper";
import "swiper/css";
import "swiper/css/effect-fade";
import SeoCmp from "../components/SEOCmp/SeoCmp";
import { slugToTitle } from "../utils/slugToTitle";

const PageWrapper = styled(Container)`
  background-color: #f8f9fb !important;
  padding: 0;
`;

const ProductDisplayWrapper = styled.div`
  /* background-color: #fff; */
  padding: 2rem 0rem;
  width: 100%;
  max-width: calc(1100px + 5rem);
  margin: 1rem auto 5rem;

  @media only screen and (max-width: 1247px) {
    padding: 2rem 1.5rem;
  }

  @media only screen and (max-width: 760px) {
    margin: 1rem auto 4rem;
    max-width: calc(1000px + 1rem);
  }

  @media only screen and (max-width: 547px) {
    margin: 1rem auto 3rem;
    padding: 0rem 1rem;
    background-color: transparent;
  }
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 16px;
`;

const ProductItemsConatiner = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 25px;

  @media only screen and (max-width: 547px) {
    position: relative;
    width: 100vw;
    left: calc(-50vw + 50%);
  }
`;

const AdWrapper = styled.div`
  width: 100%;
  text-align: center;
  padding-bottom: 1rem;
  cursor: pointer;
`;

const AdImage = styled(LazyLoadImage)`
  height: 100%;
  max-height: 90px;
  width: 100%;
`;

const ScrollTopWrapper = styled.div`
  display: none;

  @media only screen and (max-width: 547px) {
    display: unset;
  }
`;

const UserShop = ({
  setGetAllProductsInAShop,
  setGetAllPublishedAds,
  setIncreaseAdCount,
  itemsError,
  itemsLoading,
  itemsSuccess,
  adsLoading,
  adsSuccess,
  adsError,
}) => {
  const params = useParams();
  const navigate = useNavigate();
  const [pageNo, setPageNo] = useState(1);
  const [openDeliveryDrawer, setOpenDeliveryDrawer] = useState(false);
  const [currentItemToAddToCart, setCurrentItemToAddToCart] = useState({});
  const [triggerWhenProductAdded, setTriggerWhenProductAdded] = useState(false);

  useEffect(() => {
    const getAllProducts = async () => {
      await setGetAllProductsInAShop(params?.slug, pageNo, 20);
      await setGetAllPublishedAds();
    };
    getAllProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNo, params?.slug]);

  return (
    <>
      {adsSuccess && (
        <SeoCmp
          title={`${slugToTitle(params?.slug)}`}
          description={`Shop your goods at ${slugToTitle(params?.slug)}`}
          url={window.location.href}
          name={slugToTitle(params?.slug)}
        />
      )}
      <PageWrapper fluid>
        <ShopNavbarCmp shopSlug={itemsSuccess?.data?.storeDetails?.slug} />
        <ProductDisplayWrapper>
          {itemsLoading ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <SpinnerCmp
                enabled={true}
                color={"#fff"}
                size={40}
                secondaryColor={"#01525F"}
              />
            </div>
          ) : itemsError ? (
            <EmptyCmp firstText="Shop does not exist" />
          ) : !itemsSuccess?.data?.products.length ? (
            <EmptyCmp firstText="No product item in this shop" />
          ) : (
            <>
              {adsSuccess && adsSuccess?.data?.length > 0 && (
                <Swiper
                  watchOverflow={true}
                  modules={[Autoplay, EffectFade]}
                  slidesPerView={1}
                  autoplay={{
                    delay: 5000,
                  }}
                  fadeEffect={{ crossFade: true }}
                  effect={"fade"}
                  speed={1500}
                  loop={true}
                >
                  {adsSuccess?.data?.map((v) => {
                    return (
                      <SwiperSlide>
                        <AdWrapper
                          onClick={async () => {
                            const response = await setIncreaseAdCount(v?._id);

                            if (response?.message === "Successful") {
                              window.open(v.link, "_blank");
                            }
                          }}
                        >
                          <AdImage
                            wrapperClassName="ads-image"
                            alt={v.link}
                            effect="blur"
                            src={v.adPicUrl}
                          />
                        </AdWrapper>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              )}

              <ProductItemsConatiner>
                {itemsSuccess?.data?.products?.map((v, i) => {
                  return (
                    <ItemCardCmp
                      quantity={v.stockQty}
                      imageUrl={v.images[0]?.url}
                      productName={v.productName}
                      description={v.productDescription}
                      slug={v.slug}
                      price={
                        <>
                          <NumberFormat
                            thousandSeparator
                            prefix="₦"
                            value={v.price}
                            decimalScale={2}
                            defaultValue="0.00"
                            allowNegative={false}
                            placeholder="0.00"
                            displayType="text"
                            disabled
                          />
                        </>
                      }
                      id={v._id}
                      key={i}
                      addToCart={() => {
                        setTriggerWhenProductAdded(!triggerWhenProductAdded);
                        if (localStorage.getItem("detailsSaved")) {
                          const orders = JSON.parse(
                            localStorage.getItem("orders")
                          );
                          if (orders) {
                            localStorage.setItem(
                              "orders",
                              JSON.stringify([
                                ...orders,
                                {
                                  ...v,
                                  quantity: 1,
                                  totalPrice: parseFloat(v?.price),
                                  state:
                                    itemsSuccess?.data?.storeDetails?.state,
                                  storeName:
                                    itemsSuccess?.data?.storeDetails?.slug,
                                },
                              ])
                            );
                          } else {
                            localStorage.setItem(
                              "orders",
                              JSON.stringify([
                                {
                                  ...v,
                                  quantity: 1,
                                  totalPrice: parseFloat(v?.price),
                                  state:
                                    itemsSuccess?.data?.storeDetails?.state,
                                  storeName:
                                    itemsSuccess?.data?.storeDetails?.slug,
                                },
                              ])
                            );
                          }
                        } else {
                          localStorage.setItem(
                            "storeDetails",
                            JSON.stringify(itemsSuccess?.data?.storeDetails)
                          );
                          setCurrentItemToAddToCart({
                            ...v,
                            quantity: 1,
                            totalPrice: parseFloat(v?.price),
                            state: itemsSuccess?.data?.storeDetails?.state,
                            storeName: itemsSuccess?.data?.storeDetails?.slug,
                          });
                          setOpenDeliveryDrawer(true);
                        }
                      }}
                      triggerWhenProductAdded={triggerWhenProductAdded}
                      onParentClick={() => {
                        navigate(`/p/${params?.slug}/${v.slug}`);
                      }}
                    />
                  );
                })}
              </ProductItemsConatiner>
              <PaginationWrapper>
                <PaginationCmp
                  totalSize={itemsSuccess?.data?.count}
                  limit={20}
                  pageClick={(pageNumber, pageSize) => {
                    setPageNo(pageNumber);
                  }}
                />
              </PaginationWrapper>
            </>
          )}
        </ProductDisplayWrapper>
        <ShopFooterCmp />
      </PageWrapper>
      <ScrollTopWrapper>
        <ScrollToTop
          smooth
          component={<ArrowIosUpwardOutline size={30} />}
          style={{
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#fff",
            backgroundColor: "#016170",
            boxShadow: "0px 8px 24px 2px rgba(1, 97, 112, 0.2)",
            bottom: "20px",
            right: "35px",
          }}
        />
      </ScrollTopWrapper>
      <DeliveryDetailsDrawer
        isOpen={openDeliveryDrawer}
        onClose={() => {
          setTriggerWhenProductAdded(!triggerWhenProductAdded);
          setOpenDeliveryDrawer(false);
        }}
        currentItemToAddToCart={currentItemToAddToCart}
        setCurrentItemToAddToCart={setCurrentItemToAddToCart}
      />
    </>
  );
};

const mapState = (state) => ({
  itemsLoading: state.shop.productsInAShopLoading,
  itemsError: state.shop.productsInAShopError,
  itemsSuccess: state.shop.productsInAShopSuccess,
  adsLoading: state.shop.getAdsLoading,
  adsSuccess: state.shop.getAdsSuccess,
  adsError: state.shop.getAdsError,
});

const mapDispatch = (dispatch) => ({
  setGetAllProductsInAShop: (name, page, limit) =>
    dispatch(getAllProductsInAShop(name, page, limit)),
  setGetAllPublishedAds: () => dispatch(getAllPublishedAds()),
  setIncreaseAdCount: (adId) => dispatch(increaseAdCount(adId)),
});

export default connect(mapState, mapDispatch)(UserShop);
