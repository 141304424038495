import React from "react";
import styled from "styled-components";
import { Form } from "react-bootstrap";
import CheckCustomImage from "../../assets/check-icon.svg";

const Wrapper = styled.div`
  display: flex;
  border-radius: 8px;
  /* border: 1px solid #adb5bd; */
  border: ${(props) =>
    props.isInvalid ? "1px solid red" : "1px solid #ADB5BD"};
  justify-content: space-between;
  padding: 1rem;
  align-items: flex-start;
  cursor: pointer;
  /* height: ${(props) => (props.height ? `${props.height}` : "50px")}; */
  gap: ${(props) => (props.gap ? `${props.gap}` : "")};

  @media only screen and (max-width: 380px) {
    margin: 0 1rem;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 90%;
  text-align: left;
`;

const Title = styled.h2`
  font-weight: 400;
  font-size: 16px;
  color: #1d1e2c;
`;

const Desc = styled.p`
  font-weight: 400;
  font-size: 14px;
  color: #6d6d6d;
  margin: 0;
`;

const FormCheck = styled(Form.Check)``;

const CheckInput = styled(Form.Check.Input)`
  width: 20px;
  height: 20px;

  &:focus {
    box-shadow: none;
  }

  &:checked {
    background-color: #01525f;
    border-color: #01525f;
    background-image: url(${CheckCustomImage}) !important;
    background-size: auto;
  }
`;

const CustomCheckboxCmp = ({
  title,
  desc,
  handleChange,
  id,
  isInvalid,
  checked,
  gap,
  height,
}) => {
  return (
    <>
      <Wrapper
        onClick={handleChange}
        isInvalid={isInvalid}
        gap={gap}
        height={height}
      >
        <TextWrapper>
          <Title>{title}</Title>
          <Desc>{desc}</Desc>
        </TextWrapper>
        <FormCheck type="radio" id={id} isInvalid={isInvalid}>
          <CheckInput
            onChange={handleChange}
            id={id}
            isInvalid={isInvalid}
            checked={checked}
            type="radio"
          />
        </FormCheck>
      </Wrapper>
    </>
  );
};

export default CustomCheckboxCmp;
