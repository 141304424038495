import {
  ACCEPT_RETURN_ORDER_ERROR,
  ACCEPT_RETURN_ORDER_LOADING,
  ACCEPT_RETURN_ORDER_SUCCESS,
  FILL_ACCEPT_RETURN_FORM_ERROR,
  FILL_ACCEPT_RETURN_FORM_LOADING,
  FILL_ACCEPT_RETURN_FORM_SUCCESS,
  GET_REVIEW_ORDER_ERROR,
  GET_REVIEW_ORDER_LOADING,
  GET_REVIEW_ORDER_SUCCESS,
  RATE_ORDER_ERROR,
  RATE_ORDER_LOADING,
  RATE_ORDER_SUCCESS,
  VERIFY_DELIVERY_CODE_ERROR,
  VERIFY_DELIVERY_CODE_LOADING,
  VERIFY_DELIVERY_CODE_SUCCESS,
  VERIFY_TRACKING_ID_ERROR,
  VERIFY_TRACKING_ID_LOADING,
  VERIFY_TRACKING_ID_SUCCESS,
} from "./types";

const initialState = {
  getReviewOrderLoading: null,
  getReviewOrderSuccess: null,
  getReviewOrderError: null,
  acceptReturnOrderLoading: null,
  acceptReturnOrderSuccess: null,
  acceptReturnOrderError: null,
  rateOrderLoading: null,
  rateOrderSuccess: null,
  rateOrderError: null,
  fillAcceptReturnFormLoading: null,
  fillAcceptReturnFormSuccess: null,
  fillAcceptReturnFormError: null,
};

export default function orderReducer(state = initialState, action) {
  switch (action.type) {
    case GET_REVIEW_ORDER_LOADING:
      return { ...state, getReviewOrderLoading: action.payload };
    case GET_REVIEW_ORDER_SUCCESS:
      return { ...state, getReviewOrderSuccess: action.payload };
    case GET_REVIEW_ORDER_ERROR:
      return { ...state, getReviewOrderError: action.payload };
    case ACCEPT_RETURN_ORDER_LOADING:
      return { ...state, acceptReturnOrderLoading: action.payload };
    case ACCEPT_RETURN_ORDER_SUCCESS:
      return { ...state, acceptReturnOrderSuccess: action.payload };
    case ACCEPT_RETURN_ORDER_ERROR:
      return { ...state, acceptReturnOrderError: action.payload };
    case RATE_ORDER_LOADING:
      return { ...state, rateOrderLoading: action.payload };
    case RATE_ORDER_SUCCESS:
      return { ...state, rateOrderSuccess: action.payload };
    case RATE_ORDER_ERROR:
      return { ...state, rateOrderError: action.payload };
    case FILL_ACCEPT_RETURN_FORM_LOADING:
      return { ...state, fillAcceptReturnFormLoading: action.payload };
    case FILL_ACCEPT_RETURN_FORM_SUCCESS:
      return { ...state, fillAcceptReturnFormSuccess: action.payload };
    case FILL_ACCEPT_RETURN_FORM_ERROR:
      return { ...state, fillAcceptReturnFormError: action.payload };
    case VERIFY_TRACKING_ID_LOADING:
      return { ...state, verifyTrackingIdLoading: action.payload };
    case VERIFY_TRACKING_ID_SUCCESS:
      return { ...state, verifyTrackingIdSuccess: action.payload };
    case VERIFY_TRACKING_ID_ERROR:
      return { ...state, verifyTrackingIdError: action.payload };
    case VERIFY_DELIVERY_CODE_LOADING:
      return { ...state, verifyDeliveryCodeLoading: action.payload };
    case VERIFY_DELIVERY_CODE_SUCCESS:
      return { ...state, verifyDeliveryCodeSuccess: action.payload };
    case VERIFY_DELIVERY_CODE_ERROR:
      return { ...state, verifyDeliveryCodeError: action.payload };
    default:
      return state;
  }
}
