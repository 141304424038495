import React from "react";
import styled from "styled-components";
import LineSvg from "../../assets/line.svg";

const Text = styled.span`
  position: relative;

  &::after {
    content: "";
    position: absolute;
    bottom: ${(props) => (props.bottom ? `${props.bottom}` : "-0.125rem")};
    left: -0.5rem;
    right: -0.5rem;
    height: ${(props) => (props.height ? `${props.height}` : "0.75rem")};
    // Position the line behind the text so that
    // it is still easily readable
    z-index: ${(props) => (props.zIndex ? `${props.zIndex}` : -1)};

    // The SVG is added as an SVG background image
    background-image: ${(props) => props.img && `url(${props.img})`};
    background-repeat: no-repeat;

    // This allows the SVG to flex in size to fit
    // any length of word. If the word is short,
    // the SVG will be stretched vertically, if it
    // is long, the SVG will be stretched horizontally.
    // The jagged nature of this particular SVG works
    // with this transforming.
    background-size: contain;
  }
`;

const UnderlineTextCmp = (props) => {
  return (
    <>
      <Text
        zIndex={props.zIndex}
        height={props.height}
        bottom={props.bottom}
        img={LineSvg}
      >
        {props.children}
      </Text>
    </>
  );
};

export default UnderlineTextCmp;
