import React, { useState, useEffect } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import styled from "styled-components";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";
import LabelCmp from "../LabelCmp/LabelCmp";
import { capitalizeSentences } from "../../utils/capitalizeFirstLetter";

const Wrapper = styled.div`
  /* border: 1px solid #d1d1d1; */
  padding: 10px;
  /* max-width: 250px; */
  width: ${(props) => (props.width ? props.width : "24%")};
  border-radius: 12.1385px;
  flex-basis: ${(props) => (props.width ? props.width : "24%")};
  background-color: #fff;
  cursor: pointer;

  @media only screen and (max-width: 995px) {
    flex-basis: ${(props) => (props.width ? props.width : "32%")};
    width: ${(props) => (props.width ? props.width : "32%")};
  }

  @media only screen and (max-width: 757px) {
    flex-basis: ${(props) => (props.width ? props.width : "49%")};
    width: ${(props) => (props.width ? props.width : "49%")};
  }

  @media only screen and (max-width: 547px) {
    flex-basis: 100%;
    width: 100%;
    border: none;
  }
`;

const ContentHolder = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media only screen and (max-width: 547px) {
    flex-direction: ${(props) => (props.isSliderItem ? "column" : "row")};
    align-items: ${(props) => (props.isSliderItem ? "unset" : "center")};
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
`;

const OutOfStockWrapper = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;

  @media only screen and (max-width: 547px) {
    display: none;
  }
`;

const Image = styled(LazyLoadImage)`
  width: 100%;
  /* width: 205px; */
  aspect-ratio: 1;
  border-radius: 6px;

  @media only screen and (max-width: 547px) {
    aspect-ratio: 1;
    width: ${(props) => (props.isSliderItem ? "205px" : "72px")};
  }
`;

const TextWrapper = styled.div`
  @media only screen and (max-width: 547px) {
    width: 100%;
  }
`;

const ProductName = styled.h2`
  font-weight: 700;
  font-size: 18px;
  color: #1d1e2c;
  margin: 0;
`;

const ProductDescription = styled.p`
  font-weight: 400;
  font-size: 12px;
  color: #7c7c84;
  margin: 0;
  padding-top: 6px;
`;

const PriceWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
`;

const Price = styled.h2`
  font-weight: 800;
  font-size: 16px;
  color: #1d1e2c;
  margin: 0;
`;

const AddCart = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  background: ${(props) => props.backColor || "#01525f"};
  color: ${(props) => props.color || "#fff"};
  font-weight: 700;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
`;

const ItemCardCmp = ({
  quantity,
  productName,
  imageUrl,
  id,
  description,
  price,
  removeCartBtn,
  width,
  isSliderItem,
  addToCart,
  triggerWhenProductAdded,
  onParentClick,
}) => {
  const [checkIfProductAdded, setCheckIfProductAdded] = useState(false);

  useEffect(() => {
    setCheckIfProductAdded(
      localStorage.getItem("orders") &&
        JSON.parse(localStorage.getItem("orders"))
          ?.map((v) => v._id)
          .includes(id)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerWhenProductAdded]);

  return (
    <>
      <Wrapper
        width={width}
        onClick={() => {
          if (onParentClick) {
            onParentClick();
          }
        }}
      >
        <ContentHolder isSliderItem={isSliderItem}>
          <ImageWrapper>
            <Image
              src={imageUrl}
              alt={capitalizeSentences(productName)}
              effect="blur"
              isSliderItem={isSliderItem}
            />
            {quantity === 0 && (
              <OutOfStockWrapper>
                <LabelCmp background={"red"} />
              </OutOfStockWrapper>
            )}
          </ImageWrapper>
          <TextWrapper>
            <ProductName id={`vendar-product-${id}`}>
              {productName.length > 22
                ? `${capitalizeSentences(productName).slice(0, 19)}...`
                : capitalizeSentences(productName)}
            </ProductName>
            {productName.length > 22 && (
              <ReactTooltip
                anchorId={`vendar-product-${id}`}
                place="bottom"
                content={capitalizeSentences(productName)}
                style={{ top: "10px", width: "200px" }}
              />
            )}
            {description && (
              <>
                <ProductDescription id={`product-description-${id}`}>
                  {description.length > 40
                    ? `${description.slice(0, 37)}...`
                    : description}
                </ProductDescription>
                {description.length > 40 && (
                  <ReactTooltip
                    anchorId={`product-description-${id}`}
                    place="bottom"
                    content={description}
                    style={{ top: "10px", width: "250px" }}
                  />
                )}
              </>
            )}

            <PriceWrapper>
              <Price>{price}</Price>
              {!removeCartBtn && (
                <AddCart
                  backColor={
                    quantity === 0
                      ? "rgba(1, 82, 95, 0.5)"
                      : checkIfProductAdded
                      ? "transparent"
                      : "#01525f"
                  }
                  color={
                    quantity === 0
                      ? "#fff"
                      : checkIfProductAdded
                      ? "#01525f"
                      : "#fff"
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    if (quantity === 0 || checkIfProductAdded) {
                      e.preventDefault();
                    } else {
                      addToCart();
                    }
                  }}
                >
                  {quantity === 0
                    ? "Out of stock"
                    : checkIfProductAdded
                    ? "Added to cart"
                    : "Add to cart"}
                </AddCart>
              )}
            </PriceWrapper>
          </TextWrapper>
        </ContentHolder>
      </Wrapper>
    </>
  );
};

export default ItemCardCmp;
