import React from "react";
import NumberFormat from "react-number-format";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import SolidButton from "../Buttons/SolidButton";
import ModalCmp from "../ModalCmp/ModalCmp";
import { createOrder } from "../../redux/actions/shopAction";
import { connect } from "react-redux";
import SpinnerCmp from "../SpinnerCmp/SpinnerCmp";
import { closePaymentModal, useFlutterwave } from "flutterwave-react-v3";
import { useState } from "react";
import { useEffect } from "react";

const Wrapper = styled.div`
  padding: 1rem;
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const OrderDetailsText = styled.h2`
  font-weight: 500;
  font-size: 16px;
  color: #1d1e2c;
`;

const ButtonWrapper = styled.div``;

const Line = styled.hr`
  width: 100%;
  height: 1px;
  color: #dfe6e9;
  border-top: 1px solid #dfe6e9;
`;

const InnerWrapperLeft = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  cursor: pointer;

  @media only screen and (max-width: 600px) {
    flex-basis: 100%;
    width: 100%;
  }
`;

const SmallProductImg = styled.img`
  width: 60px;
  aspect-ratio: 1;
`;

const ProductDescWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ProductTitle = styled.h2`
  font-weight: 700;
  font-size: 16px;
  color: #2d3436;
  margin: 0;

  @media only screen and (max-width: 1024px) {
    font-size: 15px;
  }
`;

const AmountWrapper = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
`;

const Amount = styled.p`
  margin: 0;
  font-weight: 700;
  font-size: 14px;
  color: #7c7c84;
`;

const Quantity = styled.p`
  margin: 0;
  font-weight: 600;
  font-size: 14px;
  color: #1d1e2c;
`;

const DeliveryWrapper = styled.div`
  padding: 5px 0;
  display: flex;
  justify-content: space-between;
  align-items: ${(props) => (props.alignItems ? props.alignItems : "center")};
`;

const DeliveryChargesText = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: #636e72;
  margin: 0;
`;

const DeliveryChargesLeftText = styled.h2`
  font-weight: 700;
  font-size: 14px;
  color: ${(props) => (props.color ? props.color : "#7c7c84")};
  margin: 0;
  cursor: pointer;
`;

const TotalWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TotalText = styled.h2`
  font-weight: 700;
  font-size: 16px;
  color: #1d1e2c;
  margin: 0;
`;

const BtnWrapper = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  justify-content: center;
  padding-top: 1rem;
`;

const PayModal = ({
  setCreateOrder,
  createOrderError,
  createOrderLoading,
  createOrderSuccess,
  ...props
}) => {
  const [flwConfig, setFlwConfig] = useState({
    public_key:
      process.env.REACT_APP_NODE_ENV === "production"
        ? process.env.REACT_APP_FLUTTERWAVE_PUBLIC_KEY_LIVE
        : process.env.REACT_APP_FLUTTERWAVE_PUBLIC_KEY,
    customizations: {
      title: "Vendar",
    },
    currency: "NGN",
    payment_options: "card,mobilemoney,ussd",
  });

  const handlePayment = useFlutterwave(flwConfig);

  const [triggerPayment, setTriggerPayment] = useState(false);

  useEffect(() => {
    if (triggerPayment) {
      handlePayment({
        callback: async (response) => {
          const { status } = response;
          if (status === "completed" || status === "successful") {
            closePaymentModal();
            props.onRequestClose();
            setTriggerPayment(false);
            props.setShowPaySuccessModal(true);
            localStorage.setItem("couponDetails", JSON.stringify({}));
            localStorage.setItem("orders", JSON.stringify([]));
            localStorage.setItem("storeDetails", JSON.stringify({}));
          }
        },
        onClose: () => {},
      });
    }
  }, [handlePayment, props, triggerPayment]);
  return (
    <>
      <ModalCmp
        isOpen={props.isOpen}
        onRequestClose={props.onRequestClose}
        contentStyles={{ maxWidth: props.maxWidth || "350px" }}
        shouldFocusAfterRender={false}
        contentLabel="Pay"
        id="pay"
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
        showCloseIcon={props.showCloseIcon}
      >
        <Wrapper>
          <TopWrapper>
            <OrderDetailsText>Order details</OrderDetailsText>
            <ButtonWrapper>
              <SolidButton
                onClick={() => {
                  props.onRequestClose();
                  setTriggerPayment(false);
                }}
                text={"Modify Cart"}
                type="submit"
                weighty="600"
                textFontSize="14px"
                specifyPadding="0.5rem"
                color="#01525F"
                widthWebkit={"100px"}
                widthMoz={"100px"}
                widthNormal={"100px"}
                borderRadius="8px"
                backColor={"rgba(118, 170, 178, 0.1)"}
                backgroundHoverColor={"rgba(118, 170, 178, 0.1)"}
                textHoverColor="#01525F"
                margin="0rem auto 0"
              />
            </ButtonWrapper>
          </TopWrapper>
          <Line />
          <Swiper
            modules={[Autoplay]}
            slidesPerView={1}
            autoplay={{
              delay: 5000,
            }}
            speed={1500}
            style={{ width: "100%" }}
            loop={true}
          >
            {props?.ordersArr &&
              props?.ordersArr?.length &&
              props?.ordersArr?.map((v, i) => {
                return (
                  <SwiperSlide key={i}>
                    <InnerWrapperLeft>
                      <SmallProductImg src={v.images[0]?.url} alt={v.slug} />
                      <ProductDescWrapper>
                        <ProductTitle>{v.productName}</ProductTitle>
                        <AmountWrapper>
                          <Amount>
                            <NumberFormat
                              thousandSeparator
                              prefix="₦"
                              value={v.price}
                              decimalScale={2}
                              defaultValue="0.00"
                              allowNegative={false}
                              placeholder="0.00"
                              displayType="text"
                              disabled
                            />
                          </Amount>
                          <Quantity>Quantity: {v.quantity}</Quantity>
                        </AmountWrapper>
                      </ProductDescWrapper>
                    </InnerWrapperLeft>
                  </SwiperSlide>
                );
              })}
          </Swiper>
          <Line />
          <DeliveryWrapper>
            <DeliveryChargesText>Subtotal:</DeliveryChargesText>
            <DeliveryChargesLeftText>
              <NumberFormat
                thousandSeparator
                prefix="₦"
                value={props.subtotal}
                decimalScale={2}
                defaultValue="0.00"
                allowNegative={false}
                placeholder="0.00"
                displayType="text"
                disabled
              />
            </DeliveryChargesLeftText>
          </DeliveryWrapper>
          <DeliveryWrapper>
            <DeliveryChargesText>Delivery fee:</DeliveryChargesText>
            <DeliveryChargesLeftText>
              {props.deliveryFee}
            </DeliveryChargesLeftText>
          </DeliveryWrapper>
          {props?.couponDetails && props?.couponDetails?.couponCode && (
            <>
              <DeliveryWrapper>
                <DeliveryChargesText>Coupon:</DeliveryChargesText>
                <DeliveryChargesLeftText>
                  {props?.couponDetails?.couponType === "Percentage" ? (
                    `${props?.couponDetails?.amount}% Off`
                  ) : props?.couponDetails?.couponType === "Fixed" ||
                    props?.couponDetails?.couponType === "Fixed Shipping" ? (
                    <>
                      <NumberFormat
                        thousandSeparator
                        prefix="₦"
                        value={props?.couponDetails?.amount}
                        decimalScale={2}
                        defaultValue="0.00"
                        allowNegative={false}
                        placeholder="0.00"
                        displayType="text"
                        disabled
                      />{" "}
                      {"Off"}{" "}
                      {props.couponDetails?.couponType === "Fixed Shipping"
                        ? "on shipping"
                        : ""}
                    </>
                  ) : (
                    props?.couponDetails?.couponType
                  )}
                </DeliveryChargesLeftText>
              </DeliveryWrapper>
              <Line />
            </>
          )}
          <TotalWrapper>
            <TotalText>Total</TotalText>
            <TotalText>{props.total}</TotalText>
          </TotalWrapper>

          <BtnWrapper>
            <SolidButton
              text="Cancel"
              type="submit"
              weighty="500"
              onClick={() => {
                props.onRequestClose();
                setTriggerPayment(false);
              }}
              textFontSize="14px"
              specifyPadding="0.8rem 1.5rem"
              color="#1D1E2C"
              widthWebkit="100%"
              widthMoz="100%"
              widthNormal="100%"
              borderRadius="7px"
              backColor="rgba(141, 184, 191, 0.2)"
              backgroundHoverColor="rgba(141, 184, 191, 0.2)"
              textHoverColor="#1D1E2C"
              margin="0rem auto 0"
            />
            <SolidButton
              text={
                createOrderLoading ? (
                  <>
                    <SpinnerCmp
                      enabled={true}
                      color={"000"}
                      size={20}
                      secondaryColor={"#fff"}
                    />
                  </>
                ) : (
                  "Pay now"
                )
              }
              type="submit"
              weighty="500"
              onClick={async () => {
                const deliveryDetails = localStorage.getItem("deliveryDetails");
                const couponDetails = localStorage.getItem("couponDetails");

                let data = {
                  products: props?.ordersArr?.map((v) => {
                    return {
                      ...v,
                      totalPrice: `${v.totalPrice}`,
                      itemPrice: v.price,
                      productId: v._id,
                      vendorId: v.userId,
                    };
                  }),
                  customerDetail: deliveryDetails
                    ? {
                        ...JSON.parse(deliveryDetails),
                        phone: JSON.parse(deliveryDetails)?.fullPhoneNumber,
                        country: "Nigeria",
                      }
                    : {},
                  deliveryType: JSON.parse(deliveryDetails)?.deliveryType,
                  totalPriceOfProductPurchased: `${props.totalPriceAndQuantity.totalPrice}`,
                };

                if (couponDetails) {
                  data = {
                    ...data,
                    couponCodeUsed: JSON.parse(couponDetails)?.couponCode,
                  };
                }
                const response = await setCreateOrder(data);

                if (
                  response &&
                  response.message === "Order created successfully"
                ) {
                  setFlwConfig({
                    ...flwConfig,
                    amount: parseFloat(response?.data?.totalPriceAfterDiscount),
                    tx_ref: response?.data?.paymentResult?.txnRef,
                    customer: {
                      email: response?.data?.customerDetail?.email,
                      name: `${response?.data?.customerDetail?.firstName} ${response?.data?.customerDetail?.lastName}`,
                      phone_number: response?.data?.customerDetail?.phone,
                    },
                    meta: { _id: response?.data?._id },
                  });

                  setTriggerPayment(true);
                }
              }}
              textFontSize="14px"
              specifyPadding="0.8rem 1.5rem"
              color="#fff"
              widthWebkit="100%"
              widthMoz="100%"
              widthNormal="100%"
              borderRadius="7px"
              backColor="#01525F"
              backgroundHoverColor="#01525F"
              textHoverColor="#fff"
              margin="0rem auto 0"
              disabled={createOrderLoading ? true : false}
            />
          </BtnWrapper>
        </Wrapper>
      </ModalCmp>
    </>
  );
};

const mapState = (state) => ({
  createOrderLoading: state.shop.createOrderLoading,
  createOrderError: state.shop.createOrderError,
  createOrderSuccess: state.shop.createOrderSuccess,
});

const mapDispatch = (dispatch) => ({
  setCreateOrder: (data) => dispatch(createOrder(data)),
});

export default connect(mapState, mapDispatch)(PayModal);
