/* eslint-disable no-useless-escape */
import * as yup from "yup";
import "yup-phone";

const deliveryDetailsValidatorSchema = yup.object().shape({
  email: yup
    .string()
    .email("It must be a valid email")
    .required("Please enter your email address"),
  firstName: yup
    .string()
    .required("First name is required")
    .matches(/(?!.*[\.\-\_]{2,})^[a-zA-Z0-9\.\-\_]{3,24}$/, "Invalid name"),
  lastName: yup
    .string()
    .required("Last name is required")
    .matches(/(?!.*[\.\-\_]{2,})^[a-zA-Z0-9\.\-\_]{3,24}$/, "Invalid name"),
  state: yup.string().required("State is required"),
  city: yup.string().required("City is required"),
  address: yup.string().required("Address is required"),
  deliveryType: yup.string().required("Delivery type is required"),
  phone: yup
    .string()
    .phone(undefined, undefined, "Invalid phone number")
    .required(),
});

export default deliveryDetailsValidatorSchema;
