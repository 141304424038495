import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import styled from "styled-components";
import "./CustomPhoneInputCmp.css";

const ErrorMessageWrapper = styled.div`
  text-align: left;
`;

const ErrorMessage = styled.p`
  color: red;
  font-weight: 300;
  font-size: 13px;
  padding: 0;
  padding-top: 0.5rem;
  margin: 0;
`;

const CustomPhoneCmp = (props) => {
  return (
    <>
      <PhoneInput
        country={"ng"}
        onlyCountries={["ng"]}
        value={props.values ? props.values[props.name] : props.value}
        onChange={props.onChange || null}
        disabled={props.disabled}
        inputProps={{
          name: props.name,
          required: props.required ? true : false || false,
        }}
        inputStyle={{
          padding: props.padding ? props.padding : "0.8rem 14px 0.8rem 60px",
          border: props.message ? "1px solid red" : "1px solid #ADB5BD",
          background: props.background ? props.background : "#fff",
          borderRadius: "8px",
          color: "#374151",
          fontWeight: "400",
          fontSize: "15px",
          width: "100%",
        }}
        inputClass="vendar-phone-input"
        containerClass="vendar-phone-container"
        placeholder={props.placeholder}
      />

      {props.showMessage && (
        <ErrorMessageWrapper>
          <ErrorMessage>{props.showMessage ? props.message : ""}</ErrorMessage>
        </ErrorMessageWrapper>
      )}
    </>
  );
};

export default CustomPhoneCmp;
