import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import NumberFormat from "react-number-format";
import styled from "styled-components";
import ShopFooterCmp from "../components/FooterCmp/ShopFooterCmp";
import ShopNavbarCmp from "../components/ShopNavbarCmp/ShopNavbarCmp";
import NumericInputCmp from "../components/NumericInputCmp/NumericInputCmp";
import SolidButton from "../components/Buttons/SolidButton";
import { Plus } from "@styled-icons/bootstrap";
import { NavigateBefore, NavigateNext } from "@styled-icons/material-rounded";
import ReactImageGallery from "react-image-gallery";
import { Rating } from "react-simple-star-rating";
import {
  getAProductInAShop,
  getAllPublishedAds,
  increaseAdCount,
} from "../redux/actions/shopAction";
import { connect } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import SpinnerCmp from "../components/SpinnerCmp/SpinnerCmp";
import EmptyCmp from "../components/EmptyCmp/EmptyCmp";
import { capitalizeFirstLetter } from "../utils/cart";
import DeliveryDetailsDrawer from "../components/DeliveryDetailsDrawer/DeliveryDetailsDrawer";
import "react-image-gallery/styles/css/image-gallery.css";
import "./styles/gallery.css";
import LabelCmp from "../components/LabelCmp/LabelCmp";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade } from "swiper";
import "swiper/css";
import "swiper/css/effect-fade";
import { LazyLoadImage } from "react-lazy-load-image-component";
import SeoCmp from "../components/SEOCmp/SeoCmp";
import { slugToTitle } from "../utils/slugToTitle";

const PageWrapper = styled(Container)`
  background-color: #f8f9fb !important;
  padding: 0;
`;

const Wrapper = styled.div`
  /* background-color: #fff; */
  padding: 2rem 0rem;
  width: 100%;
  max-width: calc(1100px + 5rem);
  margin: 1rem auto 5rem;

  @media only screen and (max-width: 1247px) {
    padding: 2rem 1.5rem;
  }

  @media only screen and (max-width: 760px) {
    margin: 1rem auto 4rem;
    max-width: calc(1000px + 1rem);
  }

  @media only screen and (max-width: 547px) {
    margin: 1rem auto 3rem;
    padding: 0rem 1rem;
    background-color: transparent;
  }
`;

const MainProductWrapper = styled.div`
  background-color: #fff;
  padding: 2rem;
  width: 100%;
  border-radius: 7px;

  @media only screen and (max-width: 1247px) {
    padding: 2rem 1.5rem;
  }

  @media only screen and (max-width: 547px) {
    padding: 2rem 1rem;
  }
`;

const MainProductInnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 30px;
  align-items: center;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const MainProductInnerLeftWrapper = styled.div`
  flex-basis: 50%;
  width: 50%;

  @media only screen and (max-width: 768px) {
    flex-basis: 100%;
    width: 100%;
  }
`;

const ThumbnailImage = styled.img`
  width: 50px;
  aspect-ratio: 1;
  border-radius: 3.5px;
`;

const MainProductInnerRightWrapper = styled.div`
  flex-basis: 50%;
  width: 50%;

  @media only screen and (max-width: 768px) {
    flex-basis: 100%;
    width: 100%;
  }
`;

const ProductTitle = styled.h2`
  font-weight: 700;
  font-size: 23px;
  color: #2d3436;
  line-height: 36px;
  margin: 0;

  @media only screen and (max-width: 510px) {
    line-height: unset;
    font-size: 20px;
  }
`;

const ProductInfoWrapper = styled.div`
  padding: 5px 0;
  display: flex;
  gap: 10px;
  align-items: flex-end;

  @media only screen and (max-width: 975px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 7px;
  }
`;

const ProductInfoContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: flex-end;

  @media only screen and (max-width: 1024px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const RatingWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 7px;
`;

const RatingNumber = styled.p`
  font-weight: 700;
  font-size: 12px;
  color: #636e72;
  margin: 0;
  padding: 0;
`;

const ProductCodeWrapper = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: #7c7c84;
`;

const VendorWrapper = styled.div`
  font-weight: 700;
  font-size: 14px;
  color: #7c7c84;
`;

const ProductDescriptionWrapper = styled.div``;

const ProductDescriptionTitle = styled.h2`
  font-weight: 700;
  font-size: 18px;
  color: #1d1e2c;

  @media only screen and (max-width: 510px) {
    font-size: 16px;
  }
`;

const ProductDescriptionText = styled.p`
  font-weight: 400;
  font-size: 15px;
  color: #7c7c84;
`;

const QuantityWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const QuantityTitle = styled.h2`
  font-weight: 600;
  font-size: 16px;
  color: #1d1e2c;
  margin: 0;
`;

const QuantityInputWrapper = styled.div``;

const PriceWrapper = styled.div`
  border: 1px solid #f5f5f5;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  margin-top: 1rem;
  gap: 20px;

  @media only screen and (max-width: 510px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    border: none;
  }
`;

const PriceText = styled.h2`
  font-weight: 600;
  font-size: 28px;
  color: #1d1e2c;
  margin: 0;
`;

const ButtonWrapper = styled.div`
  max-width: 250px;
  width: 100%;

  @media only screen and (max-width: 510px) {
    max-width: 100%;
  }
`;

const AdWrapper = styled.div`
  width: 100%;
  text-align: center;
  padding-bottom: 3rem;
  cursor: pointer;
`;

const AdImage = styled(LazyLoadImage)`
  height: 100%;
  max-height: 90px;
  width: 100%;
`;

// const OtherProductWrapper = styled.div`
//   padding-top: 3rem;
// `;

// const OtherProductTitle = styled.h2`
//   font-weight: 600;
//   font-size: 18px;
//   color: #151515;
// `;

// const ProductSliderContainer = styled.div`
//   padding-top: 1rem;
// `;

const Product = ({
  productLoading,
  productSuccess,
  productError,
  setGetAProductInAShop,
  setGetAllPublishedAds,
  setIncreaseAdCount,
  adsLoading,
  adsSuccess,
  adsError,
}) => {
  const params = useParams();
  const navigate = useNavigate();
  const [value, setValue] = useState(1);
  const [, setRating] = useState(0);
  const [productPrice, setProductPrice] = useState();
  const [images, setImages] = useState([]);
  const [checkIfProductAdded, setCheckIfProductAdded] = useState(false);
  const [openDeliveryDrawer, setOpenDeliveryDrawer] = useState(false);
  const [currentItemToAddToCart, setCurrentItemToAddToCart] = useState({});
  const [triggerWhenProductAdded, setTriggerWhenProductAdded] = useState(false);
  const [currentProductId, setCurrentProductId] = useState();

  useEffect(() => {
    async function getAProduct() {
      const response = await setGetAProductInAShop(
        params?.shopSlug,
        params?.productSlug
      );
      await setGetAllPublishedAds();
      setImages(
        response?.data?.productDetails?.images?.map((v) => {
          return {
            original: v.url,
            thumbnail: v.url,
            sizes: "cover",
            originalHeight: "300px",
          };
        })
      );
      setCurrentProductId(response?.data?.productDetails?._id);

      setProductPrice(
        value * parseFloat(response?.data?.productDetails?.price)
      );
    }
    getAProduct();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.shopSlug, params?.productSlug]);

  useEffect(() => {
    setCheckIfProductAdded(
      localStorage.getItem("orders") &&
        JSON.parse(localStorage.getItem("orders"))
          ?.map((v) => v._id)
          .includes(currentProductId)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerWhenProductAdded, currentProductId]);

  return (
    <>
      <PageWrapper fluid>
        <ShopNavbarCmp shopSlug={productSuccess?.data?.vendor?.shopSlug} />
        <Wrapper>
          {productLoading ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <SpinnerCmp
                enabled={true}
                color={"#fff"}
                size={40}
                secondaryColor={"#01525F"}
              />
            </div>
          ) : productError ? (
            <EmptyCmp firstText={productError} />
          ) : (
            <>
              <SeoCmp
                title={`Vendar: ${productSuccess?.data?.productDetails?.productName}`}
                description={
                  productSuccess?.data?.productDetails?.productDescription
                }
                url={window.location.href}
                imgUrl={images[0]?.original}
                name={slugToTitle(productSuccess?.data?.vendor?.shopSlug)}
              />
              {adsSuccess && adsSuccess?.data?.length > 0 && (
                <Swiper
                  watchOverflow={true}
                  modules={[Autoplay, EffectFade]}
                  slidesPerView={1}
                  autoplay={{
                    delay: 5000,
                  }}
                  fadeEffect={{ crossFade: true }}
                  effect={"fade"}
                  speed={1500}
                  loop={true}
                >
                  {adsSuccess?.data?.map((v, i) => {
                    return (
                      <SwiperSlide key={i}>
                        <AdWrapper
                          onClick={async () => {
                            const response = await setIncreaseAdCount(v?._id);

                            if (response?.message === "Successful") {
                              window.open(v.link, "_blank");
                            }
                          }}
                        >
                          <AdImage
                            wrapperClassName="ads-image"
                            alt={v.link}
                            effect="blur"
                            src={v.adPicUrl}
                          />
                        </AdWrapper>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              )}
              <MainProductWrapper>
                <MainProductInnerWrapper>
                  <MainProductInnerLeftWrapper>
                    {images && (
                      <ReactImageGallery
                        additionalClass="vendar-gallery"
                        items={images}
                        showPlayButton={false}
                        showFullscreenButton={true}
                        useBrowserFullscreen={false}
                        renderThumbInner={(image) => {
                          return <ThumbnailImage src={image.thumbnail} />;
                        }}
                        renderRightNav={(onClick) => {
                          return (
                            <>
                              <button
                                type="button"
                                className="image-gallery-icon image-gallery-right-nav"
                                aria-label="Next Slide"
                                onClick={onClick}
                              >
                                <NavigateNext
                                  color="#1D1E2C"
                                  size={35}
                                  // className="image-gallery-svg"
                                />
                              </button>
                            </>
                          );
                        }}
                        renderLeftNav={(onClick) => {
                          return (
                            <>
                              <button
                                type="button"
                                className="image-gallery-icon image-gallery-left-nav"
                                aria-label="Previous Slide"
                                onClick={onClick}
                              >
                                <NavigateBefore
                                  color="#1D1E2C"
                                  size={35}
                                  // className="image-gallery-svg"
                                />
                              </button>
                            </>
                          );
                        }}
                      />
                    )}
                  </MainProductInnerLeftWrapper>
                  <MainProductInnerRightWrapper>
                    {productSuccess?.data?.productDetails?.stockQty === 0 && (
                      <LabelCmp background={"red"} />
                    )}

                    <ProductTitle>
                      {productSuccess?.data?.productDetails?.productName &&
                        capitalizeFirstLetter(
                          productSuccess?.data?.productDetails?.productName
                        )}
                    </ProductTitle>
                    <ProductInfoWrapper>
                      <ProductInfoContainer>
                        <RatingWrapper>
                          <Rating
                            onClick={(rating) => {
                              setRating(rating);
                            }}
                            initialValue={
                              productSuccess?.data?.averageRating || 0
                            }
                            fillColor="#FFC700"
                            emptyColor="#DFE6E9"
                            size={15}
                            allowFraction={true}
                            readonly={true}
                          />
                          <RatingNumber>
                            {productSuccess?.data?.averageRating}
                          </RatingNumber>
                        </RatingWrapper>
                        <ProductCodeWrapper>
                          Product Code:{" "}
                          <span style={{ color: "#000" }}>
                            {productSuccess?.data?.productDetails?.productId}
                          </span>
                        </ProductCodeWrapper>
                      </ProductInfoContainer>
                      <VendorWrapper>
                        by{" "}
                        <span
                          style={{ color: "#000", cursor: "pointer" }}
                          onClick={() => {
                            navigate(
                              `/shop/${productSuccess?.data?.vendor?.shopSlug}`
                            );
                          }}
                        >
                          {productSuccess?.data?.vendor?.shopSlug}
                        </span>
                      </VendorWrapper>
                    </ProductInfoWrapper>
                    <ProductDescriptionWrapper>
                      <ProductDescriptionTitle>
                        Product Description
                      </ProductDescriptionTitle>
                      <ProductDescriptionText>
                        {
                          productSuccess?.data?.productDetails
                            ?.productDescription
                        }
                      </ProductDescriptionText>
                    </ProductDescriptionWrapper>
                    <QuantityWrapper>
                      <QuantityTitle>Quantity: </QuantityTitle>
                      <QuantityInputWrapper>
                        <NumericInputCmp
                          onChange={(val) => {
                            setValue(val);
                            setProductPrice(
                              val *
                                parseFloat(
                                  productSuccess?.data?.productDetails?.price
                                )
                            );
                          }}
                          value={value}
                          max={
                            productSuccess?.data?.productDetails?.stockQty ||
                            undefined
                          }
                        />
                      </QuantityInputWrapper>
                    </QuantityWrapper>
                    <PriceWrapper>
                      <PriceText>
                        <NumberFormat
                          thousandSeparator
                          prefix="₦"
                          value={productPrice}
                          // decimalScale={2}
                          defaultValue="0.00"
                          allowNegative={false}
                          placeholder="0.00"
                          displayType="text"
                          disabled
                        />
                      </PriceText>
                      <ButtonWrapper>
                        <SolidButton
                          text={
                            productSuccess?.data?.productDetails?.stockQty ===
                            0 ? (
                              "Out of stock"
                            ) : checkIfProductAdded ? (
                              "Added to cart"
                            ) : (
                              <>
                                <Plus size={18} /> Buy now
                              </>
                            )
                          }
                          type="submit"
                          weighty="700"
                          textFontSize="16px"
                          specifyPadding="1rem 0rem"
                          color="#fff"
                          borderRadius="12px"
                          backColor="#01525F"
                          backgroundHoverColor="#01525F"
                          textHoverColor="#fff"
                          widthWebkit="100%"
                          widthMoz="100%"
                          widthNormal="100%"
                          margin="0"
                          disabled={
                            productSuccess?.data?.productDetails?.stockQty === 0
                              ? true
                              : checkIfProductAdded
                              ? true
                              : false
                          }
                          onClick={() => {
                            setTriggerWhenProductAdded(
                              !triggerWhenProductAdded
                            );
                            if (!checkIfProductAdded) {
                              if (localStorage.getItem("detailsSaved")) {
                                const orders = JSON.parse(
                                  localStorage.getItem("orders")
                                );
                                if (orders) {
                                  localStorage.setItem(
                                    "orders",
                                    JSON.stringify([
                                      ...orders,
                                      {
                                        ...productSuccess?.data?.productDetails,
                                        quantity: value,
                                        totalPrice:
                                          value *
                                          parseFloat(
                                            productSuccess?.data?.productDetails
                                              ?.price
                                          ),
                                        state:
                                          productSuccess?.data?.vendor?.state,
                                        storeName:
                                          productSuccess?.data?.vendor
                                            ?.shopSlug,
                                      },
                                    ])
                                  );
                                } else {
                                  localStorage.setItem(
                                    "orders",
                                    JSON.stringify([
                                      {
                                        ...productSuccess?.data?.productDetails,
                                        quantity: 1,
                                        totalPrice: parseFloat(
                                          productSuccess?.data?.productDetails
                                            ?.price
                                        ),
                                        state:
                                          productSuccess?.data?.vendor?.state,
                                        storeName:
                                          productSuccess?.data?.vendor
                                            ?.shopSlug,
                                      },
                                    ])
                                  );
                                }
                              } else {
                                localStorage.setItem(
                                  "storeDetails",
                                  JSON.stringify({
                                    state: productSuccess?.data?.vendor?.state,
                                  })
                                );
                                setCurrentItemToAddToCart({
                                  ...productSuccess?.data?.productDetails,
                                  quantity: 1,
                                  totalPrice: parseFloat(
                                    productSuccess?.data?.productDetails?.price
                                  ),
                                  state: productSuccess?.data?.vendor?.state,
                                  storeName:
                                    productSuccess?.data?.vendor?.shopSlug,
                                });
                                setOpenDeliveryDrawer(true);
                              }
                            }
                          }}
                        />
                      </ButtonWrapper>
                    </PriceWrapper>
                  </MainProductInnerRightWrapper>
                </MainProductInnerWrapper>
              </MainProductWrapper>
              {/* <OtherProductWrapper>
                <OtherProductTitle>Other products</OtherProductTitle>
                <ProductSliderContainer>
                  {productSuccess?.data?.otherProducts &&
                    productSuccess?.data?.otherProducts?.length && (
                      <Swiper
                        style={{ width: "100%" }}
                        slidesPerView={4.3}
                        spaceBetween={30}
                        breakpoints={{
                          // when window width is >= 320px
                          300: { slidesPerView: 1.3 },
                          390: { slidesPerView: 1.6 },
                          425: { slidesPerView: 1.8 },
                          500: { slidesPerView: 2 },
                          600: { slidesPerView: 2.4 },
                          750: { slidesPerView: 3 },
                          850: { slidesPerView: 3.5 },
                          1010: { slidesPerView: 4 },
                          1100: { slidesPerView: 4.3 },
                        }}
                      >
                        {productSuccess?.data?.otherProducts?.map((v, i) => {
                          return (
                            <SwiperSlide key={i} style={{ width: "100%" }}>
                              <ItemCardCmp
                                width="100%"
                                isSliderItem={true}
                                imageUrl={v?.images[0]?.url}
                                productName={
                                  v.productName &&
                                  capitalizeFirstLetter(v.productName)
                                }
                                price={
                                  <>
                                    <NumberFormat
                                      thousandSeparator
                                      prefix="₦"
                                      value={v.price}
                                      decimalScale={0}
                                      defaultValue="0.00"
                                      allowNegative={false}
                                      placeholder="0.00"
                                      displayType="text"
                                      disabled
                                    />
                                  </>
                                }
                                id={i}
                                removeCartBtn={true}
                                onParentClick={() => {
                                  navigate(
                                    `/p/${v.userId.storeName}/${v.slug}`
                                  );
                                }}
                              />
                            </SwiperSlide>
                          );
                        })}
                      </Swiper>
                    )}
                </ProductSliderContainer>
              </OtherProductWrapper> */}
            </>
          )}
        </Wrapper>
        <ShopFooterCmp />
        <DeliveryDetailsDrawer
          isOpen={openDeliveryDrawer}
          onClose={() => {
            setTriggerWhenProductAdded(!triggerWhenProductAdded);
            setOpenDeliveryDrawer(false);
          }}
          currentItemToAddToCart={currentItemToAddToCart}
          setCurrentItemToAddToCart={setCurrentItemToAddToCart}
        />
      </PageWrapper>
    </>
  );
};

const mapState = (state) => ({
  productLoading: state.shop.aProductInAShopLoading,
  productError: state.shop.aProductInAShopError,
  productSuccess: state.shop.aProductInAShopSuccess,
  adsLoading: state.shop.getAdsLoading,
  adsSuccess: state.shop.getAdsSuccess,
  adsError: state.shop.getAdsError,
});

const mapDispatch = (dispatch) => ({
  setGetAProductInAShop: (shopSlug, productSlug) =>
    dispatch(getAProductInAShop(shopSlug, productSlug)),
  setGetAllPublishedAds: () => dispatch(getAllPublishedAds()),
  setIncreaseAdCount: (adId) => dispatch(increaseAdCount(adId)),
});

export default connect(mapState, mapDispatch)(Product);
