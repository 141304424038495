import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  /* border: 1px solid #d1d1d1; */
  /* padding: 10px; */
  /* max-width: 250px; */
  width: ${(props) => (props.width ? props.width : "24%")};
  border-radius: 14px;
  flex-basis: ${(props) => (props.width ? props.width : "24%")};
  background-color: #fff;
  cursor: pointer;

  @media only screen and (max-width: 995px) {
    flex-basis: ${(props) => (props.width ? props.width : "32%")};
    width: ${(props) => (props.width ? props.width : "32%")};
  }

  @media only screen and (max-width: 757px) {
    flex-basis: ${(props) => (props.width ? props.width : "49%")};
    width: ${(props) => (props.width ? props.width : "49%")};
  }

  @media only screen and (max-width: 531px) {
    flex-basis: 45%;
    width: 45%;
  }

  @media only screen and (max-width: 450px) {
    flex-basis: 48%;
    width: 48%;
  }

  @media only screen and (max-width: 375px) {
    flex-basis: 100%;
    width: 100%;
  }
`;

const ContentHolder = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const BgWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 14px 14px 0 0;
  width: 100%;
  aspect-ratio: 280/150;
  background-color: ${(props) =>
    props.iconBgColor ? props.iconBgColor : "#fff"};
`;

// const IconImg = styled(LazyLoadImage)`
//   @media only screen and (max-width: 510px) {
//     width: 50px;
//     aspect-ratio: 1;
//   }
// `;

const Name = styled.h2`
  font-weight: 700;
  font-size: 18px;
  color: #1d1e2c;
  text-align: center;
  padding: 10px 0;
`;

const Commission = styled.h4`
  font-weight: 500;
  font-size: 16px;
  color: #1d1e2c;
  text-align: center;
  padding: 10px 0;
`;

const CategoryCardCmp = ({ icon, name, iconBgColor, onClick, commission }) => {
  return (
    <>
      <Wrapper onClick={onClick}>
        <ContentHolder>
          <BgWrapper iconBgColor={iconBgColor}>
            {/* <IconImg src={icon} alt={name} /> */}
            {icon}
          </BgWrapper>
          <Name>{name}</Name>
          {commission && <Commission>Commission: {commission}%</Commission>}
        </ContentHolder>
      </Wrapper>
    </>
  );
};

export default CategoryCardCmp;
