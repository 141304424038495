import React from "react";
import { Container } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import styled from "styled-components";
import CtaSvg from "../../assets/cta-svg.svg";
import GooglePlayImage from "../../assets/Play.svg";
import AppleStoreImage from "../../assets/Apple.svg";
import { Fade, Zoom } from "react-awesome-reveal";

const CtaContainer = styled(Container)`
  padding: 6rem 2rem;
  background-color: #f8f9fb;
  margin: ${(props) => props.containerMargin && props.containerMargin};

  @media only screen and (max-width: 500px) {
    padding: 6rem 1rem 3rem;
  }
`;

const CtaWrapper = styled.div`
  width: 100%;
  /* max-width: 800px; */
  max-width: calc(1100px + 3rem);
  margin: 0 auto;
  border-radius: 10px;
  padding: 4rem 1.5rem;
  background: url(${CtaSvg}), #01525f no-repeat;

  @media only screen and (max-width: 500px) {
    padding: 2rem 1rem;
  }
`;

const ContentHolder = styled.div`
  max-width: 700px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
`;

const CtaTitle = styled.h2`
  font-size: 35px;
  font-weight: 800;
  color: #fff;

  @media only screen and (max-width: 500px) {
    font-size: 26px;
  }
`;

const CtaDesc = styled.p`
  color: #fff;
  font-weight: 300;
  font-size: 15px;
  padding: 0.5rem 0;
`;

const StoreWrapper = styled.div`
  display: flex;
  gap: 10px;
  padding-top: 0.5rem;
  justify-content: center;
`;

const StoreImg = styled(LazyLoadImage)`
  cursor: pointer;

  @media only screen and (max-width: 400px) {
    width: 100px;
  }
`;

const CtaCmp = ({ containerMargin }) => {
  return (
    <>
      <CtaContainer fluid containerMargin={containerMargin}>
        <CtaWrapper>
          <ContentHolder>
            <Fade cascade direction="up" triggerOnce>
              <CtaTitle>Up your selling game with Vendar</CtaTitle>
              <CtaDesc>
                An end-to-end world-class eCommerce solution that entrenches
                trust
              </CtaDesc>
            </Fade>
            <Zoom triggerOnce={true}>
              <StoreWrapper>
                <StoreImg
                  src={GooglePlayImage}
                  alt="Google Play Store"
                  effect="blur"
                  onClick={() => {
                    window.open(
                      "https://play.google.com/store/apps/details?id=com.vendar",
                      "_blank"
                    );
                  }}
                />
                <StoreImg
                  src={AppleStoreImage}
                  alt="Apple Store"
                  effect="blur"
                  onClick={() => {
                    window.open(
                      "https://apps.apple.com/us/app/vendar/id6449153439",
                      "_blank"
                    );
                  }}
                />
              </StoreWrapper>
            </Zoom>
          </ContentHolder>
        </CtaWrapper>
      </CtaContainer>
    </>
  );
};

export default CtaCmp;
