/* eslint-disable no-useless-escape */
import * as yup from "yup";

const fillFormSchema = yup.object().shape({
  image: yup
    .mixed()
    .required("Please add an image")
    .test("fileSize", "File Size is too large", (value) => {
      if (value && value[0]?.size) {
        return value[0].size <= 500000;
      }
    })
    .test("fileType", "Unsupported File Format", (value) => {
      if (value && value[0]?.type) {
        if (
          ["image/jpg", "image/jpeg", "image/gif", "image/png"].includes(
            value[0].type
          )
        ) {
          return true;
        }
      }
    }),
  email: yup
    .string()
    .required("Please add email")
    .email("Must be a valid email"),
  description: yup.string().required("Add a description"),
  orderId: yup.string().required("Add Order ID"),
});

export default fillFormSchema;
