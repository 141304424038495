import React from "react";
import styled from "styled-components";

const LabelWrapper = styled.div`
  border-radius: 4px;
  background: ${(props) => (props.background ? props.background : "#000")};
  width: fit-content;
  padding: 4px 6px;
  font-weight: 400;
  font-size: 12px;
  color: ${(props) => (props.color ? props.color : "#fff")};
`;

const LabelCmp = ({ color, background, text }) => {
  return (
    <>
      <LabelWrapper color={color} background={background}>
        {text || "Out of stock"}
      </LabelWrapper>
    </>
  );
};

export default LabelCmp;
