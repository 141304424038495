import React from "react";
import CtaCmp from "../components/CtaCmp/CtaCmp";
import FooterCmp from "../components/FooterCmp/FooterCmp";
import RightImgCmp from "../components/RightImgCmp/RightImgCmp";
import WhyVendarCmp from "../components/WhyVendarCmp/WhyVendarCmp";
import OneAppImg from "../assets/one-app-img.svg";
import PassionImg from "../assets/passion.svg";
import MarketplaceImg from "../assets/marketplace-img.svg";
import BusyImg from "../assets/busy-season-img.svg";
import ConnectImg from "../assets/connect-img.svg";
import LeftImgCmp from "../components/LeftImgCmp/LeftImgCmp";
import HowItWorksCmp from "../components/HowItWorksCmp/HowItWorksCmp";
import HeroCmp from "../components/HeroCmp/HeroCmp";
import NavbarCmp from "../components/NavbarCmp/NavbarCmp";

const Home = () => {
  return (
    <>
      <NavbarCmp />
      <HeroCmp isSlant margin="-80px 0 0 0" />
      <RightImgCmp
        bgColor="#f8f9fb"
        bigTitle={"One app to do it all"}
        bigTitleBolder="and more"
        desc={
          "Vendar helps businesses leverage powerful business tools that actually work."
        }
        imgAlt="One app to do all"
        imgSrc={OneAppImg}
        margin="-3rem 0 0 0"
      />
      <LeftImgCmp
        bgColor="#f8f9fb"
        bigTitle={"Connect with customers"}
        bigTitleBolder="everywhere"
        desc={
          "Explore the social selling tools and sales channels that you need to sell your products everywhere, to everyone."
        }
        imgAlt="Connect with customers"
        imgSrc={ConnectImg}
      />
      <WhyVendarCmp />
      <RightImgCmp
        bgColor="rgba(207, 227, 225, 0.2)"
        bigTitle={"Sell in the global marketplace"}
        bigTitleBolder="in just a few clicks"
        desc={
          "Vendar leverages the combined reach of major social media platforms - Facebook, Instagram, TikTok and Twitter to give your business a wider reach and set you up for more sales."
        }
        imgAlt="Sell in the global marketplace"
        imgSrc={MarketplaceImg}
        isSlant
      />
      <HowItWorksCmp />
      <RightImgCmp
        bigTitle={"Turn your passions"}
        bigTitleBolder="into profit"
        desc={
          "Vendar provides digital creators innovative tools and resources to level up their sales."
        }
        imgAlt="Turn your passions into profit"
        imgSrc={PassionImg}
      />
      <LeftImgCmp
        bigTitle={"Get ready for the"}
        bigTitleBolder="busy season"
        desc={
          "Vendar take your store to the next level  with powerful new tools  packed."
        }
        imgAlt="Get ready for the busy season"
        imgSrc={BusyImg}
        isSlant
      />
      <CtaCmp containerMargin={"-30px 0 0 0"} />
      <FooterCmp />
    </>
  );
};

export default Home;
