import React, { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import styled from "styled-components";
import LogoImg from "../assets/logo.svg";
import Success from "../assets/success.svg";
import { Rating } from "react-simple-star-rating";
import SolidButton from "../components/Buttons/SolidButton";
import { connect } from "react-redux";
import { getReviewOrder, rateOrder } from "../redux/actions/orderAction";
import { useNavigate, useParams } from "react-router-dom";
import EmptyCmp from "../components/EmptyCmp/EmptyCmp";
import SpinnerCmp from "../components/SpinnerCmp/SpinnerCmp";

const Container = styled.div`
  max-width: 375px;
  width: 100%;
  min-height: 80vh;
  height: 100%;
  padding: 1rem;
  margin: 3rem auto;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  min-height: inherit;
`;

const Logo = styled(LazyLoadImage)`
  cursor: pointer;
  padding: 0 0 10px;
`;

const Welcome = styled.h2`
  font-weight: 700;
  font-size: 20px;
  color: #1d1e2c;
  padding: 10px 0;
  margin: 0;
  text-align: center;

  @media only screen and (max-width: 375px) {
    font-size: 18px;
  }
`;

const Desc = styled.p`
  font-weight: 400;
  font-size: 16px;
  color: #526366;
  margin: 0;
  text-align: center;

  @media only screen and (max-width: 375px) {
    font-size: 14px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  align-items: center;
`;

const Content = styled.div``;

const SuccessImg = styled.img`
  width: 100px;
`;

const RatingWrapper = styled.div``;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
`;

const Complete = ({
  getRateOrderError,
  getRateOrderLoading,
  getRateOrderSuccess,
  setRateOrder,
  setGetReviewOrder,
  getReviewOrderLoading,
  getReviewOrderError,
  getReviewOrderSuccess,
}) => {
  const params = useParams();
  const navigate = useNavigate();
  const [rating, setRating] = useState(0);

  useEffect(() => {
    if (!params) {
      navigate("/");
    }
    async function getOrder() {
      await setGetReviewOrder(params?.reviewOrderId);
    }
    getOrder();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.reviewOrderId]);
  return (
    <>
      <Container>
        <Wrapper>
          <Logo src={LogoImg} alt="Vendar" />
          {getReviewOrderLoading ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <SpinnerCmp
                enabled={true}
                color={"#fff"}
                size={40}
                secondaryColor={"#01525F"}
              />
            </div>
          ) : getReviewOrderError ? (
            <EmptyCmp firstText={getReviewOrderError} />
          ) : (
            <>
              {" "}
              <ContentWrapper>
                <SuccessImg src={Success} alt="Success" />
                <Content>
                  <Welcome>Transaction complete</Welcome>
                  <Desc>Thanks for buying with vendar</Desc>
                </Content>
                <RatingWrapper>
                  <Rating
                    onClick={async (rating) => {
                      setRating(rating);
                      await setRateOrder(params?.reviewOrderId, { rating });
                    }}
                    initialValue={rating}
                    fillColor="#FFC700"
                    emptyColor="#DFE6E9"
                    size={40}
                    allowFraction={false}
                  />
                </RatingWrapper>
              </ContentWrapper>
              <ButtonWrapper>
                <Desc>Do you have any complaint about this order?</Desc>
                <SolidButton
                  onClick={() => {
                    navigate(`/form/accept/${params?.reviewOrderId}`);
                  }}
                  text={"Submit Complaint"}
                  type="submit"
                  weighty="600"
                  textFontSize="14px"
                  specifyPadding="0.8rem"
                  color="#fff"
                  widthWebkit={"100%"}
                  widthMoz={"100%"}
                  widthNormal={"100%"}
                  borderRadius="8px"
                  backColor={"#01525F"}
                  backgroundHoverColor={"#01525F"}
                  textHoverColor="#fff"
                  margin="0rem auto 0"
                />
              </ButtonWrapper>
            </>
          )}
        </Wrapper>
      </Container>
    </>
  );
};

const mapState = (state) => ({
  getRateOrderLoading: state.order.rateOrderLoading,
  getRateOrderError: state.order.rateOrderError,
  getRateOrderSuccess: state.order.rateOrderSuccess,
  getReviewOrderLoading: state.order.getReviewOrderLoading,
  getReviewOrderError: state.order.getReviewOrderError,
  getReviewOrderSuccess: state.order.getReviewOrderSuccess,
});

const mapDispatch = (dispatch) => ({
  setRateOrder: (reviewOrderId, data) =>
    dispatch(rateOrder(reviewOrderId, data)),
  setGetReviewOrder: (reviewOrderId) => dispatch(getReviewOrder(reviewOrderId)),
});

export default connect(mapState, mapDispatch)(Complete);
