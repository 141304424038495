import { Provider } from "react-redux";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Home from "./pages/Home";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import store from "./redux/store";
import ChatCmp from "./components/ChatCmp/ChatCmp";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import UserShop from "./pages/UserShop";
import Product from "./pages/Product";
import Cart from "./pages/Cart";
import CategoryShop from "./pages/CategoryShop";
import Shop from "./pages/Shop";
import OrderAction from "./pages/OrderAction";
import Complete from "./pages/Complete";
import FillForm from "./pages/FillForm";
import Verification from "./pages/Verification";
import EmailVerification from "./pages/EmailVerification";
import Marketing from "./pages/Marketing";
import ErrorPage from "./pages/ErrorPage";
import RefundPolicy from "./pages/ReturnPolicy";
import Commission from "./pages/Commission";

function App() {
  return (
    <>
      <Provider store={store}>
        <ToastContainer autoClose={1500} />
        <Router>
          <ChatCmp />
          <Routes>
            <Route path="*" element={<ErrorPage />} />
            <Route path="/" element={<Home />} />
            <Route path="/terms-of-service" element={<Terms />} />
            <Route path="/privacy-policy" element={<Privacy />} />
            <Route
              path="/return-and-refund-policy"
              element={<RefundPolicy />}
            />
            <Route path="/shop/:slug" element={<UserShop />} />
            <Route path="/shop" element={<Shop />} />
            <Route path="/p/:shopSlug/:productSlug" element={<Product />} />
            <Route path="/cart" element={<Cart />} />
            <Route
              path="/categories/:categorySlug"
              element={<CategoryShop />}
            />
            <Route path="/review/:reviewOrderId" element={<OrderAction />} />
            <Route path="/complete/:reviewOrderId" element={<Complete />} />
            <Route
              path="/form/:formType/:reviewOrderId"
              element={<FillForm />}
            />
            <Route
              path="/verify/order/:trackingId"
              element={<Verification />}
            />
            <Route
              path="/verify-email/:emailToken"
              element={<EmailVerification />}
            />
            <Route path="/marketer/:marketerId" element={<Marketing />} />
            <Route path="/docs/category-info" element={<Commission />} />
          </Routes>
        </Router>
      </Provider>
    </>
  );
}

export default App;
