import React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import FooterCmp from "../components/FooterCmp/FooterCmp";
import NavbarCmp from "../components/NavbarCmp/NavbarCmp";
import PageHeadingCmp from "../components/PageHeadingCmp/PageHeadingCmp";

const Privacy = () => {
  const ContainerDiv = styled(Container)`
    background-color: #f8f9fb;
    padding: 5rem 1rem 5rem;

    @media only screen and (max-width: 500px) {
      padding: 3rem 1rem 3rem;
    }
  `;

  const WhiteWrapper = styled.div`
    background: #ffffff;
    border-radius: 10px;
    padding: 1.5rem 1rem;
    max-width: calc(1100px + 3rem);
    width: 100%;
    margin: 0 auto;
    text-align: left;
  `;

  const Title = styled.h2`
    font-weight: 800;
    font-size: 24px;
    color: #01525f;
    margin: 0;
    margin-bottom: 10px;

    @media only screen and (max-width: 700px) {
      font-size: 20px;
    }
  `;

  const SmallTitle = styled.h2`
    font-weight: 600;
    font-size: 18px;
    color: #01525f;
    margin: 0;
    margin-bottom: 10px;

    @media only screen and (max-width: 700px) {
      font-size: 16px;
    }
  `;

  const Text = styled.p`
    font-weight: 400;
    font-size: 15px;
    line-height: 26px;
    color: #1d1e2c;

    @media only screen and (max-width: 600px) {
      font-size: 14px;
    }
  `;

  const Section = styled.div`
    margin-bottom: 1.2rem;
  `;

  return (
    <>
      <NavbarCmp />
      <PageHeadingCmp
        margin="-80px 0 0 0"
        title="Privacy Policy"
        date="Last updated December 2023"
      />
      <ContainerDiv fluid>
        <WhiteWrapper>
          <Section>
            <Title>Privacy Policy for Vendar Ecommerce Seller’s Platform</Title>
            <Text>
              Welcome to Vendar, the premier social selling Ecommerce Platform
              provided as a service by our Company ("Vendar," "we," "us," or
              "our"). This comprehensive Privacy Policy outlines our commitment
              to safeguarding your information and explains how we collect,
              share, and utilize data when you engage with our platform, social
              media, or interact with us through the website [vendar.co] (the
              "Site"). Your use of Vendar signifies your agreement to the terms
              presented in this Privacy Policy.
            </Text>
          </Section>
          <Section>
            <Title>1. Introduction</Title>
            <div>
              <SmallTitle>1.1 Purpose</SmallTitle>
              <Text>
                Information we collect includes both information you knowingly
                and actively provide us when using or participating in any of
                our services and promotions, and any information automatically
                sent by your devices in the course of accessing our products and
                services.
              </Text>
            </div>
            <div>
              <SmallTitle>1.2 Scope</SmallTitle>
              <Text>
                The policy applies to all information collected on the Site and
                delineates the types of data we collect, the purposes for
                collection, and the rights you have in relation to your Personal
                Data.
              </Text>
            </div>{" "}
            <div>
              <SmallTitle>1.3 Vendar's Mission</SmallTitle>
              <Text>
                Vendar is a purpose-built platform that empowers business owners
                at any level. It invokes the spirit of entrepreneurship and
                industry, with a seller-centric focus. The goal is not to
                attract attention to Vendar as an entity but to amplify the
                seller and their wares. The name 'Vendar' is derived from the
                root word 'Vende,' present in various languages globally, such
                as Hindi, where it signifies a Vendor or seller. 'Vende' and
                'Venda' also have root meanings related to 'sell' or 'sales.'
              </Text>
            </div>
          </Section>
          <Section>
            <Title>Key Definitions</Title>
            <div>
              <SmallTitle>2.1 Personal Data</SmallTitle>
              <Text>
                "Personal Data" refers to any information that identifies you
                personally, such as your name, address, telephone number, email
                address, and, in some instances, your Internet Protocol (IP)
                address.
              </Text>
            </div>
            <div>
              <SmallTitle>2.2 Non-personal Data</SmallTitle>
              <Text>
                "Non-personal Data" encompasses technical information that does
                not personally identify you, including cookies, web beacons, and
                demographic information.
              </Text>
            </div>
          </Section>
          <Section>
            <Title>3. Privacy Principles</Title>
            <div>
              <SmallTitle>3.1 Commitment</SmallTitle>
              <Text>
                Vendar is dedicated to privacy and data protection, adhering to
                the following principles:
                <ul>
                  <li>Lawful, fair, and transparent use of Personal Data.</li>
                  <li>
                    Collection of only necessary Personal Data for legitimate
                    purposes.
                  </li>
                  <li>Limited retention of data for specific durations.</li>
                  <li>
                    Implementation of appropriate security measures to protect
                    Personal Data
                  </li>
                </ul>
              </Text>
            </div>
          </Section>
          <Section>
            <Title>4. Data Use</Title>
            <div>
              <SmallTitle>4.1 Purpose of Data Collection</SmallTitle>
              <Text>
                We use collected data for various purposes, including:
                <ul>
                  <li> Service provision and maintenance.</li>
                  <li>Notification of policy changes and order details.</li>
                  <li>Customer support.</li>
                  <li>Site improvement and service optimization.</li>
                  <li>Monitoring Site usage propriety.</li>
                  <li>
                    Detection, prevention, and addressing of technical issues.
                  </li>
                  <li>
                    Providing news, special offers, and general information,
                    unless you opt out.
                  </li>
                </ul>
              </Text>
            </div>
          </Section>
          <Section>
            <Title>5. Location Data and Tracking</Title>
            <div>
              <SmallTitle>5.1 Location Data</SmallTitle>
              <Text>
                With your permission, we may use and store location information
                to enhance and customize your shopping experience.
              </Text>
            </div>
            <div>
              <SmallTitle>5.2 Tracking & Cookies Data</SmallTitle>
              <Text>
                We utilize cookies and similar tracking technologies to analyze
                and enhance Site activity. Your browser settings allow you to
                manage cookie preferences, though disabling cookies may impact
                certain Site functionalities.
              </Text>
            </div>
          </Section>
          <Section>
            <Title>6. Data Storage, Transfer, and Retention</Title>
            <div>
              <SmallTitle>6.1 Geographic Storage</SmallTitle>
              <Text>
                Personal Data is primarily stored and processed within the
                European Union or the European Economic Area (EU/EEA) or the
                United States of America (USA). Any transfers comply with
                applicable laws to protect your data and rights.
              </Text>
            </div>
            <div>
              <SmallTitle>6.2 Data Access</SmallTitle>
              <Text>
                Access to Personal Data is restricted to those needing it for
                processing purposes. Sharing may occur with suppliers,
                subcontractors, and third parties for specific tasks.
              </Text>
            </div>
          </Section>
          <Section>
            <Title>7. Data Security and Disclosure</Title>
            <div>
              <SmallTitle>7.1 Data Security</SmallTitle>
              <Text>
                While we employ commercially acceptable means to protect your
                Personal Data, absolute security is not guaranteed due to
                inherent Internet transmission risks.
              </Text>
            </div>
            <div>
              <SmallTitle>7.2 Data Disclosure</SmallTitle>
              <Text>
                We may disclose Personal Data in response to valid requests by
                public authorities or if required by law.
              </Text>
            </div>
          </Section>
          <Section>
            <Title>8. Data Erasure and Your Rights</Title>
            <div>
              <SmallTitle>8.1 Data Erasure</SmallTitle>
              <Text>
                You have the right to request the erasure of your Personal Data,
                with exceptions outlined in specific situations.
              </Text>
            </div>
            <div>
              <SmallTitle>8.2 Your Data Protection Rights</SmallTitle>
              <Text>
                You possess rights, including:
                <ul>
                  <li>Access to Personal Data.</li>
                  <li>Copy of data for transfer.</li>
                  <li>Rectification of incorrect information.</li>
                  <li>Objection to processing based on legitimate interest.</li>
                </ul>
              </Text>
            </div>
          </Section>
          <Section>
            <Title>9. CCPA Privacy Information</Title>
            <div>
              <SmallTitle>9.1 Rights for California Residents</SmallTitle>
              <Text>
                California residents have additional rights under the California
                Consumer Privacy Act (CCPA), including:
                <ul>
                  <li>Right to restriction.</li>
                  <li>Right against discrimination.</li>
                  <li>
                    Right to know categories of personal information collected.
                  </li>
                  <li>Right to delete personal information.</li>
                  <li>Right to opt-out of selling personal information.</li>
                </ul>
              </Text>
            </div>
          </Section>
          <Section>
            <Title>10. Exercise Your Rights</Title>
            <div>
              <Text>
                To exercise your rights or inquire about your Personal Data,
                contact us at [
                <a href="mailto:info@vendar.co">info@vendar.co</a>].
              </Text>
            </div>
          </Section>
          <Section>
            <Title>11. Contact Information</Title>
            <div>
              <Text>
                For privacy-related concerns, reach out to our dedicated
                Customer Service at [
                <a href="mailto:info@vendar.co">info@vendar.co</a>].
              </Text>
              <Text>
                Thank you for choosing Vendar. We value your trust and remain
                committed to protecting your privacy and data. If you have any
                queries or concerns, feel free to contact us.
              </Text>
            </div>
          </Section>
        </WhiteWrapper>
      </ContainerDiv>
      <FooterCmp />
    </>
  );
};

export default Privacy;
