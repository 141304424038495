import React, { useEffect } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { SwiperSlide, Swiper } from "swiper/react";
import { Autoplay } from "swiper";
import LogoImg from "../assets/logo.svg";
import SolidButton from "../components/Buttons/SolidButton";
import EmptyCmp from "../components/EmptyCmp/EmptyCmp";
import SpinnerCmp from "../components/SpinnerCmp/SpinnerCmp";
import {
  acceptReturnOrder,
  getReviewOrder,
} from "../redux/actions/orderAction";

const Container = styled.div`
  max-width: 375px;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  padding: 1rem;
  margin: 3rem auto;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: inherit;
`;

const Logo = styled(LazyLoadImage)`
  cursor: pointer;
  padding: 0 0 10px;
`;

const Welcome = styled.h2`
  font-weight: 700;
  font-size: 20px;
  color: #1d1e2c;
  padding: 10px 0;
  margin: 0;
  text-align: center;

  @media only screen and (max-width: 375px) {
    font-size: 18px;
  }
`;

const Desc = styled.p`
  font-weight: 400;
  font-size: 16px;
  color: #526366;
  margin: 0;
  text-align: center;

  @media only screen and (max-width: 375px) {
    font-size: 14px;
  }
`;

const ItemContainer = styled.div`
  width: 100%;
`;

const ItemWrapper = styled.div`
  margin: 2rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 450px;
  background: #ffffff;
  border: 1.34194px solid rgba(124, 124, 132, 0.2);
  border-radius: 13.4194px;
  padding: 12px;
`;

const ItemImage = styled.img`
  width: 200px;
  aspect-ratio: 285/241;
`;

const ProductDetails = styled.div`
  padding: 12px 0;
  width: 100%;
`;

const ProductInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Name = styled.h2`
  font-weight: 600;
  font-size: 16px;
  color: #6c6c74;
  margin: 0;
`;

const Info = styled.h2`
  font-weight: 600;
  font-size: 16px;
  color: #1d1e2c;
  margin: 0;
`;

const Line = styled.hr`
  width: 100%;
  height: 1px;
  color: #dfe6e9;
  border-top: 1px solid #dfe6e9;
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
`;

const OrderAction = ({
  setGetReviewOrder,
  getReviewOrderLoading,
  getReviewOrderError,
  getReviewOrderSuccess,
  setAcceptReturnOrder,
  getAcceptReturnOrderLoading,
  getAcceptReturnOrderError,
  getAcceptReturnOrderSuccess,
}) => {
  const params = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (!params) {
      navigate("/");
    }
    async function getOrder() {
      await setGetReviewOrder(params?.reviewOrderId);
    }
    getOrder();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.reviewOrderId]);
  return (
    <>
      <Container>
        <Wrapper>
          <Logo src={LogoImg} alt="Vendar" />
          {getReviewOrderLoading ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <SpinnerCmp
                enabled={true}
                color={"#fff"}
                size={40}
                secondaryColor={"#01525F"}
              />
            </div>
          ) : getReviewOrderError ? (
            <EmptyCmp firstText={getReviewOrderError} />
          ) : (
            <>
              <Welcome>Welcome to your product page</Welcome>
              <Desc>Please confirm by accepting product</Desc>
              <ItemContainer>
                <Swiper
                  modules={[Autoplay]}
                  slidesPerView={1}
                  spaceBetween={30}
                  autoplay={{
                    delay: 5000,
                  }}
                  speed={1500}
                  style={{ width: "100%" }}
                  loop={true}
                  onAfterInit={(s) => {
                    if (getReviewOrderSuccess?.data?.products?.length === 1) {
                      s.autoplay.stop();
                    }
                  }}
                >
                  {getReviewOrderSuccess &&
                    getReviewOrderSuccess?.data &&
                    getReviewOrderSuccess?.data?.products?.map((v, i) => {
                      return (
                        <SwiperSlide key={i}>
                          <ItemWrapper>
                            <ItemImage
                              src={v.productId.images[0].url}
                              alt="Product"
                            />
                            <ProductDetails>
                              <ProductInfo>
                                <Name>Product Name</Name>
                                <Info style={{ textAlign: "right" }}>
                                  {v.productId.productName}
                                </Info>
                              </ProductInfo>
                              <Line />
                              <ProductInfo>
                                <Name>Order ID</Name>
                                <Info>
                                  {getReviewOrderSuccess?.data?.reviewOrderId}
                                </Info>
                              </ProductInfo>
                              <Line />
                              <ProductInfo>
                                <Name>Quantity</Name>
                                <Info>{v.quantity} pieces</Info>
                              </ProductInfo>
                            </ProductDetails>
                          </ItemWrapper>
                        </SwiperSlide>
                      );
                    })}
                </Swiper>

                <ButtonWrapper>
                  <SolidButton
                    onClick={async () => {
                      const response = await setAcceptReturnOrder(
                        getReviewOrderSuccess?.data?.reviewOrderId,
                        { isReturned: true }
                      );

                      if (response?.status === 200) {
                        navigate(
                          `/form/return/${getReviewOrderSuccess?.data?.reviewOrderId}`
                        );
                      }
                    }}
                    text={
                      getAcceptReturnOrderLoading ? (
                        <>
                          <SpinnerCmp
                            enabled={true}
                            color={"000"}
                            size={20}
                            secondaryColor={"#fff"}
                          />
                        </>
                      ) : (
                        "Return"
                      )
                    }
                    type="submit"
                    weighty="600"
                    textFontSize="14px"
                    specifyPadding="0.8rem"
                    color="#01525F"
                    widthWebkit={"100%"}
                    widthMoz={"100%"}
                    widthNormal={"100%"}
                    borderRadius="8px"
                    backColor={"rgba(118, 170, 178, 0.1)"}
                    backgroundHoverColor={"rgba(118, 170, 178, 0.1)"}
                    textHoverColor="#01525F"
                    margin="0rem auto 0"
                    disabled={getAcceptReturnOrderLoading}
                  />
                  <SolidButton
                    onClick={async () => {
                      const response = await setAcceptReturnOrder(
                        getReviewOrderSuccess?.data?.reviewOrderId,
                        { isAccepted: true }
                      );

                      if (response?.status === 200) {
                        navigate(
                          `/complete/${getReviewOrderSuccess?.data?.reviewOrderId}`
                        );
                      }
                    }}
                    text={
                      getAcceptReturnOrderLoading ? (
                        <>
                          <SpinnerCmp
                            enabled={true}
                            color={"000"}
                            size={20}
                            secondaryColor={"#fff"}
                          />
                        </>
                      ) : (
                        "Apply"
                      )
                    }
                    type="submit"
                    weighty="600"
                    textFontSize="14px"
                    specifyPadding="0.8rem"
                    color="#fff"
                    widthWebkit={"100%"}
                    widthMoz={"100%"}
                    widthNormal={"100%"}
                    borderRadius="8px"
                    backColor={"#01525F"}
                    backgroundHoverColor={"#01525F"}
                    textHoverColor="#fff"
                    margin="0rem auto 0"
                    disabled={getAcceptReturnOrderLoading}
                  />
                </ButtonWrapper>
              </ItemContainer>
            </>
          )}
        </Wrapper>
      </Container>
    </>
  );
};

const mapState = (state) => ({
  getReviewOrderLoading: state.order.getReviewOrderLoading,
  getReviewOrderError: state.order.getReviewOrderError,
  getReviewOrderSuccess: state.order.getReviewOrderSuccess,
  getAcceptReturnOrderLoading: state.order.acceptReturnOrderLoading,
  getAcceptReturnOrderError: state.order.acceptReturnOrderError,
  getAcceptReturnOrderSuccess: state.order.acceptReturnOrderSuccess,
});

const mapDispatch = (dispatch) => ({
  setGetReviewOrder: (reviewOrderId) => dispatch(getReviewOrder(reviewOrderId)),
  setAcceptReturnOrder: (reviewOrderId, data) =>
    dispatch(acceptReturnOrder(reviewOrderId, data)),
});

export default connect(mapState, mapDispatch)(OrderAction);
