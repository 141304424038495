import React from "react";
import NumericInput from "react-numeric-input";

const NumericInputCmp = ({ onChange, value, max }) => {
  return (
    <NumericInput
      className="vendar-quantity-selector"
      name="vendar-quantity-selector"
      value={value}
      min={1}
      max={max}
      step={1}
      size={4}
      precision={0}
      inputMode="numeric"
      strict
      mobile
      style={{
        wrap: { height: "40px" },
        input: { height: "40px", border: "none" },
        "input:not(.form-control)": { border: "none" },
        "input:focus": { border: "none", outline: "none" },
        "btnUp.mobile": {
          background: "#E3E5E5",
          borderRadius: "0px 8px 8px 0px",
          padding: "15px",
          border: "none",
          cursor: "pointer",
        },
        "btnDown.mobile": {
          background: "#E3E5E5",
          borderRadius: "8px 0px 0px 8px",
          padding: "15px",
          border: "none",
          cursor: "pointer",
        },
        plus: { background: "#01525F" },
        minus: { background: "#01525F" },
      }}
      onChange={(value) => {
        onChange(value);
      }}
    />
  );
};

export default NumericInputCmp;
