import React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import PageBg from "../../assets/page-heading-bg.svg";

const ContainerDiv = styled(Container)`
  margin: ${(props) => props.margin && props.margin};
  padding: 7rem 3rem 4rem;
  background-color: ${(props) => (props.bgColor ? props.bgColor : "#fff")};
  background: url(${PageBg}), #ffffff no-repeat;
  /* clip-path: ${(props) =>
    props.isSlant && "polygon(0 0, 100% 0, 100% 100%, 0 95%)"}; */

  @media only screen and (max-width: 768px) {
    padding-top: 7rem;
  }

  @media only screen and (max-width: 500px) {
    padding: 6rem 1rem 3rem;
  }
`;

const Wrapper = styled.div`
  max-width: calc(1100px + 3rem);
  width: 100%;
  margin: 0 auto;
  text-align: center;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
`;

const Title = styled.h2`
  color: #01525f;
  font-weight: 800;
  font-size: 40px;
  margin: 0;

  @media only screen and (max-width: 700px) {
    font-size: 30px;
  }

  @media only screen and (max-width: 500px) {
    font-size: 25px;
  }
`;

const Date = styled.p`
  color: #1d1e2c;
  font-size: 17px;
  font-weight: 600;

  @media only screen and (max-width: 500px) {
    font-size: 15px;
  }
`;

const PageHeadingCmp = (props) => {
  return (
    <ContainerDiv
      margin={props.margin}
      padding={props.padding}
      bgColor={props.bgColor}
      fluid
    >
      <Wrapper>
        <ContentWrapper>
          <Title>{props.title}</Title>
          <Date>{props.date}</Date>
        </ContentWrapper>
      </Wrapper>
    </ContainerDiv>
  );
};

export default PageHeadingCmp;
