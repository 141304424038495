exports.capitalizeSentences = (text) => {
  if (!text) {
    return "";
  }

  // Split the text into an array of sentences
  const sentences = text.split(/([.?!]\s+)/);

  // Capitalize the first letter of each sentence
  const capitalizedSentences = sentences.map((sentence) => {
    if (sentence.trim().length > 0) {
      return sentence.charAt(0).toUpperCase() + sentence.slice(1);
    } else {
      return sentence;
    }
  });

  // Join the sentences back together
  return capitalizedSentences.join("");
};
