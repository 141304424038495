import React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import FooterCmp from "../components/FooterCmp/FooterCmp";
import NavbarCmp from "../components/NavbarCmp/NavbarCmp";
import PageHeadingCmp from "../components/PageHeadingCmp/PageHeadingCmp";

const Terms = () => {
  const ContainerDiv = styled(Container)`
    background-color: #f8f9fb;
    padding: 5rem 1rem 5rem;

    @media only screen and (max-width: 500px) {
      padding: 3rem 1rem 3rem;
    }
  `;

  const WhiteWrapper = styled.div`
    background: #ffffff;
    border-radius: 10px;
    padding: 1.5rem 1rem;
    max-width: calc(1100px + 3rem);
    width: 100%;
    margin: 0 auto;
    text-align: left;
  `;

  const Title = styled.h2`
    font-weight: 800;
    font-size: 24px;
    color: #01525f;
    margin: 0;
    margin-bottom: 10px;

    @media only screen and (max-width: 700px) {
      font-size: 20px;
    }
  `;

  const Text = styled.p`
    font-weight: 400;
    font-size: 15px;
    line-height: 26px;
    color: #1d1e2c;

    @media only screen and (max-width: 600px) {
      font-size: 14px;
    }
  `;

  const Section = styled.div`
    margin-bottom: 1.2rem;
  `;

  return (
    <>
      <NavbarCmp />
      <PageHeadingCmp
        margin="-80px 0 0 0"
        title="Terms & Conditions"
        date="Last updated December 2023"
      />
      <ContainerDiv fluid>
        <WhiteWrapper>
          <Section>
            <Title>Terms and Conditions for Vendar Ecommerce Platform</Title>
            <Text>
              Welcome to Vendar, the innovative Ecommerce Platform designed to
              empower business owners at every level. We appreciate your trust
              in our platform, and these Terms and Conditions ("Terms") outline
              the rules and guidelines for your use of the Vendar app and
              website, accessible at [vendar.co] (the "Site"). Please read these
              Terms carefully as they govern your relationship with Vendar.
            </Text>
          </Section>
          <Section>
            <Title>1. Governing Law and Jurisdiction</Title>
            <Text>
              These Terms and Conditions are governed by the laws of the
              jurisdiction specified in your Vendar account settings. Disputes
              arising from these Terms will be exclusively resolved by the
              courts of that jurisdiction. This section establishes a clear
              legal framework for our users and ensures transparency in the
              resolution of any potential conflicts.
            </Text>
          </Section>
          <Section>
            <Title>2. Terms Modification</Title>
            <Text>
              Vendar reserves the right to modify these Terms at any time. It is
              crucial for users to stay informed about any changes. We encourage
              you to review the Terms whenever you access the Site. Your
              continued use of the Site after modifications constitutes your
              acceptance of the updated terms. This clause emphasizes our
              commitment to transparency and user awareness.
            </Text>
          </Section>
          <Section>
            <Title>3. Assignment</Title>
            <Text>
              Vendar may assign its rights and duties under these Terms and
              Conditions to any party at any time without notice. This
              flexibility allows Vendar to adapt to changes while maintaining
              the integrity of its services.
            </Text>
          </Section>
          <Section>
            <Title>4. Age Restrictions</Title>
            <Text>
              Users must certify they are 18 years or older to accept these
              terms. For users under 18, parental supervision is required. This
              section promotes responsible use of our platform, ensuring a safe
              and secure environment for all users.
            </Text>
          </Section>
          <Section>
            <Title>5. Content Permission & Restrictions</Title>
            <Text>
              Ownership of the Content on the Site/App is clarified, and users
              are granted a limited license for personal, non-commercial use.
              This section emphasizes the importance of respecting intellectual
              property rights and sets the boundaries for user engagement.
            </Text>
          </Section>
          <Section>
            <Title>6. On-site Information Accuracy</Title>
            <Text>
              While we strive for accuracy, this section acknowledges the
              dynamic nature of information on the Site. Users are informed
              about the possibility of changes, reinforcing transparency and
              setting realistic expectations.
            </Text>
          </Section>
          <Section>
            <Title>7. Shipping Condition</Title>
            <Text>
              Detailed information about shipping conditions is provided,
              ensuring users are aware of potential delays and are equipped with
              tracking capabilities. This emphasizes our commitment to keeping
              users informed throughout their purchasing journey.
            </Text>
          </Section>
          <Section>
            <Title>8. Email Communication</Title>
            <Text>
              Users agree to receive communications related to products and
              services. Opt-out options are provided, respecting users'
              preferences. This section underlines our commitment to responsible
              and user-friendly communication.
            </Text>
          </Section>
          <Section>
            <Title>9. Order Confirmation</Title>
            <Text>
              The order confirmation process is explained, ensuring users
              understand the steps from placing an order to its confirmation.
              Clear communication is crucial to building trust and satisfaction.
            </Text>
          </Section>
          <Section>
            <Title>10. Compliance and Availability</Title>
            <Text>
              Information about product compliance with laws and regulations is
              provided, and the unpredictability of product availability is
              acknowledged. This section sets realistic expectations for users
              and demonstrates our commitment to legal compliance.
            </Text>
          </Section>
          <Section>
            <Title>11. User Comments</Title>
            <Text>
              Users are allowed to post comments, but guidelines and
              restrictions are established to maintain a positive and respectful
              online community. The importance of responsible user engagement is
              highlighted.
            </Text>
          </Section>
          <Section>
            <Title>12. Links to Third-Party Sites</Title>
            <Text>
              The risks associated with accessing third-party websites are
              communicated, emphasizing user responsibility. This section
              promotes informed decision-making when navigating external links.
            </Text>
          </Section>{" "}
          <Section>
            <Title>13. Prohibited Uses of Site</Title>
            <Text>
              Clear guidelines on prohibited uses of the site are outlined to
              maintain a secure and positive online environment. This section
              contributes to user safety and platform integrity.
            </Text>
          </Section>{" "}
          <Section>
            <Title>14. Registration, Accounts, and Passwords</Title>
            <Text>
              The importance of safeguarding account credentials is stressed,
              and reporting procedures for lost or stolen credentials are
              explained. User responsibility for account security is emphasized.
            </Text>
          </Section>{" "}
          <Section>
            <Title>15. Disclaimer of Warranty & Limitation of Liability</Title>
            <Text>
              The terms of use are presented with a disclaimer of warranty and
              limitation of liability. Users are informed of potential risks
              associated with using the site, promoting transparency and
              accountability.
            </Text>
          </Section>{" "}
          <Section>
            <Title>16. Indemnification</Title>
            <Text>
              Users are informed about their responsibility to indemnify Vendar
              in case of claims or damages arising from their use of the Site.
              This section emphasizes accountability and legal compliance.
            </Text>
          </Section>{" "}
          <Section>
            <Title>17. Disputes</Title>
            <Text>
              A structured dispute resolution procedure is outlined, promoting
              an amicable resolution before resorting to legal measures. This
              section reflects our commitment to fair and transparent conflict
              resolution.
            </Text>
          </Section>{" "}
          <Section>
            <Title>18. Privacy</Title>
            <Text>
              Users are directed to the Privacy Policy for comprehensive
              information on data handling. Privacy is a priority, and this
              section reinforces our commitment to protecting user information.
            </Text>
          </Section>{" "}
          <Section>
            <Title>19. Contact Information</Title>
            <Text>
              Users are encouraged to reach out to Customer Service with
              questions or concerns. This section provides clear communication
              channels, enhancing user support. .
            </Text>
            <Text>
              Thank you for choosing Vendar. Your trust in our platform is
              valued, and we are committed to providing you with an exceptional
              experience. We believe in transparency, user empowerment, and
              responsible online engagement. If you have any questions or need
              assistance, please contact Customer Service at [
              <a href="mailto:info@vendar.co">info@vendar.co</a>]]
            </Text>
          </Section>
        </WhiteWrapper>
      </ContainerDiv>
      <FooterCmp />
    </>
  );
};

export default Terms;
