import React from "react";
import { Zoom, Fade } from "react-awesome-reveal";
import styled from "styled-components";

const CardWrapper = styled.div`
  max-width: 252px;
  max-height: 240px;
  width: 100%;
  height: 100%;
  box-shadow: 0px 4px 4px rgba(1, 97, 112, 0.06);
  border-radius: 10px;
  background-color: #fff;
  padding: 2rem 1.3rem;

  @media only screen and (max-width: 375px) {
    max-width: 100%;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: left;
`;

const IconImg = styled.img`
  width: 36px;
  height: 40px;
`;

const Title = styled.h2`
  color: #1d1e2c;
  font-size: 15px;
  font-weight: 700;
  margin: 0;
`;

const Desc = styled.p`
  color: #515151;
  opacity: 0.7;
  font-size: 13px;
  font-weight: 400;
`;

const CardWithIcon = ({ icon, title, desc }) => {
  return (
    <>
      <CardWrapper>
        <ContentWrapper>
          <Zoom duration="400">
            <IconImg src={icon} alt={title} />
          </Zoom>
          <Fade cascade direction="down" duration={"900"} damping={0.3}>
            <Title>{title}</Title>
            <Desc>{desc}</Desc>
          </Fade>
        </ContentWrapper>
      </CardWrapper>
    </>
  );
};

export default CardWithIcon;
