import { Disc, DiscFill, Earbuds, PrinterFill } from "@styled-icons/bootstrap";
import {
  Automobile,
  Baby,
  Book,
  Camera,
  Desktop,
  Female,
  FileAudio,
  Football,
  Gamepad,
  Home,
  KitchenSet,
  Laptop,
  Male,
  Music,
  Phone,
  PrescriptionBottle,
  Satellite,
  Tablet,
  TabletAlt,
  Television,
  UserFriends,
  Video,
  WineGlass,
} from "@styled-icons/fa-solid";
import {
  Cable,
  DriveFileMove,
  ElectricalServices,
  EmojiFoodBeverage,
  FoodBank,
  Scanner,
  Sports,
} from "@styled-icons/material-rounded";

const categoryIcons = {
  "musical-instruments": <Music size={50} color="rgba(29, 30, 44, 0.2)" />,
  desktop: <Desktop size={50} color="rgba(29, 30, 44, 0.2)" />,
  "mobile-accessories": <Earbuds size={50} color="rgba(29, 30, 44, 0.2)" />,
  cameras: <Camera size={50} color="rgba(29, 30, 44, 0.2)" />,
  softwares: <DiscFill size={50} color="rgba(29, 30, 44, 0.2)" />,
  "computer-pheripherals-and-accessories": (
    <DriveFileMove size={50} color="rgba(29, 30, 44, 0.2)" />
  ),
  "home-and-office": <Home size={50} color="rgba(29, 30, 44, 0.2)" />,
  "women-fashion": <Female size={50} color="rgba(29, 30, 44, 0.2)" />,
  printers: <PrinterFill size={50} color="rgba(29, 30, 44, 0.2)" />,
  tablets: <Tablet size={50} color="rgba(29, 30, 44, 0.2)" />,
  "audio-and-video": <Video size={50} color="rgba(29, 30, 44, 0.2)" />,
  "scanners-and-photocopiers": (
    <Scanner size={50} color="rgba(29, 30, 44, 0.2)" />
  ),
  laptops: <Laptop size={50} color="rgba(29, 30, 44, 0.2)" />,
  "blu-ray-and-dvd-players": <Disc size={50} color="rgba(29, 30, 44, 0.2)" />,
  "mobile-phones": <Phone size={50} color="rgba(29, 30, 44, 0.2)" />,
  "networking-accessories": <Cable size={50} color="rgba(29, 30, 44, 0.2)" />,
  "men-fashion": <Male size={50} color="rgba(29, 30, 44, 0.2)" />,
  "beauty-and-perfumes": (
    <PrescriptionBottle size={50} color="rgba(29, 30, 44, 0.2)" />
  ),
  "tablet-accessories": <TabletAlt size={50} color="rgba(29, 30, 44, 0.2)" />,
  televisions: <Television size={50} color="rgba(29, 30, 44, 0.2)" />,
  "kitchen-and-dining": <KitchenSet size={50} color="rgba(29, 30, 44, 0.2)" />,
  "electrical-appliances": (
    <ElectricalServices size={50} color="rgba(29, 30, 44, 0.2)" />
  ),
  "baby-kids-and-toys": <Baby size={50} color="rgba(29, 30, 44, 0.2)" />,
  "sports-health-and-fitness": (
    <Sports size={50} color="rgba(29, 30, 44, 0.2)" />
  ),
  consoles: <Gamepad size={50} color="rgba(29, 30, 44, 0.2)" />,
  games: <Football size={50} color="rgba(29, 30, 44, 0.2)" />,
  "wedding-shop": <UserFriends size={50} color="rgba(29, 30, 44, 0.2)" />,
  "food-and-groceries": <FoodBank size={50} color="rgba(29, 30, 44, 0.2)" />,
  beverages: <EmojiFoodBeverage size={50} color="rgba(29, 30, 44, 0.2)" />,
  "books-and-stationery": <Book size={50} color="rgba(29, 30, 44, 0.2)" />,
  "media-and-digital-products": (
    <FileAudio size={50} color="rgba(29, 30, 44, 0.2)" />
  ),
  automobile: <Automobile size={50} color="rgba(29, 30, 44, 0.2)" />,
  "wines-spirits-and-cigars": (
    <WineGlass size={50} color="rgba(29, 30, 44, 0.2)" />
  ),
  "decoders-and-satellite-dishes": (
    <Satellite size={50} color="rgba(29, 30, 44, 0.2)" />
  ),
};

export default categoryIcons;
