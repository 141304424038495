//types used in VERIFY_EMAIL
export const VERIFY_EMAIL_LOADING = "VERIFY_EMAIL_LOADING";
export const VERIFY_EMAIL_SUCCESS = "VERIFY_EMAIL_SUCCESS";
export const VERIFY_EMAIL_ERROR = "VERIFY_EMAIL_ERROR";

//types used in SUBSCRIBE
export const SUBSCRIBE_LOADING = "SUBSCRIBE_LOADING";
export const SUBSCRIBE_SUCCESS = "SUBSCRIBE_SUCCESS";
export const SUBSCRIBE_ERROR = "SUBSCRIBE_ERROR";

//types used in SHOP
export const PRODUCTS_IN_A_SHOP_LOADING = "PRODUCTS_IN_A_SHOP_LOADING";
export const PRODUCTS_IN_A_SHOP_SUCCESS = "PRODUCTS_IN_A_SHOP_SUCCESS";
export const PRODUCTS_IN_A_SHOP_ERROR = "PRODUCTS_IN_A_SHOP_ERROR";

export const A_PRODUCT_IN_A_SHOP_LOADING = "A_PRODUCT_IN_A_SHOP_LOADING";
export const A_PRODUCT_IN_A_SHOP_SUCCESS = "A_PRODUCT_IN_A_SHOP_SUCCESS";
export const A_PRODUCT_IN_A_SHOP_ERROR = "A_PRODUCT_IN_A_SHOP_ERROR";

export const PRODUCTS_IN_A_CATEGORY_LOADING = "PRODUCTS_IN_A_CATEGORY_LOADING";
export const PRODUCTS_IN_A_CATEGORY_SUCCESS = "PRODUCTS_IN_A_CATEGORY_SUCCESS";
export const PRODUCTS_IN_A_CATEGORY_ERROR = "PRODUCTS_IN_A_CATEGORY_ERROR";

export const VERIFY_COUPON_CODE_LOADING = "VERIFY_COUPON_CODE_LOADING";
export const VERIFY_COUPON_CODE_SUCCESS = "VERIFY_COUPON_CODE_SUCCESS";
export const VERIFY_COUPON_CODE_ERROR = "VERIFY_COUPON_CODE_ERROR";

export const CALCULATE_DELIVERY_FEE_LOADING = "CALCULATE_DELIVERY_FEE_LOADING";
export const CALCULATE_DELIVERY_FEE_SUCCESS = "CALCULATE_DELIVERY_FEE_SUCCESS";
export const CALCULATE_DELIVERY_FEE_ERROR = "CALCULATE_DELIVERY_FEE_ERROR";

export const GET_CATEGORIES_LOADING = "GET_CATEGORIES_LOADING";
export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS";
export const GET_CATEGORIES_ERROR = "GET_CATEGORIES_ERROR";

export const GET_NEW_ARRIVALS_LOADING = "GET_NEW_ARRIVALS_LOADING";
export const GET_NEW_ARRIVALS_SUCCESS = "GET_NEW_ARRIVALS_SUCCESS";
export const GET_NEW_ARRIVALS_ERROR = "GET_NEW_ARRIVALS_ERROR";

export const CREATE_ORDER_LOADING = "CREATE_ORDER_LOADING";
export const CREATE_ORDER_SUCCESS = "CREATE_ORDER_SUCCESS";
export const CREATE_ORDER_ERROR = "CREATE_ORDER_ERROR";

export const GET_REVIEW_ORDER_LOADING = "GET_REVIEW_ORDER_LOADING";
export const GET_REVIEW_ORDER_SUCCESS = "GET_REVIEW_ORDER_SUCCESS";
export const GET_REVIEW_ORDER_ERROR = "GET_REVIEW_ORDER_ERROR";

export const ACCEPT_RETURN_ORDER_LOADING = "ACCEPT_RETURN_ORDER_LOADING";
export const ACCEPT_RETURN_ORDER_SUCCESS = "ACCEPT_RETURN_ORDER_SUCCESS";
export const ACCEPT_RETURN_ORDER_ERROR = "ACCEPT_RETURN_ORDER_ERROR";

export const RATE_ORDER_LOADING = "RATE_ORDER_LOADING";
export const RATE_ORDER_SUCCESS = "RATE_ORDER_SUCCESS";
export const RATE_ORDER_ERROR = "RATE_ORDER_ERROR";

export const FILL_ACCEPT_RETURN_FORM_LOADING =
  "FILL_ACCEPT_RETURN_FORM_LOADING";
export const FILL_ACCEPT_RETURN_FORM_SUCCESS =
  "FILL_ACCEPT_RETURN_FORM_SUCCESS";
export const FILL_ACCEPT_RETURN_FORM_ERROR = "FILL_ACCEPT_RETURN_FORM_ERROR";

export const GET_ADS_LOADING = "GET_ADS_LOADING";
export const GET_ADS_SUCCESS = "GET_ADS_SUCCESS";
export const GET_ADS_ERROR = "GET_ADS_ERROR";

export const INCREASE_AD_COUNT_LOADING = "INCREASE_AD_COUNT_LOADING";
export const INCREASE_AD_COUNT_SUCCESS = "INCREASE_AD_COUNT_SUCCESS";
export const INCREASE_AD_COUNT_ERROR = "INCREASE_AD_COUNT_ERROR";

export const VERIFY_TRACKING_ID_LOADING = "VERIFY_TRACKING_ID_LOADING";
export const VERIFY_TRACKING_ID_SUCCESS = "VERIFY_TRACKING_ID_SUCCESS";
export const VERIFY_TRACKING_ID_ERROR = "VERIFY_TRACKING_ID_ERROR";

export const VERIFY_DELIVERY_CODE_LOADING = "VERIFY_DELIVERY_CODE_LOADING";
export const VERIFY_DELIVERY_CODE_SUCCESS = "VERIFY_DELIVERY_CODE_SUCCESS";
export const VERIFY_DELIVERY_CODE_ERROR = "VERIFY_DELIVERY_CODE_ERROR";

export const GET_A_MARKETER_LOADING = "GET_A_MARKETER_LOADING";
export const GET_A_MARKETER_SUCCESS = "GET_A_MARKETER_SUCCESS";
export const GET_A_MARKETER_ERROR = "GET_A_MARKETER_ERROR";

export const SUBMIT_SHOP_DETAILS_LOADING = "SUBMIT_SHOP_DETAILS_LOADING";
export const SUBMIT_SHOP_DETAILS_SUCCESS = "SUBMIT_SHOP_DETAILS_SUCCESS";
export const SUBMIT_SHOP_DETAILS_ERROR = "SUBMIT_SHOP_DETAILS_ERROR";
