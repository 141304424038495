import React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import { ArrowForwardOutline } from "@styled-icons/evaicons-outline";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Fade } from "react-awesome-reveal";

const ContainerDiv = styled(Container)`
  padding: 4rem 3rem;
  background-color: ${(props) => (props.bgColor ? props.bgColor : "#fff")};
  clip-path: ${(props) =>
    props.isSlant && "polygon(0 0, 100% 0, 100% 100%, 0 95%)"};
  margin: ${(props) => props.margin && props.margin};

  @media only screen and (max-width: 500px) {
    /* padding: 5rem 1rem 3rem; */
    padding: 5rem 1rem 0.73rem;
  }
`;

const Wrapper = styled.div`
  max-width: calc(1100px + 3rem);
  width: 100%;
  margin: 0 auto;
  text-align: center;
`;

const ContentImgHolder = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;

  @media only screen and (max-width: 450px) {
    flex-wrap: wrap;
    gap: 15px;
  }
`;

const LeftSection = styled.div`
  max-width: 350px;
  width: 100%;
  text-align: left;
`;

const BigTitle = styled.h2`
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
  font-weight: 500;
  font-size: 35px;
  color: #1d1e2c;
  line-height: 45px;

  @media only screen and (max-width: 500px) {
    font-size: 30px;
    line-height: 40px;
  }
`;

const Desc = styled.p`
  max-width: 340px;
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  color: #1d1e2c;
  opacity: 0.7;
  padding-top: 0.5rem;

  @media only screen and (max-width: 500px) {
    max-width: 300px;
  }
`;

const LearnMoreWrapper = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  font-weight: 700;
  color: #01525f;
  cursor: pointer;
  font-size: 17px;
`;

const RightImg = styled(LazyLoadImage)`
  width: unset;
  @media only screen and (max-width: 1000px) {
    width: 100%;
  }

  @media only screen and (max-width: 600px) {
    width: 80%;
  }
`;

const RightImgCmp = ({
  bigTitle,
  bigTitleBolder,
  desc,
  imgAlt,
  imgSrc,
  bgColor,
  isSlant,
  margin,
}) => {
  return (
    <>
      <ContainerDiv bgColor={bgColor} fluid isSlant={isSlant} margin={margin}>
        <Wrapper>
          <ContentImgHolder>
            <LeftSection>
              <BigTitle>
                {bigTitle}{" "}
                <span style={{ fontWeight: "800" }}>{bigTitleBolder}</span>
              </BigTitle>
              <Fade cascade direction="down" triggerOnce>
                <Desc>{desc}</Desc>
                <LearnMoreWrapper>
                  <span>Learn more</span>
                  <span>
                    <ArrowForwardOutline color="#01525f" size="16" />
                  </span>
                </LearnMoreWrapper>
              </Fade>
            </LeftSection>
            <Fade direction="left" triggerOnce>
              <RightImg src={imgSrc} alt={imgAlt} effect="blur" />
            </Fade>
          </ContentImgHolder>
        </Wrapper>
      </ContainerDiv>
    </>
  );
};

export default RightImgCmp;
