import React from "react";
import styled from "styled-components";
import UnderlineTextCmp from "../UnderlineTextCmp/UnderlineTextCmp";

const DropdownWrapper = styled.div`
  background-color: #fff;
  border-radius: 10px;
  max-width: 280px;
  min-width: 250px;
  width: 100%;
  padding: 1rem;
`;

const DropdownTitle = styled.div`
  font-weight: 800;
  font-size: 16px;
  color: #1d1e2c;
  margin-bottom: 12px;
`;

const DropdownDesc = styled.p``;

const DropdownMenuWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 2rem;
`;

const LeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const RightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const Text = styled.h2`
  font-weight: 800;
  font-size: 14px;
`;

const DropdownCmp = (props) => {
  return (
    <>
      <DropdownWrapper>
        <DropdownTitle>
          <UnderlineTextCmp zIndex="2001" height="0.4rem" bottom="-0.25rem">
            {props.title}
          </UnderlineTextCmp>
        </DropdownTitle>
        <DropdownDesc>{props.desc}</DropdownDesc>

        <DropdownMenuWrapper>
          <LeftWrapper>
            {props?.leftArr.map((v, i) => {
              return <Text key={i}>{v.text}</Text>;
            })}
          </LeftWrapper>
          {props.rightArr && (
            <RightWrapper>
              {props?.rightArr.map((v, i) => {
                return <Text key={i}>{v.text}</Text>;
              })}
            </RightWrapper>
          )}
        </DropdownMenuWrapper>
      </DropdownWrapper>
    </>
  );
};

export default DropdownCmp;
