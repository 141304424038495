import React from "react";
import { Helmet } from "react-helmet-async";

const SeoCmp = ({ title, description, url, imgUrl, name }) => {
  return (
    <Helmet>
      {/* Primary Meta Tags */}
      <title>{title || "Vendar"}</title>
      <meta name="title" content={title || "Vendar"} />
      <meta
        name="description"
        content={description || "Best way to trade your goods"}
      />

      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url || "https://vendar.co/"} />
      <meta property="og:title" content={title || "Vendar"} />
      <meta
        property="og:description"
        content={description || "Best way to trade your goods"}
      />
      <meta
        property="og:image"
        content={
          imgUrl ||
          "https://res.cloudinary.com/dfobcfdhv/image/upload/v1701933909/vendar-assets/logo_fdh6sv.svg"
        }
      />

      {/* Twitter  */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:image"
        content={
          imgUrl ||
          "https://res.cloudinary.com/dfobcfdhv/image/upload/v1701933909/vendar-assets/logo_fdh6sv.svg"
        }
      ></meta>
      <meta name="twitter:url" content={url || "https://vendar.co/"} />
      <meta name="twitter:title" content={title || "Vendar"} />
      <meta
        name="twitter:description"
        content={description || "Best way to trade your goods"}
      />
    </Helmet>
  );
};

export default SeoCmp;
