/* eslint-disable no-useless-escape */
import * as yup from "yup";

const submitShopDetailsSchema = yup.object().shape({
  shopName: yup.string().required("Provide shop name"),
  shopUrl: yup
    .string()
    .required("Provide shop url")
    .matches(
      /^(https?:\/\/)?(www\.)?(stalwart-churros-fb4b99\.netlify\.app\/shop\/[a-zA-Z0-9-]+|vendar\.co\/shop\/[a-zA-Z0-9-]+)/,
      "Invalid Vendar URL"
    ),
});

export default submitShopDetailsSchema;
