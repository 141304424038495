import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import Favicon from "react-favicon";
import Icon from "./assets/favicon.svg";
import { HelmetProvider } from "react-helmet-async";
import SeoCmp from "./components/SEOCmp/SeoCmp";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <SeoCmp />
      <Favicon url={Icon} />
      <App />
    </HelmetProvider>
  </React.StrictMode>
);
