import { Formik } from "formik";
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import LogoImg from "../assets/logo.svg";
import SolidButton from "../components/Buttons/SolidButton";
import CustomInputCmp from "../components/InputsCmp/CustomInputCmp";
import CustomFileCmp from "../components/InputsCmp/CustomFileCmp";
import CustomTextAreaCmp from "../components/InputsCmp/CustomTextAreaCmp";
import fillFormSchema from "../validators/fillFormValidator";
import { connect } from "react-redux";
import { fillAcceptReturnForm } from "../redux/actions/orderAction";
import SpinnerCmp from "../components/SpinnerCmp/SpinnerCmp";
import { toast } from "react-toastify";

const Container = styled.div`
  max-width: 375px;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  padding: 1rem;
  margin: 3rem auto;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: inherit;
`;

const Logo = styled(LazyLoadImage)`
  cursor: pointer;
  padding: 0 0 10px;
`;

const Welcome = styled.h2`
  font-weight: 700;
  font-size: 20px;
  color: #1d1e2c;
  padding: 10px 0;
  margin: 0;
  text-align: center;

  @media only screen and (max-width: 375px) {
    font-size: 18px;
  }
`;

const Desc = styled.p`
  font-weight: 400;
  font-size: 16px;
  color: #526366;
  margin: 0;
  text-align: center;

  @media only screen and (max-width: 375px) {
    font-size: 14px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
`;

const InputWrapper = styled.div`
  width: 100%;
`;

const InputLabel = styled.h2`
  font-weight: 500;
  font-size: 14px;
  color: #6c6c74;
`;

const FillForm = ({
  setFillAcceptReturnForm,
  getFillAcceptReturnFormLoading,
  getFillAcceptReturnFormSuccess,
  getFillAcceptReturnFormError,
}) => {
  const params = useParams();
  const navigate = useNavigate();

  return (
    <>
      <Container>
        <Wrapper>
          <Logo src={LogoImg} alt="Vendar" />
          <Welcome>
            {params?.formType === "complain"
              ? "Complaint Form"
              : "Return Product"}
          </Welcome>
          <Desc>Please fill this form if the product has issues</Desc>
          <Formik
            validationSchema={fillFormSchema}
            enableReinitialize={false}
            isInitialValid={true}
            initialValues={{
              email: "",
              orderId: "",
              description: "",
              image: "",
            }}
            onSubmit={async (values, { resetForm }) => {
              let formData = new FormData();
              if (params?.formType === "complain") {
                formData.append("isComplain", true);
              } else {
                formData.append("isReturned", true);
              }

              formData.append("image", values.image[0], values.image[0].name);
              formData.append("description", values.description);
              formData.append("reviewOrderId", values.orderId);
              formData.append("email", values.email);

              const response = await setFillAcceptReturnForm(formData);

              if (
                response &&
                response.status === 200 &&
                response.message === "Review created successfully"
              ) {
                toast.success(`Review created successfully`);
                return navigate("/shop");
              }
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              setFieldValue,
              values,
              touched,
              isValid,
              errors,
            }) => (
              <>
                <form
                  style={{
                    width: "100%",
                    paddingTop: "1rem",
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                  noValidate
                  onSubmit={handleSubmit}
                >
                  <InputWrapper>
                    <InputLabel>Order ID</InputLabel>
                    <CustomInputCmp
                      value={values.orderId}
                      onChange={handleChange}
                      name="orderId"
                      placeholder="Enter Order ID"
                      message={`${errors["orderId"] ? errors["orderId"] : ""}`}
                    />
                  </InputWrapper>
                  <InputWrapper>
                    <InputLabel>Email address</InputLabel>
                    <CustomInputCmp
                      value={values.email}
                      onChange={handleChange}
                      name="email"
                      placeholder="Enter email"
                      message={`${errors["email"] ? errors["email"] : ""}`}
                    />
                  </InputWrapper>
                  <InputWrapper>
                    <InputLabel>Description</InputLabel>
                    <CustomTextAreaCmp
                      background="rgba(225, 235, 245, 0.1)"
                      as="textarea"
                      rows={3}
                      required={true}
                      onChange={handleChange}
                      values={values}
                      type="text"
                      name="description"
                      placeholder="Enter description"
                      showMessage
                      message={`${
                        errors["description"] ? errors["description"] : ""
                      }`}
                    />
                  </InputWrapper>
                  <InputWrapper>
                    <InputLabel>Upload image</InputLabel>
                    <CustomFileCmp
                      maxFiles={1}
                      maxSize={1000000}
                      accept={{
                        "image/png": [".png"],
                        "image/jpeg": [".jpg", ".jpeg"],
                      }}
                      type="text"
                      placeholder="Click to upload an image"
                      values={values}
                      setFieldValue={setFieldValue}
                      name="image"
                      showMessage
                      message={`${errors["image"] ? errors["image"] : ""}`}
                    />
                  </InputWrapper>
                  <ButtonWrapper>
                    <SolidButton
                      text={
                        getFillAcceptReturnFormLoading ? (
                          <>
                            <SpinnerCmp
                              enabled={true}
                              color={"000"}
                              size={20}
                              secondaryColor={"#fff"}
                            />
                          </>
                        ) : (
                          "Submit"
                        )
                      }
                      type="submit"
                      weighty="700"
                      textFontSize="16px"
                      specifyPadding="1rem 1rem"
                      color="#fff"
                      borderRadius="11px"
                      backColor="#01525F"
                      backgroundHoverColor="#01525F"
                      textHoverColor="#fff"
                      widthWebkit="100%"
                      widthMoz="100%"
                      widthNormal="100%"
                      margin="0"
                      disabled={getFillAcceptReturnFormLoading}
                    />
                  </ButtonWrapper>
                </form>
              </>
            )}
          </Formik>
        </Wrapper>
      </Container>
    </>
  );
};

const mapState = (state) => ({
  getFillAcceptReturnFormLoading: state.order.fillAcceptReturnFormLoading,
  getFillAcceptReturnFormError: state.order.fillAcceptReturnFormError,
  getFillAcceptReturnFormSuccess: state.order.fillAcceptReturnFormSuccess,
});

const mapDispatch = (dispatch) => ({
  setFillAcceptReturnForm: (data) => dispatch(fillAcceptReturnForm(data)),
});

export default connect(mapState, mapDispatch)(FillForm);
