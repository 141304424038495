import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import styled from "styled-components";
import ModalCmp from "../ModalCmp/ModalCmp";
import VendarDownloadImg from "../../assets/vendar-download.svg";
import { QRCodeSVG } from "qrcode.react";

const Wrapper = styled.div`
  padding: ${(props) =>
    props.wrapperPadding ? props.wrapperPadding : "2rem 1rem"};
`;

const Container = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
`;

const TextWrapper = styled.div``;

const Title = styled.h2`
  font-weight: 700;
  font-size: 42px;
  color: #01525f;
`;

const ImgHolder = styled(LazyLoadImage)``;

const DescText = styled.p`
  font-weight: 400;
  font-size: 14px;
  color: #1d1e2c;
  opacity: 0.8;
  max-width: 280px;
`;

const QrCodeWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 60px rgba(1, 97, 112, 0.1);
  border-radius: 10px;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DownloadModal = (props) => {
  return (
    <ModalCmp
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose}
      contentStyles={{ maxWidth: props.maxWidth || "350px" }}
      shouldFocusAfterRender={false}
      contentLabel="Success"
      id="success"
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={false}
      showCloseIcon={props.showCloseIcon}
    >
      <Wrapper wrapperPadding={props.wrapperPadding}>
        <Container>
          <TextWrapper>
            <Title>
              Download Vendar,{" "}
              <span style={{ fontWeight: 900, color: "#5A98A2" }}>
                open an account!
              </span>
            </Title>
            <DescText>
              Scan the QR code below with your phone camera to download the
              Vendar app.
            </DescText>
            <QrCodeWrapper>
              <QRCodeSVG
                id="qrCode-vendar"
                value={"https://vendar.co"}
                size={100}
                level={"H"}
                includeMargin={true}
              />
            </QrCodeWrapper>
          </TextWrapper>
          <ImgHolder src={VendarDownloadImg} effect="blur" />
        </Container>
      </Wrapper>
    </ModalCmp>
  );
};

export default DownloadModal;
