import React from "react";
import styled from "styled-components";
import { Container } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import {
  InstagramAlt,
  Facebook,
  Twitter,
  Tiktok,
  Pinterest,
} from "@styled-icons/boxicons-logos";
// import { Link } from "react-scroll";
import GooglePlayImage from "../../assets/Play.svg";
import AppleStoreImage from "../../assets/Apple.svg";
import LogoImg from "../../assets/logo.svg";

const FooterContainer = styled(Container)`
  background-color: #f1f4f7;
  padding: 4rem 3rem;

  @media only screen and (max-width: 760px) {
    padding: 4rem 1rem;
  }
`;

const FooterWrapper = styled.div`
  max-width: calc(1100px + 5rem);
  margin: 0 auto;

  @media only screen and (max-width: 500px) {
    max-width: calc(1000px + 1rem);
  }
`;

const ColDiv = styled.div`
  flex: 25%;
  max-width: 250px;

  @media only screen and (max-width: 1114px) {
    max-width: 500px;
  }

  @media only screen and (max-width: 939px) {
    max-width: 300px;
    flex: 33%;
    padding-bottom: 2rem;
  }

  @media only screen and (max-width: 625px) {
    flex: 50%;
  }

  @media only screen and (max-width: 596px) {
    flex: 50%;
    max-width: 250px;
  }

  @media only screen and (max-width: 450px) {
    max-width: 200px;
    flex: 50%;
  }
`;

const ColDivTitle = styled.h2`
  font-weight: 800;
  color: #01525f;
  font-size: 18px;
  padding-bottom: 0.5rem;

  @media only screen and (max-width: 420px) {
    max-width: 160px;
  }
`;

const ItemWrapper = styled.div``;

const ItemList = styled.li`
  list-style-type: none;
  padding: 0 0 1rem;
`;

const ItemLink = styled.a`
  text-decoration: none;
  font-size: 16px;
  font-weight: 400;
  color: #1d1e2c;
  cursor: pointer;

  &:hover {
    color: #1d1e2c;
  }
`;

const ThirdRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 2rem 0 4rem;

  @media only screen and (max-width: 625px) {
    padding: 1rem 0 0rem;
  }
`;

const CopyrightFooter = styled.p`
  text-align: center;
  color: #1d1e2c;
  font-size: 12px;
  margin: 0;
  font-weight: 400;
  padding: 1rem 0;
`;

const SocialWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  padding-top: 0.7rem;
`;

const StoreWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 1rem 0;
  gap: 1px;
`;

const StoreImg = styled(LazyLoadImage)`
  margin: 0 0.3rem 0.3rem 0;
  cursor: pointer;
  width: 115px;

  @media only screen and (max-width: 577px) {
    width: 80px;
  }
`;

const IconContainer = styled.div`
  width: 27px;
  height: 27px;
  border-radius: 50%;
  border: 1px solid #01525f;
  background-color: #01525f;
  cursor: pointer;
  margin: 0 0.3rem 0.3rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Logo = styled(LazyLoadImage)`
  padding-bottom: 1rem;
`;

function ShopFooterCmp() {
  const openLink = (link) => {
    return window.open(link, "_blank");
  };

  //   const navigate = useNavigate();
  return (
    <>
      <FooterContainer fluid>
        <FooterWrapper>
          <ThirdRow>
            <ColDiv>
              <Logo src={LogoImg} alt="Vendar" />
              <ItemWrapper>
                <ItemList>
                  At Vendar, we believe that social selling is the future.
                </ItemList>
              </ItemWrapper>
            </ColDiv>
            <ColDiv>
              <ColDivTitle>Contact us</ColDivTitle>
              <ItemWrapper
                style={{
                  paddingTop: "0.5rem",
                }}
              >
                <ItemList>
                  <ItemLink href="mailto:support@vendar.co">
                    support@vendar.co
                  </ItemLink>
                </ItemList>
              </ItemWrapper>
            </ColDiv>
            <ColDiv>
              <ColDivTitle>Follow us</ColDivTitle>
              <ItemWrapper>
                <SocialWrapper>
                  <IconContainer
                    onClick={() => {
                      openLink("https://www.instagram.com/vendarconnect/");
                    }}
                  >
                    <InstagramAlt size={18} color="#fff" />
                  </IconContainer>
                  <IconContainer
                    onClick={() => {
                      openLink("https://web.facebook.com/VendarConnect");
                    }}
                  >
                    <Facebook size={18} color="#fff" />
                  </IconContainer>
                  <IconContainer
                    onClick={() => {
                      openLink("https://twitter.com/VendarConnect");
                    }}
                  >
                    <Twitter size={18} color="#fff" />
                  </IconContainer>
                  <IconContainer
                    onClick={() => {
                      openLink("https://www.tiktok.com/@vendarconnect");
                    }}
                  >
                    <Tiktok size={18} color="#fff" />
                  </IconContainer>
                  <IconContainer
                    onClick={() => {
                      openLink("http://www.pinterest.com/VendarConnect");
                    }}
                  >
                    <Pinterest size={18} color="#fff" />
                  </IconContainer>
                </SocialWrapper>
                <StoreWrapper>
                  <StoreImg
                    src={GooglePlayImage}
                    alt="Google Play Store"
                    onClick={() => {
                      window.open(
                        "https://play.google.com/store/apps/details?id=com.vendar",
                        "_blank"
                      );
                    }}
                  />
                  <StoreImg
                    src={AppleStoreImage}
                    alt="Apple Store"
                    onClick={() => {
                      window.open(
                        "https://apps.apple.com/us/app/vendar/id6449153439",
                        "_blank"
                      );
                    }}
                  />
                </StoreWrapper>
              </ItemWrapper>
            </ColDiv>
          </ThirdRow>
          <CopyrightFooter>
            © {new Date().getFullYear()} Vendar All Rights Reserved.
            <br />A Rhea Carrison Ltd Company.
          </CopyrightFooter>
        </FooterWrapper>
      </FooterContainer>
    </>
  );
}

export default ShopFooterCmp;
