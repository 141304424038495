import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import CategoryCardCmp from "../components/CategoryCardCmp/CategoryCardCmp";
import ShopNavbarCmp from "../components/ShopNavbarCmp/ShopNavbarCmp";
import PaginationCmp from "../components/PaginationCmp/PaginationCmp";
import ShopFooterCmp from "../components/FooterCmp/ShopFooterCmp";
import ShopServicesImg from "../assets/shop-services-img.svg";
import DeliveryImg from "../assets/delivery.svg";
import NationwideImg from "../assets/nationwide.svg";
import PhoneImg from "../assets/phone.svg";
import GooglePlayImage from "../assets/Play-White.svg";
import AppleStoreImage from "../assets/Apple-White.svg";
import TiredManImage from "../assets/tired-man.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import {
  getAllCategories,
  getNewArrivals,
  getAllPublishedAds,
  increaseAdCount,
} from "../redux/actions/shopAction";
import { connect } from "react-redux";
import SpinnerCmp from "../components/SpinnerCmp/SpinnerCmp";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade } from "swiper";
import ItemCardCmp from "../components/ItemCardCmp/ItemCardCmp";
import NumberFormat from "react-number-format";
import DeliveryDetailsDrawer from "../components/DeliveryDetailsDrawer/DeliveryDetailsDrawer";
import "swiper/css";
import "swiper/css/effect-fade";
import categoryIcons from "../utils/categoryIcons";
import { ShoppingBag } from "@styled-icons/fa-solid";

const PageWrapper = styled(Container)`
  background-color: #f8f9fb !important;
  padding: 0;
`;

const ProductDisplayWrapper = styled.div`
  /* background-color: #fff; */
  padding: 2rem 0rem;
  width: 100%;
  max-width: calc(1100px + 5rem);
  margin: 1rem auto 5rem;

  @media only screen and (max-width: 1247px) {
    padding: 2rem 1.5rem;
  }

  @media only screen and (max-width: 760px) {
    margin: 1rem auto 4rem;
    max-width: calc(1000px + 1rem);
  }

  @media only screen and (max-width: 547px) {
    margin: 1rem auto 3rem;
    padding: 0rem 1rem;
    background-color: transparent;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 2rem;
  /* css added cos of the ads section */
  padding-top: 1rem;
`;

const Title = styled.h2`
  font-weight: 800;
  font-size: 32px;
  color: #1d1e2c;
  padding-bottom: 10px;
  position: relative;
  display: inline-block;

  &::after {
    content: "";
    height: 1px;
    width: 20%;
    background: #01525f;
    position: absolute;
    left: calc(50% - 10%);
    bottom: 0;
  }

  @media only screen and (max-width: 800px) {
    font-size: 24px;
  }
`;

const AdWrapper = styled.div`
  width: 100%;
  text-align: center;
  padding-bottom: 1rem;
  cursor: pointer;
`;

const AdImage = styled(LazyLoadImage)`
  height: 100%;
  max-height: 90px;
  width: 100%;
`;

const CategoryItemsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 25px;

  @media only screen and (max-width: 531px) {
    justify-content: space-between;
  }
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 16px;
`;

const ServicesContainer = styled.div`
  display: flex;
  gap: 15px;
  padding: 1.5rem 0;
  height: 100%;
  /* max-height: 320px; */

  @media only screen and (max-width: 914px) {
    flex-direction: column;
    max-height: unset;
  }
`;

const ServiceLeft = styled.div`
  flex: 70%;
  background: url(${ShopServicesImg}), #6fabbc no-repeat;
  width: 70%;
  height: 370px;
  border-radius: 14px;
  position: relative;

  @media only screen and (max-width: 1044px) {
    flex: 75%;
    width: 75%;
  }

  @media only screen and (max-width: 914px) {
    flex: 100%;
    width: 100%;
    /* height: fit-content; */
  }
`;

const LeftTextGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  max-width: 378px;
  width: 100%;
  padding-left: 2rem;
  height: 100%;

  @media only screen and (max-width: 914px) {
    padding: 4rem 0 4rem 2rem;
  }

  @media only screen and (max-width: 560px) {
    max-width: 340px;
    padding: 3rem 0 3rem 2rem;
  }

  @media only screen and (max-width: 400px) {
    padding: 2rem 0 2rem 1rem;
    max-width: 250px;
  }
`;

const SellOnVendarText = styled.h2`
  margin: 0;
  font-weight: 600;
  font-size: 20px;
  color: #ffffff;

  @media only screen and (max-width: 400px) {
    font-size: 16px;
  }
`;

const StartText = styled.h2`
  font-weight: 800;
  font-size: 40px;
  margin: 0;
  color: #ffffff;

  @media only screen and (max-width: 700px) {
    font-size: 35px;
  }

  @media only screen and (max-width: 600px) {
    font-size: 30px;
  }

  @media only screen and (max-width: 560px) {
    font-size: 25px;
  }

  @media only screen and (max-width: 400px) {
    font-size: 20px;
  }
`;

const StoreWrapper = styled.div`
  display: flex;
  gap: 10px;
  padding-top: 20px;
  justify-content: flex-start;

  @media only screen and (max-width: 560px) {
    padding-top: 10px;
  }
`;

const StoreImg = styled(LazyLoadImage)`
  cursor: pointer;

  @media only screen and (max-width: 700px) {
    width: 100px;
  }

  @media only screen and (max-width: 450px) {
    width: 80px;
  }
`;

const TiredMan = styled(LazyLoadImage)`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 350px;
  aspect-ratio: 1.3/1;
  border-radius: 0 0 14px 0;

  @media only screen and (max-width: 700px) {
    width: 300px;
  }

  @media only screen and (max-width: 560px) {
    width: 250px;
  }

  @media only screen and (max-width: 500px) {
    width: 200px;
  }

  @media only screen and (max-width: 400px) {
    width: 180px;
  }

  @media only screen and (max-width: 374px) {
    display: none;
  }
`;

const ServiceRight = styled.div`
  flex: 30%;
  height: 320px;

  @media only screen and (max-width: 1044px) {
    flex: 25%;
  }

  @media only screen and (max-width: 914px) {
    flex: 100%;
    width: 100%;
  }
`;

const OurServicesText = styled.h2`
  text-align: left;
  font-weight: 700;
  font-size: 16px;
  color: #333333;
  background: #fff;
  border-radius: 7px;
  width: 100%;
  padding: 10px 1rem;
  margin: 0;
`;

const ServiceFeatureWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  padding-left: 1rem;
  background: #fff;
  border-radius: 7px;
  height: 100%;
  margin-top: 1rem;

  @media only screen and (max-width: 914px) {
    padding: 1rem;
  }
`;

const ServiceCmpWrapper = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
`;

const ServiceCmpIcon = styled.img`
  width: 20px;
  aspect-ratio: 1;
`;

const ServiceCmpTextWrapper = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
`;

const ServiceCmpTitle = styled.h2`
  margin: 0;
  font-weight: 700;
  font-size: 11.9973px;
  color: #3f4254;
`;

const ServiceCmpDesc = styled.p`
  margin: 0;
  font-weight: 400;
  font-size: 11.9973px;
  color: #626679;
`;

const NewArrivalsBar = styled.div`
  background: #ffffff;
  border-radius: 8px;
  max-width: 640px;
  margin: 2rem auto;
  display: flex;
  justify-content: space-between;
  padding: 1rem 5rem;

  @media only screen and (max-width: 450px) {
    padding: 1rem;
    margin: 1rem auto;
  }
`;

const NewText = styled.h2`
  font-weight: ${(props) => (props.bolden ? "700" : "500")};
  font-size: 16px;
  color: #1d1e2c;
  margin: 0;
  cursor: pointer;
  position: relative;
  display: inline-block;

  &.active {
    &::after {
      content: "";
      height: 1px;
      width: 90%;
      background: #01525f;
      position: absolute;
      left: calc(50% - 45%);
      bottom: -16px;
    }
  }

  @media only screen and (max-width: 480px) {
    font-size: 12px;
  }
`;

const ProductItemsConatiner = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 25px;

  @media only screen and (max-width: 547px) {
    position: relative;
    width: 100vw;
    left: calc(-50vw + 50%);
  }
`;

const Shop = ({
  productCategoriesError,
  productCategoriesLoading,
  productCategoriesSuccess,
  setGetAllCategories,
  setGetNewArrivals,
  setGetAllPublishedAds,
  setIncreaseAdCount,
  newArrivalsError,
  newArrivalsLoading,
  newArrivalsSuccess,
  adsLoading,
  adsSuccess,
  adsError,
}) => {
  const ServiceCmp = ({ title, desc, icon }) => {
    return (
      <>
        <ServiceCmpWrapper>
          <ServiceCmpIcon src={icon} alt="Icon" />
          <ServiceCmpTextWrapper>
            <ServiceCmpTitle>{title}</ServiceCmpTitle>
            <ServiceCmpDesc>{desc}</ServiceCmpDesc>
          </ServiceCmpTextWrapper>
        </ServiceCmpWrapper>
      </>
    );
  };

  const [pageNo, setPageNo] = useState(1);
  const navigate = useNavigate();
  const [colorsArr] = useState([
    "#FFE8EB",
    "#CCE1EE",
    "#FFE7F7",
    "#FFEDEA",
    "#EAFFF8",
    "#DFE0F9",
    "#B6D3D8",
    "#F9EABD",
  ]);
  const [currentNewProductType, setCurrentNewProductType] =
    useState("New Arrivals");
  const [openDeliveryDrawer, setOpenDeliveryDrawer] = useState(false);
  const [currentItemToAddToCart, setCurrentItemToAddToCart] = useState({});
  const [triggerWhenProductAdded, setTriggerWhenProductAdded] = useState(false);

  useEffect(() => {
    const getAllCategories = async () => {
      await setGetAllCategories(pageNo, 20);
      await setGetAllPublishedAds();
    };
    getAllCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNo]);

  useEffect(() => {
    const getNewArrivals = async () => {
      await setGetNewArrivals();
    };
    getNewArrivals();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeProductType = (type) => {
    setCurrentNewProductType(type);
  };
  return (
    <>
      <PageWrapper fluid>
        <ShopNavbarCmp logoType="Vendar" />
        <ProductDisplayWrapper>
          {adsSuccess && adsSuccess?.data?.length > 0 && (
            <Swiper
              watchOverflow={true}
              modules={[Autoplay, EffectFade]}
              slidesPerView={1}
              autoplay={{
                delay: 5000,
              }}
              fadeEffect={{ crossFade: true }}
              effect={"fade"}
              speed={1500}
              loop={true}
            >
              {adsSuccess?.data?.map((v) => {
                return (
                  <SwiperSlide>
                    <AdWrapper
                      onClick={async () => {
                        const response = await setIncreaseAdCount(v?._id);

                        if (response?.message === "Successful") {
                          window.open(v.link, "_blank");
                        }
                      }}
                    >
                      <AdImage
                        wrapperClassName="ads-image"
                        alt={v.link}
                        effect="blur"
                        src={v.adPicUrl}
                      />
                    </AdWrapper>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          )}
          <TitleWrapper>
            <Title>Browse Categories</Title>
          </TitleWrapper>

          {productCategoriesLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                padding: "1rem 0",
              }}
            >
              <SpinnerCmp
                enabled={true}
                color={"#fff"}
                size={40}
                secondaryColor={"#01525F"}
              />
            </div>
          ) : (
            <CategoryItemsContainer>
              <>
                {productCategoriesSuccess?.data?.productCategories?.map(
                  (v, i) => {
                    return (
                      <CategoryCardCmp
                        key={i}
                        name={v.name}
                        icon={
                          categoryIcons[v.slug] || (
                            <ShoppingBag
                              size={50}
                              color="rgba(29, 30, 44, 0.2)"
                            />
                          )
                        }
                        iconBgColor={colorsArr[i % colorsArr.length]}
                        onClick={() => {
                          navigate(`/categories/${v.slug}`);
                        }}
                      />
                    );
                  }
                )}
              </>
            </CategoryItemsContainer>
          )}
          {productCategoriesSuccess && (
            <PaginationWrapper>
              <PaginationCmp
                totalSize={productCategoriesSuccess?.data?.count}
                limit={20}
                pageClick={(pageNumber, pageSize) => {
                  setPageNo(pageNumber);
                }}
              />
            </PaginationWrapper>
          )}

          <ServicesContainer>
            <ServiceLeft>
              <LeftTextGroup>
                <SellOnVendarText>Sell on Vedar!</SellOnVendarText>
                <StartText>Start your business today.</StartText>
                <StoreWrapper>
                  <StoreImg
                    src={GooglePlayImage}
                    alt="Google Play Store"
                    effect="blur"
                    onClick={() => {
                      window.open(
                        "https://play.google.com/store/apps/details?id=com.vendar",
                        "_blank"
                      );
                    }}
                  />
                  <StoreImg
                    src={AppleStoreImage}
                    alt="Apple Store"
                    effect="blur"
                    onClick={() => {
                      window.open(
                        "https://apps.apple.com/us/app/vendar/id6449153439",
                        "_blank"
                      );
                    }}
                  />
                </StoreWrapper>
                <TiredMan src={TiredManImage} alt="Vendar" />
              </LeftTextGroup>
            </ServiceLeft>
            <ServiceRight>
              <OurServicesText>Our Services</OurServicesText>
              <ServiceFeatureWrapper>
                <ServiceCmp
                  icon={NationwideImg}
                  title="Nationwide Delivery"
                  desc="on all orders"
                />
                <ServiceCmp
                  icon={DeliveryImg}
                  title="Next Day Delivery"
                  desc="Lagos orders only"
                />
                <ServiceCmp
                  icon={NationwideImg}
                  title="Best Online Support"
                  desc="On all orders"
                />
                <ServiceCmp
                  icon={PhoneImg}
                  title="Return Policy"
                  desc="Easy to return"
                />
              </ServiceFeatureWrapper>
            </ServiceRight>
          </ServicesContainer>

          <NewArrivalsBar>
            <NewText
              bolden={currentNewProductType === "New Arrivals"}
              onClick={() => {
                changeProductType("New Arrivals");
              }}
              className={
                currentNewProductType === "New Arrivals" ? "active" : ""
              }
            >
              New Arrivals
            </NewText>
            <NewText
              bolden={currentNewProductType === "Trending"}
              onClick={() => {
                changeProductType("Trending");
              }}
              className={currentNewProductType === "Trending" ? "active" : ""}
            >
              Trending
            </NewText>
            <NewText
              bolden={currentNewProductType === "Top Rated"}
              onClick={() => {
                changeProductType("Top Rated");
              }}
              className={currentNewProductType === "Top Rated" ? "active" : ""}
            >
              Top Rated
            </NewText>
          </NewArrivalsBar>
          {newArrivalsLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                padding: "1rem 0",
              }}
            >
              <SpinnerCmp
                enabled={true}
                color={"#fff"}
                size={40}
                secondaryColor={"#01525F"}
              />
            </div>
          ) : (
            <ProductItemsConatiner>
              {newArrivalsSuccess?.data[
                `${
                  currentNewProductType === "New Arrivals"
                    ? "newArrivals"
                    : currentNewProductType === "Trending"
                    ? "trending"
                    : "top rated"
                }`
              ] &&
                newArrivalsSuccess?.data[
                  `${
                    currentNewProductType === "New Arrivals"
                      ? "newArrivals"
                      : currentNewProductType === "Trending"
                      ? "trending"
                      : "top rated"
                  }`
                ].map((v, i) => {
                  return (
                    <ItemCardCmp
                      quantity={v.stockQty}
                      imageUrl={v.images[0]?.url}
                      productName={v.productName}
                      description={v.productDescription}
                      slug={v.slug}
                      price={
                        <>
                          <NumberFormat
                            thousandSeparator
                            prefix="₦"
                            value={v.price}
                            decimalScale={2}
                            defaultValue="0.00"
                            allowNegative={false}
                            placeholder="0.00"
                            displayType="text"
                            disabled
                          />
                        </>
                      }
                      id={v._id}
                      key={i}
                      addToCart={() => {
                        setTriggerWhenProductAdded(!triggerWhenProductAdded);
                        if (localStorage.getItem("detailsSaved")) {
                          const orders = JSON.parse(
                            localStorage.getItem("orders")
                          );
                          if (orders) {
                            localStorage.setItem(
                              "orders",
                              JSON.stringify([
                                ...orders,
                                {
                                  ...v,
                                  quantity: 1,
                                  totalPrice: parseFloat(v?.price),
                                  userId: v?.userId?._id,
                                  state: v?.userId?.state,
                                  storeName: v?.userId?.storeName,
                                },
                              ])
                            );
                          } else {
                            localStorage.setItem(
                              "orders",
                              JSON.stringify([
                                {
                                  ...v,
                                  quantity: 1,
                                  totalPrice: parseFloat(v?.price),
                                  userId: v?.userId?._id,
                                  state: v?.userId?.state,
                                  storeName: v?.userId?.storeName,
                                },
                              ])
                            );
                          }
                        } else {
                          localStorage.setItem(
                            "storeDetails",
                            JSON.stringify({
                              state: v?.userId?.state,
                            })
                          );
                          setCurrentItemToAddToCart({
                            ...v,
                            quantity: 1,
                            totalPrice: parseFloat(v?.price),
                            userId: v?.userId?._id,
                            state: v?.userId?.state,
                            storeName: v?.userId?.storeName,
                          });
                          setOpenDeliveryDrawer(true);
                        }
                      }}
                      triggerWhenProductAdded={triggerWhenProductAdded}
                      onParentClick={() => {
                        navigate(`/p/${v.userId?.storeName}/${v.slug}`);
                      }}
                    />
                  );
                })}
            </ProductItemsConatiner>
          )}
        </ProductDisplayWrapper>
        <ShopFooterCmp />
      </PageWrapper>
      <DeliveryDetailsDrawer
        isOpen={openDeliveryDrawer}
        onClose={() => {
          setTriggerWhenProductAdded(!triggerWhenProductAdded);
          setOpenDeliveryDrawer(false);
        }}
        currentItemToAddToCart={currentItemToAddToCart}
        setCurrentItemToAddToCart={setCurrentItemToAddToCart}
      />
    </>
  );
};

const mapState = (state) => ({
  productCategoriesLoading: state.shop.getCategoriesLoading,
  productCategoriesError: state.shop.getCategoriesError,
  productCategoriesSuccess: state.shop.getCategoriesSuccess,
  newArrivalsLoading: state.shop.getNewArrivalsLoading,
  newArrivalsError: state.shop.getNewArrivalsError,
  newArrivalsSuccess: state.shop.getNewArrivalsSuccess,
  adsLoading: state.shop.getAdsLoading,
  adsSuccess: state.shop.getAdsSuccess,
  adsError: state.shop.getAdsError,
});

const mapDispatch = (dispatch) => ({
  setGetAllCategories: (page, limit) => dispatch(getAllCategories(page, limit)),
  setGetNewArrivals: () => dispatch(getNewArrivals()),
  setGetAllPublishedAds: () => dispatch(getAllPublishedAds()),
  setIncreaseAdCount: (adId) => dispatch(increaseAdCount(adId)),
});

export default connect(mapState, mapDispatch)(Shop);
