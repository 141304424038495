import React from "react";
import styled from "styled-components";
import EmptyCartIcon from "../../assets/empty-cart.svg";
import SolidButton from "../Buttons/SolidButton";

const ErrorContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const ErrorWrapper = styled.div`
  text-align: center;
  width: 100%;
  max-width: 600px;
  border-radius: 10px;
  margin: 0 1rem;
  background-color: #fff;
  padding: 2rem 1rem;
`;

const EmptyCart = styled.img``;

const Text = styled.p`
  max-width: 400px;
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  margin: 0 auto;
  color: #636e72;
  padding: 5px 0;
`;

const EmptyCmp = ({ firstText, secondText, buttonText, buttonAction }) => {
  return (
    <>
      <ErrorContainer>
        {" "}
        <ErrorWrapper>
          <EmptyCart src={EmptyCartIcon} alt="Cart" />
          {firstText && <Text>{firstText}</Text>}
          {secondText && <Text>{secondText}</Text>}
          {buttonText && (
            <>
              <SolidButton
                onClick={buttonAction}
                text={buttonText}
                type="submit"
                weighty="700"
                textFontSize="16px"
                specifyPadding="1rem 1rem"
                color="#fff"
                borderRadius="11px"
                backColor="#01525F"
                backgroundHoverColor="#01525F"
                textHoverColor="#fff"
                widthWebkit="fit-content"
                widthMoz="fit-content"
                widthNormal="fit-content"
                margin="10px 0 0 0"
              />
            </>
          )}
        </ErrorWrapper>
      </ErrorContainer>
    </>
  );
};

export default EmptyCmp;
