import { CloseOutline } from "@styled-icons/evaicons-outline";
import { Formik, useFormikContext } from "formik";
import React from "react";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import styled from "styled-components";
import deliveryDetailsValidatorSchema from "../../validators/deliveryDetailsValidator";
import SolidButton from "../Buttons/SolidButton";
import CustomInputCmp from "../InputsCmp/CustomInputCmp";
import CustomInputSelectCmp from "../InputsCmp/CustomInputSelectCmp";
import CustomPhoneCmp from "../InputsCmp/CustomPhoneInputCmp";
import { parsePhoneNumber } from "awesome-phonenumber";
import "./DeliveryDetailsDrawer.css";
import CustomCheckboxCmp from "../InputsCmp/CustomCheckboxCmp";
import { useState } from "react";
import {
  statesOfNigeria,
  statesOfNigeriaMoreDetail,
} from "../../constants/states";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { checkAllStates } from "../../utils/checkAllStates";

const Wrapper = styled.div`
  padding: 2rem 1rem;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.h2`
  font-weight: 800;
  font-size: 20px;
  color: #1d1e2c;
`;

const CloseIcon = styled(CloseOutline)``;

const Desc = styled.p`
  font-weight: 400;
  font-size: 14px;
  color: #7b7b7b;
`;

const InputWrapper = styled.div`
  padding-bottom: 15px;
`;

const DeliveryDetailsWrapper = styled.div`
  padding: 1rem 0;
`;

const DeliveryTitle = styled.h2`
  font-weight: 700;
  font-size: 16px;
  color: #1d1e2c;
`;

const Line = styled.hr`
  width: 100%;
  height: 1px;
  color: #dfe6e9;
  border-top: 1px solid #dfe6e9;
`;

const InputLabel = styled.h2`
  font-weight: 500;
  font-size: 14px;
  color: #6c6c74;
`;

const FormWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

const ButtonWrapper = styled.div``;

const CheckBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const DeliveryDetailsDrawer = ({
  isOpen,
  onClose,
  currentItemToAddToCart,
  setCurrentItemToAddToCart,
}) => {
  const [checkedState, setCheckedState] = useState(false);
  const [checkedValue, setCheckedValue] = useState("");
  const [deliveryDetails] = useState(
    JSON.parse(localStorage.getItem("deliveryDetails"))
      ? JSON.parse(localStorage.getItem("deliveryDetails"))
      : {}
  );

  useEffect(() => {
    const deliveryDetails = JSON.parse(localStorage.getItem("deliveryDetails"));
    if (deliveryDetails) {
      if (
        localStorage.getItem("orders") &&
        JSON.parse(localStorage.getItem("orders")) &&
        checkAllStates(
          JSON.parse(localStorage.getItem("orders")),
          deliveryDetails.state
        )
      ) {
        setCheckedValue(deliveryDetails?.deliveryType);
        setCheckedState(true);
        localStorage.setItem(
          "deliveryDetails",
          JSON.stringify({ ...deliveryDetails })
        );
      } else {
        setCheckedValue("Standard");
        setCheckedState(true);
        localStorage.setItem(
          "deliveryDetails",
          JSON.stringify({ ...deliveryDetails, deliveryType: "Standard" })
        );
      }
    } else {
      setCheckedValue("");
      setCheckedState(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const SwitchDelivery = () => {
    const { setFieldValue } = useFormikContext();
    useEffect(() => {
      setCheckedState(true);
      setCheckedValue("Standard");
      setFieldValue("deliveryType", "Standard");
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return null;
  };
  return (
    <>
      <Drawer
        open={isOpen}
        onClose={onClose}
        direction="right"
        size={"400px"}
        zIndex={1001}
        style={{ maxWidth: "400px", width: "100%", overflowX: "scroll" }}
        className="delivery-details"
      >
        <Wrapper>
          <TitleWrapper>
            <Title>Where do we Deliver to?</Title>
            <CloseIcon color="#1D1E2C" size={"20px"} onClick={onClose} />
          </TitleWrapper>
          <Desc>Enter state of delivery to get you delivery charge</Desc>

          <Formik
            validationSchema={deliveryDetailsValidatorSchema}
            enableReinitialize={false}
            isInitialValid={true}
            initialValues={{
              email: deliveryDetails?.email,
              phone: deliveryDetails?.phone,
              lastName: deliveryDetails?.lastName,
              firstName: deliveryDetails?.firstName,
              state: deliveryDetails?.state,
              city: deliveryDetails?.city,
              address: deliveryDetails?.address,
              deliveryType: deliveryDetails?.deliveryType,
            }}
            onSubmit={async (values, { resetForm }) => {
              const fullPhoneNumber = parsePhoneNumber(values.phone);
              localStorage.setItem("detailsSaved", true);
              localStorage.setItem(
                "deliveryDetails",
                JSON.stringify({
                  ...values,
                  fullPhoneNumber: fullPhoneNumber.number.e164,
                })
              );
              onClose();
              if (currentItemToAddToCart) {
                const orders = JSON.parse(localStorage.getItem("orders"));
                if (orders) {
                  localStorage.setItem(
                    "orders",
                    JSON.stringify([...orders, currentItemToAddToCart])
                  );
                } else {
                  localStorage.setItem(
                    "orders",
                    JSON.stringify([currentItemToAddToCart])
                  );
                }
                setCurrentItemToAddToCart({});
                toast.success("Added to cart");
              }
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              setFieldValue,
              values,
              touched,
              isValid,
              errors,
            }) => (
              <>
                <form noValidate onSubmit={handleSubmit}>
                  <InputWrapper>
                    <CustomInputSelectCmp
                      required={true}
                      name="state"
                      values={values}
                      onChange={(e) => {
                        handleChange(e);
                        setCheckedState(false);
                        setCheckedValue("");
                        setFieldValue("deliveryType", "");
                      }}
                      placeholder="Select state"
                      data={statesOfNigeria}
                      message={`${errors["state"] ? errors["state"] : ""}`}
                      showMessage={false}
                    />
                  </InputWrapper>
                  <InputWrapper>
                    <CheckBoxWrapper>
                      {(localStorage.getItem("orders") &&
                        JSON.parse(localStorage.getItem("orders")) &&
                        checkAllStates(
                          JSON.parse(localStorage.getItem("orders")),
                          values.state
                        )) ||
                      ["lagos", "rivers", "oyo"].includes(
                        values.state?.toLowerCase()
                      ) ? (
                        <CustomCheckboxCmp
                          id="Express"
                          handleChange={() => {
                            setCheckedState(true);
                            setCheckedValue("Express");
                            setFieldValue("deliveryType", "Express");
                          }}
                          isInvalid={!checkedState}
                          checked={checkedValue === "Express" ? true : false}
                          title="Express Shipping"
                          desc="1-2 business days"
                        />
                      ) : (
                        <SwitchDelivery />
                      )}

                      <CustomCheckboxCmp
                        id="Standard"
                        handleChange={() => {
                          setCheckedState(true);
                          setCheckedValue("Standard");
                          setFieldValue("deliveryType", "Standard");
                          setFieldValue("city", "");
                        }}
                        checked={checkedValue === "Standard" ? true : false}
                        isInvalid={!checkedState}
                        title="Standard Shipping"
                        desc="2-5 business days"
                      />
                    </CheckBoxWrapper>
                  </InputWrapper>
                  <DeliveryDetailsWrapper>
                    <DeliveryTitle>Add delivery details</DeliveryTitle>
                    <Line />
                    <InputWrapper>
                      <InputLabel>First Name</InputLabel>
                      <CustomInputCmp
                        value={values.firstName}
                        onChange={handleChange}
                        name="firstName"
                        placeholder="Enter first name"
                        message={`${
                          errors["firstName"] ? errors["firstName"] : ""
                        }`}
                      />
                    </InputWrapper>
                    <InputWrapper>
                      <InputLabel>Last Name</InputLabel>
                      <CustomInputCmp
                        value={values.lastName}
                        onChange={handleChange}
                        name="lastName"
                        placeholder="Enter last name"
                        message={`${
                          errors["lastName"] ? errors["lastName"] : ""
                        }`}
                      />
                    </InputWrapper>
                    <InputWrapper>
                      <InputLabel>Phone Number</InputLabel>
                      <CustomPhoneCmp
                        value={values.phone}
                        onChange={(value, country, e, formattedValue) => {
                          setFieldValue("phone", formattedValue);
                        }}
                        name="phone"
                        placeholder="Enter Phone number"
                        message={`${errors["phone"] ? errors["phone"] : ""}`}
                      />
                    </InputWrapper>
                    <InputWrapper>
                      <InputLabel>Email</InputLabel>
                      <CustomInputCmp
                        value={values.email}
                        onChange={handleChange}
                        name="email"
                        placeholder="Enter email"
                        message={`${errors["email"] ? errors["email"] : ""}`}
                      />
                    </InputWrapper>
                    <FormWrapper>
                      <InputWrapper style={{ width: "100%" }}>
                        <InputLabel>State</InputLabel>
                        <CustomInputSelectCmp
                          required={true}
                          name="state"
                          values={values}
                          onChange={handleChange}
                          placeholder="Select state"
                          data={statesOfNigeria}
                          message={`${errors["state"] ? errors["state"] : ""}`}
                          showMessage={false}
                          disabled={true}
                        />
                      </InputWrapper>
                      <InputWrapper style={{ width: "100%" }}>
                        <InputLabel>City</InputLabel>
                        <CustomInputSelectCmp
                          required={true}
                          name="city"
                          values={values}
                          onChange={handleChange}
                          placeholder="Select city"
                          data={
                            values.state
                              ? statesOfNigeriaMoreDetail.filter((v) => {
                                  return v.name === values.state;
                                })[0]?.lgas
                              : []
                          }
                          message={`${errors["city"] ? errors["city"] : ""}`}
                          showMessage={false}
                        />
                      </InputWrapper>
                    </FormWrapper>
                    <InputWrapper>
                      <InputLabel>Delivery Address</InputLabel>
                      <CustomInputCmp
                        value={values.address}
                        onChange={handleChange}
                        name="address"
                        placeholder="Enter delivery address"
                        message={`${
                          errors["address"] ? errors["address"] : ""
                        }`}
                      />
                    </InputWrapper>
                    <ButtonWrapper>
                      <SolidButton
                        text={"Submit"}
                        type="submit"
                        weighty="700"
                        textFontSize="16px"
                        specifyPadding="1rem 1rem"
                        color="#fff"
                        borderRadius="11px"
                        backColor="#01525F"
                        backgroundHoverColor="#01525F"
                        textHoverColor="#fff"
                        widthWebkit="100%"
                        widthMoz="100%"
                        widthNormal="100%"
                        margin="0"
                      />
                    </ButtonWrapper>
                  </DeliveryDetailsWrapper>
                </form>
              </>
            )}
          </Formik>
        </Wrapper>
      </Drawer>
    </>
  );
};

export default DeliveryDetailsDrawer;
