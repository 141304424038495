import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import TawkTo from "tawkto-react";

function ChatCmp() {
  const location = useLocation();

  useEffect(() => {
    var tawk = new TawkTo(
      process.env.REACT_APP_TAWK_PROPERTY_ID,
      process.env.REACT_APP_TAWK_ID,
      500
    );
    tawk.hideWidget();
    if (
      !location.pathname.includes("/shop") ||
      !location.pathname.includes("/p")
    ) {
      tawk.showWidget();
    } else {
      tawk.hideWidget();
    }
  }, [location.pathname]);
  return <></>;
}

export default ChatCmp;
