import { toast } from "react-toastify";
import axios from "axios";
import {
  ACCEPT_RETURN_ORDER_ERROR,
  ACCEPT_RETURN_ORDER_LOADING,
  ACCEPT_RETURN_ORDER_SUCCESS,
  FILL_ACCEPT_RETURN_FORM_ERROR,
  FILL_ACCEPT_RETURN_FORM_LOADING,
  FILL_ACCEPT_RETURN_FORM_SUCCESS,
  GET_REVIEW_ORDER_ERROR,
  GET_REVIEW_ORDER_LOADING,
  GET_REVIEW_ORDER_SUCCESS,
  RATE_ORDER_ERROR,
  RATE_ORDER_LOADING,
  RATE_ORDER_SUCCESS,
  VERIFY_DELIVERY_CODE_ERROR,
  VERIFY_DELIVERY_CODE_LOADING,
  VERIFY_DELIVERY_CODE_SUCCESS,
  VERIFY_TRACKING_ID_ERROR,
  VERIFY_TRACKING_ID_LOADING,
  VERIFY_TRACKING_ID_SUCCESS,
} from "../reducers/types";
import { apiBaseUrl } from "../../constants/url";

export const getReviewOrder = (reviewOrderId) => async (dispatch) => {
  dispatch({ type: GET_REVIEW_ORDER_LOADING, payload: true });
  dispatch({ type: GET_REVIEW_ORDER_ERROR, payload: null });
  try {
    const response = await axios.get(
      `${apiBaseUrl}/order/get/review-order/${reviewOrderId}`
    );
    dispatch({ type: GET_REVIEW_ORDER_LOADING, payload: null });

    if (response.data.status !== 200) {
      return toast.error(response.data.message);
    }
    dispatch({ type: GET_REVIEW_ORDER_SUCCESS, payload: response.data });
    //   return response.data;
  } catch (err) {
    dispatch({ type: GET_REVIEW_ORDER_LOADING, payload: null });

    //   if (err.response.status === 422) {
    //     return toast.error(`Validation error`);
    //   }
    if (err.response.status === 422) {
      dispatch({
        type: GET_REVIEW_ORDER_ERROR,
        payload: err.response.data?.errors[0]?.reviewOrderId,
      });
    } else {
      return toast.error(err.response?.data?.message);
    }
  }
};

export const acceptReturnOrder = (reviewOrderId, data) => async (dispatch) => {
  dispatch({ type: ACCEPT_RETURN_ORDER_LOADING, payload: true });
  dispatch({ type: ACCEPT_RETURN_ORDER_ERROR, payload: null });
  try {
    const response = await axios.post(
      `${apiBaseUrl}/order/accept-order/${reviewOrderId}`,
      data
    );
    dispatch({ type: ACCEPT_RETURN_ORDER_LOADING, payload: null });

    if (response.data.status === 400 && response.data.status !== 422) {
      dispatch({
        type: ACCEPT_RETURN_ORDER_ERROR,
        payload: response?.data?.message,
      });
    }
    dispatch({
      type: ACCEPT_RETURN_ORDER_SUCCESS,
      payload: response.data,
    });
    // toast.success(`Successful`);
    return response.data;
  } catch (err) {
    dispatch({ type: ACCEPT_RETURN_ORDER_LOADING, payload: null });

    //   if (err.response.status === 422) {
    //     return toast.error(`Validation error`);
    //   }
    if (err.response.status === 422) {
      dispatch({
        type: ACCEPT_RETURN_ORDER_ERROR,
        payload: err.response?.data?.errors[0]?.isAccepted,
      });
      return toast.error(`Validation error`);
    } else {
      dispatch({
        type: ACCEPT_RETURN_ORDER_ERROR,
        payload: err.response?.data?.message,
      });
      return toast.error(`Validation error`);
    }
  }
};

export const rateOrder = (reviewOrderId, data) => async (dispatch) => {
  dispatch({ type: RATE_ORDER_LOADING, payload: true });
  dispatch({ type: RATE_ORDER_ERROR, payload: null });
  try {
    const response = await axios.post(
      `${apiBaseUrl}/order/rate/${reviewOrderId}`,
      data
    );
    dispatch({ type: RATE_ORDER_LOADING, payload: null });

    if (response.data.status === 400 && response.data.status !== 422) {
      dispatch({
        type: RATE_ORDER_ERROR,
        payload: response?.data?.message,
      });
    }
    dispatch({
      type: RATE_ORDER_SUCCESS,
      payload: response.data,
    });
    toast.success(`Order rated successfully`, { toastId: "order-rating" });
    return response.data;
  } catch (err) {
    dispatch({ type: RATE_ORDER_LOADING, payload: null });

    if (err.response.status === 422) {
      dispatch({
        type: RATE_ORDER_ERROR,
        payload:
          err.response?.data?.errors[0]?.reviewOrderId ||
          err.response?.data?.errors[0]?.rating,
      });
      toast.error(
        err.response?.data?.errors[0]?.reviewOrderId ||
          err.response?.data?.errors[0]?.rating,
        { toastId: "order-rating" }
      );
    } else {
      dispatch({
        type: RATE_ORDER_ERROR,
        payload: err.response?.data?.message,
      });
    }
  }
};

export const fillAcceptReturnForm = (data) => async (dispatch) => {
  dispatch({ type: FILL_ACCEPT_RETURN_FORM_LOADING, payload: true });
  dispatch({ type: FILL_ACCEPT_RETURN_FORM_ERROR, payload: null });
  try {
    const response = await axios.post(`${apiBaseUrl}/order/fill-form`, data);
    dispatch({ type: FILL_ACCEPT_RETURN_FORM_LOADING, payload: null });

    if (response.data.status === 400 && response.data.status !== 422) {
      dispatch({
        type: FILL_ACCEPT_RETURN_FORM_ERROR,
        payload: response?.data?.message,
      });
    }
    dispatch({
      type: FILL_ACCEPT_RETURN_FORM_SUCCESS,
      payload: response.data,
    });

    return response.data;
  } catch (err) {
    dispatch({ type: FILL_ACCEPT_RETURN_FORM_LOADING, payload: null });

    //   if (err.response.status === 422) {
    //     return toast.error(`Validation error`);
    //   }
    if (err.response.status === 422) {
      dispatch({
        type: FILL_ACCEPT_RETURN_FORM_ERROR,
        payload: err.response?.data?.errors[0]?.reviewOrderId,
      });
    } else {
      dispatch({
        type: FILL_ACCEPT_RETURN_FORM_ERROR,
        payload: err.response?.data?.message,
      });
    }
  }
};

export const verifyTrackingId = (data) => async (dispatch) => {
  dispatch({ type: VERIFY_TRACKING_ID_LOADING, payload: true });
  dispatch({ type: VERIFY_TRACKING_ID_ERROR, payload: null });
  try {
    const response = await axios.post(
      `${apiBaseUrl}/delivery/verify-tracking-id`,
      { trackingId: data }
    );
    dispatch({ type: VERIFY_TRACKING_ID_LOADING, payload: null });

    if (response.data.status === 400 && response.data.status !== 422) {
      dispatch({
        type: VERIFY_TRACKING_ID_ERROR,
        payload: response?.data?.message,
      });
    }
    dispatch({
      type: VERIFY_TRACKING_ID_SUCCESS,
      payload: response.data,
    });

    return response.data;
  } catch (err) {
    dispatch({ type: VERIFY_TRACKING_ID_LOADING, payload: null });

    if (err.response.status === 422) {
      dispatch({
        type: VERIFY_TRACKING_ID_ERROR,
        payload: err.response?.data?.errors[0]?.trackingId,
      });
    } else {
      dispatch({
        type: VERIFY_TRACKING_ID_ERROR,
        payload: err.response?.data?.message,
      });
    }
  }
};

export const verifyDeliveryAccessCode = (data) => async (dispatch) => {
  dispatch({ type: VERIFY_DELIVERY_CODE_LOADING, payload: true });
  dispatch({ type: VERIFY_DELIVERY_CODE_ERROR, payload: null });
  try {
    const response = await axios.post(
      `${apiBaseUrl}/delivery/verify-code`,
      data
    );
    dispatch({ type: VERIFY_DELIVERY_CODE_LOADING, payload: null });

    if (response.data.status === 400 && response.data.status !== 422) {
      dispatch({
        type: VERIFY_DELIVERY_CODE_ERROR,
        payload: response?.data?.message,
      });
      return toast.error(response?.data?.message);
    } else {
      dispatch({
        type: VERIFY_DELIVERY_CODE_SUCCESS,
        payload: response.data,
      });
      return response.data;
    }
  } catch (err) {
    dispatch({ type: VERIFY_DELIVERY_CODE_LOADING, payload: null });

    if (err.response.status === 422) {
      dispatch({
        type: VERIFY_DELIVERY_CODE_ERROR,
        payload: err.response?.data?.errors[0]?.accessCode,
      });

      toast.error(err.response?.data?.errors[0]?.accessCode);
    } else {
      dispatch({
        type: VERIFY_DELIVERY_CODE_ERROR,
        payload: err.response?.data?.message,
      });

      toast.error(err.response?.data?.message);
    }
  }
};
