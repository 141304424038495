import React, { useState } from "react";
import styled from "styled-components";
import { useDropzone } from "react-dropzone";

const FileWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0.8rem 1rem;
  border: ${(props) =>
    props.message ? "1px solid red" : "1px dashed #ADB5BD"};
  background: #fff;
  border-radius: 8px;
  width: 100%;
`;

// const IconWrapper = styled.div`
//   padding-right: 1rem;
// `;

// const UploadIcon = styled(LazyLoadImage)``;

const PlaceholderText = styled.h2`
  color: #374151;
  font-weight: 400;
  font-size: 14px;
  margin: 0;
`;

const ErrorMessageWrapper = styled.div`
  text-align: left;
`;

const ErrorMessage = styled.p`
  color: red;
  font-weight: 300;
  font-size: 13px;
  padding: 0;
  padding-top: 0.5rem;
  margin: 0;
`;

function CustomFileCmp(props) {
  const [file, setFile] = useState();
  const { getRootProps, getInputProps } = useDropzone({
    accept: props.accept,
    maxSize: props.maxSize,
    maxFiles: props.maxFiles,
    onDrop: (acceptedFiles) => {
      props.setFieldValue("image", acceptedFiles);
      setFile(acceptedFiles);
      const preview = URL.createObjectURL(acceptedFiles[0]);
      if (props.customFn) {
        props.customFn(preview);
      }
      // console.log(acceptedFiles, props.message);
    },
  });
  return (
    <>
      <FileWrapper
        message={props.message}
        {...getRootProps({ className: "jessie-upload" })}
        onChange={props.onChange}
      >
        <PlaceholderText>
          {file && file ? file[0]?.name : props.placeholder}
        </PlaceholderText>
        <input {...getInputProps()} />
      </FileWrapper>
      <ErrorMessageWrapper>
        <ErrorMessage>{props.showMessage ? props.message : ""}</ErrorMessage>
      </ErrorMessageWrapper>
    </>
  );
}

export default CustomFileCmp;
