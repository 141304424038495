import React, { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import styled from "styled-components";
import LogoImg from "../../assets/logo.svg";
import SolidButton from "../Buttons/SolidButton";
import NigeriaSvg from "../../assets/nigeria.svg";
import { MenuAlt4 } from "@styled-icons/heroicons-outline/MenuAlt4";
import SideDrawerMobile from "./SideDrawerMobile";
import DownloadModal from "../Modals/DownloadModal";
import Dropdown from "rc-dropdown";
import DropdownCmp from "../DropdownCmp/DropdownCmp";
import "rc-dropdown/assets/index.css";
import { useNavigate } from "react-router-dom";

const NavbarContainer = styled.div`
  background: ${(props) =>
    props.navBarState || props.type
      ? "rgba(245, 246, 248, 0.8)"
      : "transparent"};
  backdrop-filter: blur(3px);
  height: 80px;
  /* margin-top: -80px; */
  display: flex;
  align-items: center;
  padding: 0 3rem;

  /* position: relative; */
  position: sticky;
  top: 0;
  z-index: 10;
  transition: 0.8s all ease;

  @media only screen and (max-width: 500px) {
    padding: 0 1rem;
  }
`;

const NavbarWrapper = styled.div`
  width: 100%;
  max-width: calc(1100px + 3rem);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;

  @media only screen and (max-width: 500px) {
    max-width: calc(1000px + 1rem);
  }
`;

const LogoAndMenuHolder = styled.div`
  display: flex;
  gap: 60px;
  align-items: center;
`;

const Logo = styled(LazyLoadImage)`
  cursor: pointer;
`;

const GroupLink1 = styled.div`
  display: flex;
  gap: 32px;

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const MenuLink = styled.p`
  margin: 0;
  font-weight: 600;
  font-size: 16px;
  color: #1d1e2c;
  cursor: pointer;
`;

const MenuAndButtonHolder = styled.div`
  display: flex;
  gap: 60px;
  align-items: center;
`;

const ButtonFlagHolder = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;

  @media only screen and (max-width: 768px) {
    gap: 15px;
  }
`;

const FlagImage = styled.img`
  @media only screen and (max-width: 920px) {
    display: none;
  }

  @media only screen and (max-width: 768px) {
    display: unset;
  }
`;

const ButtonWrapper = styled.div`
  @media only screen and (max-width: 880px) {
    display: none;
  }
`;

const HamburgerMenu = styled(MenuAlt4)`
  display: none;
  cursor: pointer;

  @media only screen and (max-width: 768px) {
    display: unset;
  }
`;

const NavbarCmp = () => {
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const [navbarStateScroll, setNavbarStateScroll] = useState(false);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const navigate = useNavigate();

  const changeBackgroundColor = () => {
    if (window.scrollY >= 10) {
      setNavbarStateScroll(true);
    } else {
      setNavbarStateScroll(false);
    }
  };

  window.addEventListener("scroll", changeBackgroundColor);

  const [, setSellVisible] = useState();
  const [, setMarketVisible] = useState();
  const [, setManageVisible] = useState();
  const [, setLearnVisible] = useState();

  return (
    <>
      <NavbarContainer navBarState={navbarStateScroll}>
        <NavbarWrapper>
          <LogoAndMenuHolder>
            <Logo
              src={LogoImg}
              alt="Vendar"
              onClick={() => {
                navigate("/");
              }}
            />
            <GroupLink1>
              <Dropdown
                trigger={["click"]}
                overlay={
                  <DropdownCmp
                    title="Sell anywhere"
                    desc="Sell around the world on a website, social media,  and anywhere else that strikes your fancy."
                    leftArr={[
                      { text: "Website", url: "/website" },
                      { text: "Google", url: "/google" },
                      { text: "Twitter", url: "/twitter" },
                    ]}
                    rightArr={[
                      { text: "Instagram", url: "/instagram" },
                      { text: "TikTok", url: "/tiktok" },
                      { text: "Facebook", url: "/facebook" },
                    ]}
                  />
                }
                animation="slide-up"
                overlayStyle={{
                  fontFamily: '"Mulish", sans-serif',
                  zIndex: "2000",
                  width: "fit-content",
                  top: "75px",
                }}
                onVisibleChange={(visible) => {
                  setSellVisible(visible);
                }}
              >
                <MenuLink>Sell</MenuLink>
              </Dropdown>
              <Dropdown
                trigger={["click"]}
                overlay={
                  <DropdownCmp
                    title="Market your business"
                    desc="Marketing your business has never been easier. Google advertising, Facebook advertising, and more."
                    leftArr={[
                      { text: "Google Ads", url: "/website" },
                      { text: "Facebook Ads", url: "/google" },
                      { text: "Marketing apps", url: "/twitter" },
                    ]}
                  />
                }
                animation="slide-up"
                overlayStyle={{
                  fontFamily: '"Mulish", sans-serif',
                  zIndex: "2000",
                  width: "fit-content",
                  top: "75px",
                }}
                onVisibleChange={(visible) => {
                  setMarketVisible(visible);
                }}
              >
                <MenuLink>Market</MenuLink>
              </Dropdown>
              <Dropdown
                trigger={["click"]}
                overlay={
                  <DropdownCmp
                    title="Manage your store"
                    desc="Simplicity at its finest."
                    leftArr={[
                      { text: "Google Ads", url: "/website" },
                      { text: "Facebook Ads", url: "/google" },
                      { text: "Marketing apps", url: "/twitter" },
                    ]}
                  />
                }
                animation="slide-up"
                overlayStyle={{
                  fontFamily: '"Mulish", sans-serif',
                  zIndex: "2000",
                  width: "fit-content",
                  top: "75px",
                }}
                onVisibleChange={(visible) => {
                  setManageVisible(visible);
                }}
              >
                <MenuLink>Manage</MenuLink>
              </Dropdown>
            </GroupLink1>
          </LogoAndMenuHolder>
          <MenuAndButtonHolder>
            <GroupLink1>
              <Dropdown
                trigger={["click"]}
                overlay={
                  <DropdownCmp
                    title="Learn"
                    desc="Marketing your business has never been easier. Google advertising, Facebook advertising, and more."
                    leftArr={[
                      { text: "Customer support", url: "/website" },
                      { text: "Help Center", url: "/google" },
                      { text: "Blog", url: "/twitter" },
                    ]}
                  />
                }
                animation="slide-up"
                overlayStyle={{
                  fontFamily: '"Mulish", sans-serif',
                  zIndex: "2000",
                  width: "fit-content",
                  top: "75px",
                }}
                onVisibleChange={(visible) => {
                  setLearnVisible(visible);
                }}
              >
                <MenuLink>Learn</MenuLink>
              </Dropdown>
              <MenuLink>Pricing</MenuLink>
              <MenuLink
                onClick={() => {
                  window.open("https://blog.vendar.co", "_blank");
                }}
              >
                Blog
              </MenuLink>
            </GroupLink1>
            <ButtonFlagHolder>
              <ButtonWrapper>
                <SolidButton
                  onClick={() => {
                    setShowDownloadModal(true);
                  }}
                  text={"Download App"}
                  type="submit"
                  weighty="700"
                  textFontSize="16px"
                  specifyPadding="1rem"
                  color="#fff"
                  borderRadius="11px"
                  backColor="#01525F"
                  backgroundHoverColor="#01525F"
                  textHoverColor="#fff"
                  widthWebkit="170px"
                  widthMoz="170px"
                  widthNormal="170px"
                />
              </ButtonWrapper>
              <FlagImage src={NigeriaSvg} alt="Nigeria" />
              <HamburgerMenu
                color="#1D1E2C"
                size={"20px"}
                onClick={() => {
                  setOpenMobileMenu(true);
                }}
              />
            </ButtonFlagHolder>
          </MenuAndButtonHolder>
        </NavbarWrapper>
      </NavbarContainer>
      <SideDrawerMobile
        isOpen={openMobileMenu}
        onClose={() => {
          setOpenMobileMenu(false);
        }}
      />
      <DownloadModal
        maxWidth="700px"
        wrapperPadding={"0rem 2rem 2rem"}
        showCloseIcon={true}
        isOpen={showDownloadModal}
        onRequestClose={() => {
          setShowDownloadModal(false);
        }}
      />
    </>
  );
};

export default NavbarCmp;
