import axios from "axios";
import { apiBaseUrl } from "../../constants/url";
import {
  GET_A_MARKETER_ERROR,
  GET_A_MARKETER_LOADING,
  GET_A_MARKETER_SUCCESS,
  SUBMIT_SHOP_DETAILS_ERROR,
  SUBMIT_SHOP_DETAILS_LOADING,
  SUBMIT_SHOP_DETAILS_SUCCESS,
} from "../reducers/types";

export const getAMarketer = (uniqueId) => async (dispatch) => {
  dispatch({ type: GET_A_MARKETER_LOADING, payload: true });
  dispatch({ type: GET_A_MARKETER_ERROR, payload: null });
  try {
    const response = await axios.get(
      `${apiBaseUrl}/admin/marketer/view/${uniqueId}`
    );
    dispatch({ type: GET_A_MARKETER_LOADING, payload: null });

    if (response.data.status === 400 && response.data.status !== 422) {
      dispatch({
        type: GET_A_MARKETER_ERROR,
        payload: response?.data?.message,
      });
    }
    dispatch({
      type: GET_A_MARKETER_SUCCESS,
      payload: response.data,
    });

    return response.data;
  } catch (err) {
    dispatch({ type: GET_A_MARKETER_LOADING, payload: null });

    if (err.response.status === 422) {
      dispatch({
        type: GET_A_MARKETER_ERROR,
        payload: err.response?.data?.errors[0].uniqueId,
      });
    } else {
      dispatch({
        type: GET_A_MARKETER_ERROR,
        payload: err.response?.data?.message,
      });
    }
  }
};

export const submitShopDetails = (data, marketerId) => async (dispatch) => {
  dispatch({ type: SUBMIT_SHOP_DETAILS_LOADING, payload: true });
  dispatch({ type: SUBMIT_SHOP_DETAILS_ERROR, payload: null });
  try {
    const response = await axios.post(
      `${apiBaseUrl}/admin/marketer/submit/${marketerId}`,
      data
    );
    dispatch({ type: SUBMIT_SHOP_DETAILS_LOADING, payload: null });

    if (response.data.status === 400 && response.data.status !== 422) {
      dispatch({
        type: SUBMIT_SHOP_DETAILS_ERROR,
        payload: response?.data?.message,
      });
    }
    dispatch({
      type: SUBMIT_SHOP_DETAILS_SUCCESS,
      payload: response.data,
    });

    return response.data;
  } catch (err) {
    dispatch({ type: SUBMIT_SHOP_DETAILS_LOADING, payload: null });

    if (err.response.status === 422) {
      dispatch({
        type: SUBMIT_SHOP_DETAILS_ERROR,
        payload: err.response?.data?.errors[0]?.shopUrl,
      });
    } else {
      dispatch({
        type: SUBMIT_SHOP_DETAILS_ERROR,
        payload: err.response?.data?.message,
      });
    }
  }
};
